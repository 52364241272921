import React, { Component } from 'react';

import { isBrowser } from 'react-device-detect';
import { withLocalize, Translate } from 'react-localize-redux';
import DetectedLocationModalTranslation from '../../../../translations/detected_location_modal.json';

import './Checkbox.css';
import './DetectedLocationModal.css';
import DynamicImg from '../../../dynamic_image/DynamicImg.js';

class DetectedLocationModal extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(DetectedLocationModalTranslation);

        this.state = {
            cacheCheck: true,
        }
    }

    triggerConfirmEvent = (redirect) => {
        this.props.logicHandler(redirect);
    }

    render() {
        return (
            <div className={this.props.location === null ? "detected-location-modal-wrapper hide-modal" : "detected-location-modal-wrapper show-modal"} >
                <div className="detected-location-modal">

                    <div className={ isBrowser ? "browser-section" : "" }>
                        <div className={ isBrowser ? "trevr-column" : "" }>
                            <DynamicImg
                                imageName="https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/trevr_point_right"
                                fallbackExt='png'
                                imgClass="treVR-image"
                                alt="TreVR"
                                width="100%"
                                height="auto"
                            />
                        </div>
                        
                        <div className={ isBrowser ? "options-column" : "" }>
                            <div className="detected-location-modal-section">
                                <Translate id="pre-location" />
                                {this.props.location}
                                <Translate id="post-location" />
                            </div>

                            <div className="align-center">
                                <button className="generic-location-button" onClick={() => this.triggerConfirmEvent(true)}>
                                    <Translate id="yes-button" />
                                </button>
                            </div>
                            <div className="detected-popup-or">
                                <Translate id="or" />
                            </div>
                            <div className="align-center">
                                <button className="generic-location-button" onClick={() => this.triggerConfirmEvent(false)}>
                                    <Translate id="no-button" />
                                </button>
                            </div>

                            {/**
                             * <div className="detected-location-cache-checkbox">
                                {this.renderCheckBox({
                                    value: this.props.cacheCheck
                                })}
                                </div>
                             */}
                            
                        </div> 
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default withLocalize(DetectedLocationModal);