import React, { Component } from 'react';
import Axios from 'axios';
import './LocationPin.css';

import {
    GET_LOCATION_DETAILS_FOR_WEBSITE
} from '../../../../utilities/GlobalConsts'

class LocationPin extends Component {

    state = {
        locationData: null,
        showModal: null
    }

    timeoutObj = null;

    queueHideModal = () => {
        this.timeoutObj = setTimeout(() => {
            this.setState({
                showModal: false
            });
        }, 3000);
    }

    mouseOverEvent = () => {
        if(this.state.locationData === null) {
            Axios.get(GET_LOCATION_DETAILS_FOR_WEBSITE + this.props.locationName)
            .then((response) => {
                if(response['data']) {
                    let showModal = false;
                    if(this.state.showModal === null) {
                        showModal = true;

                        this.queueHideModal();
                    }

                    this.setState({
                        locationData: response['data'],
                        showModal: showModal
                    });
                } else {
                    console.warn("No results");
                }
            });
        } else {
            this.queueHideModal();

            this.setState({
                showModal: true
            });
        }
    }

    locationOnClickEvent = () => {
        if(this.state.showModal) {

            // drections link. open in new tab
            if(this.props.link.includes('google')) {
                window.open(this.props.link);
            } else { // locations link. open in same tab.
                window.location.href = this.props.link;
            }

        } else {
            this.mouseOverEvent();
        }
    }

    mouseOutEvent = () => {
        clearTimeout(this.timeoutObj);

        this.setState({
            showModal: false
        });
    }

    render() {
        let modalData = null;

        if(this.state.locationData) {
            modalData = this.state.locationData.display_name;
        }

        return (
            <div className="location-pin-wrapper">
                <div className={this.state.showModal ? "location-pin-modal show-pin-modal" : "location-pin-modal hide-pin-modal"} onClick={this.locationOnClickEvent}>
                    {modalData} {this.props.isSatalite ? "Satalite" : null}
                </div>
                <img src={this.props.image} className="location-pin" alt="pin"
                    onMouseOver={this.mouseOverEvent}
                    onMouseOut={this.mouseOutEvent}
                    onClick={this.locationOnClickEvent} />
            </div>
        );
    }
}

export default LocationPin;
