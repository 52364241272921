import React, { Component } from 'react';
import axios from 'axios';
import { isBrowser } from 'react-device-detect';
import { WEBCACHE_API as Api, getExtension, getBaseName } from '../../../utilities/GlobalConsts';

import { withLocalize, Translate } from 'react-localize-redux';
import PressroomTranslation from '../../../translations/pressroom.json';

import CoveragePreviewContainer from './coverage_preview/CoveragePreviewContainer';
import CoveragePreview from './coverage_preview/CoveragePreview';
import Video from '../../video/Video';

import './Pressroom.css';
import {Helmet} from 'react-helmet';

const LoadingGif = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/loading256.gif';
const HeaderBackgroundImg = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/3297ac15-press_room";

class Pressroom extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(PressroomTranslation);
    }

    state = {
        newsCoverageCurPage: 0,
        newsCoverageMaxPage: undefined,
        newsCoveragePosts: [],

        pressReleaseCurPage: 0,
        pressReleaseMaxPage: undefined,
        pressReleasePosts: [],

        videoFootage: undefined
    }

    previousNewsPage = () => {
        let newVal = 0;
        if(this.state.newsCoverageCurPage - 1 < 0) {
            newVal = this.state.newsCoverageMaxPage - 1
        } else {
            newVal = Math.abs(this.state.newsCoverageCurPage - 1) % this.state.newsCoverageMaxPage;
        }

        this.setState({
            newsCoverageCurPage: newVal
        });

        this.getNewsPage(newVal);
    }

    nextNewsPage = () => {
        this.setState({
            newsCoverageCurPage: (this.state.newsCoverageCurPage + 1) % this.state.newsCoverageMaxPage
        });

        this.getNewsPage((this.state.newsCoverageCurPage + 1) % this.state.newsCoverageMaxPage);
    }

    getNewsMaxPage = async () => {
        try {
            let response = await axios.get(Api.GET_NEWS_PAGE_COUNT);

            this.setState({
                newsCoverageMaxPage: response['data']['pageCount']
            });

        } catch(error) {
            console.error(error);

        }
    }

    getNewsPage = async (page) => {
        try {
            let response = await axios.get(Api.GET_NEWS_PAGE + page);

            this.setState({
                newsCoveragePosts: response['data']['page']
            });

        } catch(error) {
            console.error(error);

        }
    }

    getNewsPreviewDom = () => {
        let newsCoveragePosts = [];

        // eslint-disable-next-line
        for(let index in this.state.newsCoveragePosts) {
            newsCoveragePosts.push(
                <CoveragePreview
                    key={index}
                    post_type="news"
                    index={this.state.newsCoveragePosts[index].post_id}
                    title={this.state.newsCoveragePosts[index].title}
                    image={this.state.newsCoveragePosts[index].preview_image} />
            )
        }

        return newsCoveragePosts;
    }

    previousPressroomPage = () => {
        let newVal = 0;
        if(this.state.pressReleaseCurPage - 1 < 0) {
            newVal = this.state.pressReleaseMaxPage - 1
        } else {
            newVal = Math.abs(this.state.pressReleaseCurPage - 1) % this.state.pressReleaseMaxPage;
        }

        this.setState({
            pressReleaseCurPage: newVal
        });

        this.getPressReleasePage(newVal);
    }

    nextPressroomPage = () => {
        this.setState({
            pressReleaseCurPage: (this.state.pressReleaseCurPage + 1) % this.state.pressReleaseMaxPage
        });

        this.getPressReleasePage((this.state.pressReleaseCurPage + 1) % this.state.pressReleaseMaxPage);
    }

    getPressReleaseMaxPage = async () => {
        try {
            let response = await axios.get(Api.GET_PRESS_PAGE_COUNT);

            this.setState({
                pressReleaseMaxPage: response['data']['pageCount']
            });

        } catch(error) {
            console.error(error);

        }
    }

    getPressReleasePage = async (page) => {
        try {
            let response = await axios.get(Api.GET_PRESS_PAGE + page);

            this.setState({
                pressReleasePosts: response['data']['page']
            });

        } catch(error) {
            console.error(error);

        }
    }

    getPressReleasePreviewDom = () => {
        let pressReleasePosts = [];

        // eslint-disable-next-line
        for(let index in this.state.pressReleasePosts) {
            pressReleasePosts.push(
                <CoveragePreview
                    key={index}
                    post_type="press"
                    index={this.state.pressReleasePosts[index].post_id}
                    title={this.state.pressReleasePosts[index].title}
                    image={this.state.pressReleasePosts[index].preview_image} />
            )
        }

        return pressReleasePosts;
    }

    getVideoFootage = async () => {
        try{
            let response = await axios.get(Api.GET_VIDEOS);
            this.setState({
                videoFootage: response['data']['videos']
            });
        } catch(error) {
            console.error(error);
        }
    }

    getVideoFootageDom = () => {
        let videos = [];

        for(let index in this.state.videoFootage) {
            videos.push(
                <div className={isBrowser ? "desktop-pressroom-video" : null} key={index}>
                    <div className="pressroom-video">
                        <Video video={this.state.videoFootage[index].url} />
                    </div>
                </div>
            );
        }

        return videos;
    }

    componentDidMount() {
        this.getPressReleaseMaxPage();
        this.getPressReleasePage(0);
        this.getNewsMaxPage();
        this.getNewsPage(0);
        this.getVideoFootage();
    }

    render() {
        let newsCoverage = this.getNewsPreviewDom();

        let newsCoverageSection = null;
        if(newsCoverage.length !== 0) {
            newsCoverageSection = <CoveragePreviewContainer
                                    panels={newsCoverage}
                                    curPage={this.state.newsCoverageCurPage}
                                    maxPage={this.state.newsCoverageMaxPage}
                                    previousPage={this.previousNewsPage}
                                    nextPage={this.nextNewsPage} />;
        } else {
            newsCoverageSection = <img src={LoadingGif} height='64px' width='64px' alt="loading"/>
        }

        let pressRelease = this.getPressReleasePreviewDom();

        let pressReleaseSection = null;
        if(pressRelease.length !== 0) {
            pressReleaseSection = <CoveragePreviewContainer
                                        panels={pressRelease}
                                        curPage={this.state.pressReleaseCurPage}
                                        maxPage={this.state.pressReleaseMaxPage}
                                        previousPage={this.previousPressroomPage}
                                        nextPage={this.nextPressroomPage} />
        } else {
            pressReleaseSection = <img src={LoadingGif} height='64px' width='64px' alt="loading"/>
        }

        let videos = this.getVideoFootageDom();
        return(
            <div className="generic-wrapper">

                <Helmet>
                    <title>News, Press Coverage, Press Kit | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Directory and resources for news, press coverage, graphics, and press materials pertaining to Ctrl V Inc. - Virtual Reality Arcade"/>
                    <link rel="canonical" href={`${getBaseName()}/pressroom/`} />
                    <meta property="og:title" content="News, Press Coverage, Press Kit | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Directory and resources for news, press coverage, graphics, and press materials pertaining to Ctrl V Inc. - Virtual Reality Arcade" />
                    <meta property="og:url" content={`${getBaseName()}/pressroom/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Directory and resources for news, press coverage, graphics, and press materials pertaining to Ctrl V Inc. - Virtual Reality Arcade" />
                    <meta name="twitter:title" content="News, Press Coverage, Press Kit | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="press,media,articles,news"/>
                </Helmet>

                <div className="parallax-pressroom-header" style={{backgroundImage: `url(${HeaderBackgroundImg}.${getExtension()})`}}></div>

                <div className="generic-heading generic-heading-padding">
                    <Translate id="news-coverage" />
                </div>

                {newsCoverageSection}

                <div className="generic-heading generic-heading-padding">
                    <Translate id="press-release" />
                </div>

                {pressReleaseSection}
                {videos.length !== 0?(
                <div className="generic-heading generic-heading-padding">
                    <Translate id="video-footage" />
                </div>):null}

                {videos.length !== 0?(
                <div className="pressroom-video-section">
                    {videos}
                </div>):null}
            </div>
        );
    }
}

export default withLocalize(Pressroom);
