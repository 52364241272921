import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';

import { withLocalize, Translate } from 'react-localize-redux';
import HS from '../../../translations/homeschool.json';
import DynamicImg from '../../dynamic_image/DynamicImg';

import '../GenericPage.css';
import '../homeschool/Homeschool.css';

import { getExtension, getBaseName } from '../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

const xavrImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/xavr.png';
const ClassroomSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Virtual%20Classroom%20Screenshot';
const Classroom = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Virtual%20Classroom%20Background';
const VRobot = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/VRobot%20Education%20Background';
const VRobotSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/VRobot%20Education%20Screenshot';
const Universe = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Universe%20Sandbox%20Background';
const UniverseSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Universe%20Sandbox%20Screenshot';
const hsBackground = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Homeschool%20Header%20Background';
const hsHeader = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Homeschool%20Header%20Image';
const Nature = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Nature%20Treks%20VR%20Background';
const NatureSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Nature%20Treks%20VR%20Screenshot';
const Brush = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Tilt%20Brush%20Background';
const BrushSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Tilt%20Brush%20Screenshot';
const Anatomy = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Human%20Anatomy%20Experience%20Background';
const AnatomySS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Human%20Anatomy%20Experience%20Screenshot';
const Google = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Google%20Earth%20VR%20Background';
const GoogleSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Google%20Earth%20VR%20Screenshot';
const Victory = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Victory%20XR%20Background';
const VictorySS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Victory%20XR%20Screenshot';

class PlanoHomeschool extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(HS);
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Plano Homeschool | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Make learning unforgettable with amazing virtual reality educational content!"/>
                    <link rel="canonical" href={`${getBaseName()}/plano-homeschool/`} />
                    <meta property="og:title" content="Plano Homeschool | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Make learning unforgettable with amazing virtual reality educational content!" />
                    <meta property="og:url" content={`${getBaseName()}/plano-homeschool/`}/>
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Make learning unforgettable with amazing virtual reality educational content!" />
                    <meta name="twitter:title" content="Plano Homeschool | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content={`plano,homeschool,educate,education,learning`}/>
                </Helmet>
                
                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${hsBackground}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className='hs-title'>
                        <Translate id="title.top" />
                    </div>
                    <div className='top-image-section'>
                        <div className='hs-sub-section4'>
                            <iframe className="yt-iframe" src="https://www.youtube.com/embed/XLjU7Z54iGo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className='hs-info-section hs-sub-heading5'>
                            <Translate id="title.heading" />
                        </div>
                    </div>
                    <div className='hs-paragraph-section'>
                        <div className='hs-paragraph'>
                            <Translate id="title.middle" />
                            <p className='hs-blue'>'A Case Study: The Impact of VR on Academic Performance'</p>
                            <Translate id="title.middle-2" />
                            <p className='hs-blue'>27.4%</p>
                            <Translate id="title.middle-3" />
                            <p className='hs-blue'>32.4%!</p>
                        </div>
                        <br></br>
                        <div className='hs-paragraph'><Translate id="title.middle-4" /></div>
                        <div className='hs-paragraph'>
                            <a href="mailto:plano@ctrlvarcade.com">
                                <button className="hs-button2 hs-pulse-animation">
                                    <Translate id="title.button" />
                                </button>
                            </a>
                        </div>
                        <div className='hs-paragraph'><Translate id="title.middle-5" /></div>
                        <div className='hs-info-section hs-sub-heading4'><Translate id="title.plano-phone" /></div>
                        <br></br>
                        <br></br>
                        <div className='hs-paragraph'><Translate id="title.fb-event-1" /></div>
                        <div className='hs-paragraph'>
                            <Translate id="title.fb-event-2" />
                            <div className='hs-blue'><Translate id="title.fb-event-3-plano" /></div>
                            <Translate id="title.fb-event-4" />
                        </div>
                        <div className='hs-paragraph'>
                            <Translate id="title.fb-event-5" />
                            <div className='hs-blue'><Translate id="title.fb-event-6" /></div>
                            <Translate id="title.fb-event-7" />
                        </div>
                        <br></br>
                        <br></br>
                        <div className='hs-paragraph hs-blue'>
                            <Translate id="title.plano-address" />
                        </div>
                    </div>
                </div>
                
                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Classroom}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="hs-section">
                        <div className="hs-sub-section">
                            <div className="hs-heading2">
                                <Translate id="virtual-classroom.title" />
                            </div>
                            <div className="hs-description2">
                                <Translate id="virtual-classroom.description" />
                            </div>
                            <div className="hs-sub-heading3">
                                <Translate id="virtual-classroom.heading-1" />
                            </div>
                            <div className="hs-description2">
                                <Translate id="virtual-classroom.description-2" />
                            </div>
                            <div className="hs-sub-heading3">
                                <Translate id="virtual-classroom.heading-2" />
                            </div>
                            <div className="hs-description2">
                                <Translate id="virtual-classroom.description-3" />
                            </div>
                        </div>
                        <div className='hs-sub-section'>
                            <div className="hs-sub-section3">
                                <div className='hs-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/FlP8CnCEtvI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className='hs-margin hs-info-section'>
                                <div className="hs-sub-heading-section">
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.grade" />                                        
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="virtual-classroom.grade" />
                                    </div>
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="virtual-classroom.age" />
                                    </div>
                                </div>
                            </div>
                            <div className='hs-margin hs-info-section'>
                                <div className='hs-image-section'>
                                    <DynamicImg
                                        imageName={ClassroomSS}
                                        fallbackExt='png'
                                        alt="Virtual Classroom Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="hs-image"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Victory}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="hs-section">
                        <div className='hs-sub-section-ytl'>
                            <div className="hs-sub-section2">
                                <div className='hs-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/XLjU7Z54iGo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <a href={`${getBaseName()}/victoryxr/`}>
                                <button className="hs-button hs-pulse-animation">
                                    <Translate id="victory.button" />
                                </button>
                            </a>
                        </div>
                        <div className="hs-sub-section">
                            <div className="hs-heading">
                                <Translate id="victory.title" />
                            </div>
                            <div className='hs-info-section'>
                                <div className="hs-sub-heading-section">
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.grade" />                                        
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="victory.grade" />
                                    </div>
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="victory.age" />
                                    </div>
                                </div>
                            </div>
                            <div className='hs-info-section'>
                                <div className='hs-image-section'>
                                    <DynamicImg
                                        imageName={VictorySS}
                                        fallbackExt='png'
                                        alt="Victory XR Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="hs-image"
                                    />
                                </div>
                            </div>
                            <div className="hs-description3">
                                <Translate id="victory.description" />
                                <ul>
                                    <li><Translate id="victory.list.item-1" /></li>
                                    <li><Translate id="victory.list.item-2" /></li>
                                    <li><Translate id="victory.list.item-3" /></li>
                                    <li><Translate id="victory.list.item-5" /></li>
                                    <li><Translate id="victory.list.item-6" /></li>
                                </ul>
                            </div>
                        </div>
                        <div className='hs-sub-section-ytl2'>
                            <div className="hs-sub-section2">
                                <div className='hs-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/XLjU7Z54iGo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <a href={`${getBaseName()}/victoryxr/`}>
                                <button className="hs-button hs-pulse-animation">
                                    <Translate id="victory.button" />
                                </button>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${VRobot}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="hs-section">
                        <div className="hs-sub-section">
                            <div className="hs-heading">
                                <Translate id="robot.title" />
                            </div>
                            <div className='hs-info-section'>
                                <div className="hs-sub-heading-section">
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.grade" />                                        
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="robot.grade" />
                                    </div>
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="robot.age" />
                                    </div>
                                </div>
                            </div>
                            <div className='hs-info-section'>
                                <div className='hs-image-section'>
                                    <DynamicImg
                                        imageName={VRobotSS}
                                        fallbackExt='png'
                                        alt="VRobot Education Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="hs-image"
                                    />
                                </div>
                            </div>
                            <div className="hs-description">
                                <Translate id="robot.description" />
                            </div>
                        </div>
                        <div className='hs-sub-section'>
                            <div className="hs-sub-section3">
                                <div className='hs-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/kls0qxTlEPk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Universe}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="hs-section">
                        <div className='hs-sub-section-ytl'>
                            <div className="hs-sub-section2">
                                <div className='hs-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/uTro90oUsZY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="hs-sub-section">
                            <div className="hs-heading">
                                <Translate id="universe.title" />
                            </div>
                            <div className='hs-info-section'>
                                <div className="hs-sub-heading-section">
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.grade" />                                        
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="universe.grade" />
                                    </div>
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="universe.age" />
                                    </div>
                                </div>
                            </div>
                            <div className='hs-info-section'>
                                <div className='hs-image-section'>
                                    <DynamicImg
                                        imageName={UniverseSS}
                                        fallbackExt='png'
                                        alt="Universe Sandbox Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="hs-image"
                                    />
                                </div>
                            </div>
                            <div className="hs-description">
                                <Translate id="universe.description" />
                            </div>
                        </div>
                        <div className='hs-sub-section-ytl2'>
                            <div className="hs-sub-section2">
                                <div className='hs-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/uTro90oUsZY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Nature}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="hs-section">
                        <div className="hs-sub-section">
                            <div className="hs-heading">
                                <Translate id="nature.title" />
                            </div>
                            <div className='hs-info-section'>
                                <div className="hs-sub-heading-section">
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.grade" />                                        
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="nature.grade" />
                                    </div>
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="nature.age" />
                                    </div>
                                </div>
                            </div>
                            <div className='hs-info-section'>
                                <div className='hs-image-section'>
                                    <DynamicImg
                                        imageName={NatureSS}
                                        fallbackExt='png'
                                        alt="Nature Treks VR Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="hs-image"
                                    />
                                </div>
                            </div>
                            <div className="hs-description">
                                <Translate id="nature.description" />
                            </div>
                        </div>
                        <div className='hs-sub-section'>
                            <div className="hs-sub-section3">
                                <div className='hs-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/gBfpV49aY7w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Anatomy}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="hs-section">
                        <div className='hs-sub-section-ytl'>
                            <div className="hs-sub-section2">
                                <div className='hs-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/58ElIRAMF8Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="hs-sub-section">
                            <div className="hs-heading">
                                <Translate id="anatomy.title" />
                            </div>
                            <div className='hs-info-section'>
                                <div className="hs-sub-heading-section">
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.grade" />                                        
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="anatomy.grade" />
                                    </div>
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="anatomy.age" />
                                    </div>
                                </div>
                            </div>
                            <div className='hs-info-section'>
                                <div className='hs-image-section'>
                                    <DynamicImg
                                        imageName={AnatomySS}
                                        fallbackExt='png'
                                        alt="Human Anatomy Experience Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="hs-image"
                                    />
                                </div>
                            </div>
                            <div className="hs-description">
                                <Translate id="anatomy.description" />
                            </div>
                        </div>
                        <div className='hs-sub-section-ytl2'>
                            <div className="hs-sub-section2">
                                <div className='hs-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/58ElIRAMF8Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Google}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="hs-section">
                        <div className="hs-sub-section">
                            <div className="hs-heading">
                                <Translate id="google.title" />
                            </div>
                            <div className='hs-info-section'>
                                <div className="hs-sub-heading-section">
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.grade" />                                        
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="google.grade" />
                                    </div>
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="google.age" />
                                    </div>
                                </div>
                            </div>
                            <div className='hs-info-section'>
                                <div className='hs-image-section'>
                                    <DynamicImg
                                        imageName={GoogleSS}
                                        fallbackExt='png'
                                        alt="Google Earth VR Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="hs-image"
                                    />
                                </div>
                            </div>
                            <div className="hs-description">
                                <Translate id="google.description" />
                            </div>
                        </div>
                        <div className='hs-sub-section'>
                            <div className="hs-sub-section3">
                                <div className='hs-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/SCrkZOx5Q1M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Brush}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="hs-section">
                        <div className='hs-sub-section-ytl'>
                            <div className="hs-sub-section2">
                                <div className='hs-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/TckqNdrdbgk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="hs-sub-section">
                            <div className="hs-heading">
                                <Translate id="brush.title" />
                            </div>
                            <div className='hs-info-section'>
                                <div className="hs-sub-heading-section">
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.grade" />                                        
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="brush.grade" />
                                    </div>
                                    <div className='hs-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='hs-sub-heading2'>
                                        <Translate id="brush.age" />
                                    </div>
                                </div>
                            </div>
                            <div className='hs-info-section'>
                                <div className='hs-image-section'>
                                    <DynamicImg
                                        imageName={BrushSS}
                                        fallbackExt='png'
                                        alt="Tilt Brush Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="hs-image"
                                    />
                                </div>
                            </div>
                            <div className="hs-description">
                                <Translate id="brush.description" />
                            </div>
                        </div>
                        <div className='hs-sub-section-ytl2'>
                            <div className="hs-sub-section2">
                                <div className='hs-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/TckqNdrdbgk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withLocalize(PlanoHomeschool);