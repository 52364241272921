import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';
import Axios from 'axios';
import { withLocalize, Translate } from 'react-localize-redux';
import LocationTranslations from '../../translations/location.json';

import '../pages/locations/location/Location.css';

import {
    WEBCACHE_API
} from '../../utilities/GlobalConsts';
import DynamicImg from '../dynamic_image/DynamicImg.js';

const GoldReview = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/review_gold';
const GrayReview = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/review_gray';

class Reviews extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFakeTab: true,
            fakeReviews: undefined
        }

        this.props.addTranslation(LocationTranslations);
    }

    getFakeReviews = async () => {
        try{
            let response = await Axios.get(WEBCACHE_API.GET_FAKE_REVIEWS);

            if(response.data.success) {
                this.setState({
                    fakeReviews: response.data.reviews
                })
            }

        } catch(err) {
            console.error("error getting fake reveiws");
            console.error(err);
        }
    }

    getFakeReviewsDom = () => {
        let reviews = [];

        // eslint-disable-next-line
        for(let key in this.state.fakeReviews) {

            reviews.push(
                <div className="review" key={key}>
                    <div className="author-name">
                        {this.state.fakeReviews[key].customer_name}
                    </div>
                    <span className="stars">
                        {this.state.fakeReviews[key].review_rating}
                    </span>

                    <div className="review-text">
                        {this.state.fakeReviews[key].review_content}
                    </div>
                </div>
            );
        }

        return reviews;
    }

    getReviews = () => {
        let reviews = [];

        // eslint-disable-next-line
        for(let key in this.props.reviews) {

            let stars = [];

            for(let i = 0; i < this.props.reviews[key].rating; i++) {
                stars.push(
                    <DynamicImg
                        key={i}
                        imageName={GoldReview}
                        fallbackExt='png'
                        imgClass="review-star"
                        alt="Star"
                        width="20px"
                        height="17px"
                    />
                );
            }

            for(let i = 0; i < 5 - this.props.reviews[key].rating; i++) {
                stars.push(
                    <DynamicImg
                        key={5-i}
                        imageName={GrayReview}
                        fallbackExt='png'
                        imgClass="review-star"
                        alt="Star"
                        width="20px"
                        height="17px"
                    />
                );
            }

            reviews.push(
                <div className="review" key={key}>
                    <div className="author-name">
                        {this.props.reviews[key].author_name}
                    </div>
                    <span className="stars">
                        {stars}
                    </span>
                    {this.props.reviews[key].relative_time_description}

                    <div className="review-text">
                        {this.props.reviews[key].text}
                    </div>
                </div>
            );
        }

        return reviews;
    }

    setTab = (isFakeTab) => {
        this.setState({
            isFakeTab: isFakeTab
        });
    }

    componentDidMount = () => {
        this.getFakeReviews();
    }

    getReviewTab = () => {
        if(isBrowser) {
            return(
                <div className="review-contact-wrapper">
                    <div className="review-wrapper">
                        <div className="location-padding-wrapper">
                            <div className="location-section-header">
                                <button className={ this.state.isFakeTab ? "selected-review-tab" : "unselected-review-tab" } onClick={() => this.setTab(true)}>
                                    <Translate id="reviews.fake-title" />
                                </button>

                                <button className={ !this.state.isFakeTab ? "selected-review-tab" : "unselected-review-tab" } onClick={() => this.setTab(false)}>
                                    <Translate id="reviews.real-title" />
                                </button>
                            </div>

                            <div className="review-description">
                                <Translate id={ this.state.isFakeTab ? "reviews.fake-desc" : "reviews.bad-and-good" } />
                            </div>

                            <div className="google-review-wrapper">
                                { this.state.isFakeTab ? this.getFakeReviewsDom() : this.getReviews() }

                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="location-review-wrapper location-padding-wrapper">
                    <div className="review-wrapper">
                        <div className="location-section-header">

                            <button className={ this.state.isFakeTab ? "selected-review-tab" : "unselected-review-tab" } onClick={() => this.setTab(true)}>
                                <Translate id="reviews.fake-title" />
                            </button>

                            <button className={ !this.state.isFakeTab ? "selected-review-tab" : "unselected-review-tab" } onClick={() => this.setTab(false)}>
                                <Translate id="reviews.real-title" />
                            </button>
                        </div>

                        <div className="review-description">
                            <Translate id={ this.state.isFakeTab ? "reviews.fake-desc" : "reviews.bad-and-good" } />
                        </div>

                        <div className="google-review-wrapper">
                            { this.state.isFakeTab ? this.getFakeReviewsDom() : this.getReviews() }
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                { this.getReviewTab() }
            </React.Fragment>
        )
    }
}

export default withLocalize(Reviews);
