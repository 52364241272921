import React, { Component } from 'react';
import './ImageSlider.css';
import { tryAddImageExtension } from '../../utilities/GlobalConsts';

import SmartLink from './SmartLink';

class ImageSlider extends Component {

    state = {
        curIndex: 0,
        nextIndex: 1,
        nextImageOpacity: 'image-transition-hide'
    }

    intervalObj = null;
    fadeTimeoutObj = null;

    clearTimedObjects = () => {
        // clear interval and timeout to prevent memory leaks after unmount
        clearInterval(this.intervalObj);
        this.intervalObj = null;
        clearTimeout(this.fadeTimeoutObj);
        this.fadeTimeoutObj = null;
    }
    componentDidUpdate(prevProps) {
        if(prevProps.imageData.length !== this.props.imageData.length && this.props.imageData.length >= 2){
            //Randomization of the beginning point
            const randomStart = Math.floor(Math.random() * this.props.imageData.length);
            const randomNext = (randomStart + 1) % this.props.imageData.length;
            //We want a random starting point for the slider
            this.setState({curIndex: randomStart, nextIndex:randomNext})

            this.startSliderLoop();
        }
    }
    // transitions between images automatically via timeouts
    startSliderLoop = () => {
        // save interval object for clearing / preventing mem leaks
        this.intervalObj = setInterval(() => {

            // queue next image (offscreen)
            this.setState({
                nextImageOpacity: 'image-transition-show'
            });

            // start timeout for fade (changes class, not image) after a second.
            this.fadeTimeoutObj = setTimeout(() => {
                let setCurIndex = (this.state.curIndex + 1) % this.props.imageData.length;
                let setNextIndex = (setCurIndex + 1) % this.props.imageData.length
    
                this.setState({
                    curIndex: setCurIndex,
                    nextIndex: setNextIndex,
                    nextImageOpacity: 'image-transition-hide'
                });
            }, 1000);
        }, 5000);
    }

    // method for handling user selected slide
    setSelectedIndex = (index) => {
        // clear inverval to prevent flickering and having 2 intervals going
        clearInterval(this.intervalObj);

        // set slide index
        this.setState({
            curIndex: parseInt(index)
        });

        // restart loop
        this.startSliderLoop();
    }

    componentDidMount = () => {
        if(this.fadeTimeoutObj !== null || this.intervalObj !== null) {
            this.clearTimedObjects();
        }
        if(this.props.imageData.length >= 1){
            this.startSliderLoop();
        }
        
    }


    componentWillUnmount = () => {
        // prevents memory leaks (dom manupulation on unmounted objects)
        this.clearTimedObjects();
    }

    render() {
        //This is very gross, but allows the page to load.
        // If an error is thrown on imageslider, the page gives a 404
        try{
            let buttons = [];
            if(this.props.imageData.length === 0) {
                return <div></div>;
            }
            // eslint-disable-next-line
            for(let index in this.props.imageData) {
                buttons.push(
                    <button key={index} 
                        onClick={() => this.setSelectedIndex(index)} 
                        className={parseInt(index) === this.state.curIndex ? "selected-button" : "not-selected-button"}
                        aria-label="Button">
                        
                    </button>
                        
                )
            }
            // We want to see if there is only a single image in the imageData
            if(this.props.imageData.length === 1){
                return(
                    <div className="image-slider-wrapper">
                            <div className={"desktop-image-slider-wrapper"}>
                                <SmartLink to={this.props.imageData[this.state.curIndex].link || "#"}>
                                    <img 
                                    className="image-slider-image" 
                                    src={tryAddImageExtension( this.props.imageData[this.state.curIndex].image )} 
                                    alt="Banner"></img>
                                </SmartLink>
                            </div>
                    </div>
                );
            }
            
            // Otherwise it's business as usual
            return (
                <div className="image-slider-wrapper">
                    <div className={"desktop-image-slider-wrapper"}>
                            <SmartLink to={this.props.imageData[this.state.curIndex].link || "#"}>
                                <img 
                                className="image-slider-image" 
                                src={tryAddImageExtension( this.props.imageData[this.state.curIndex].image )} 
                                alt="Banner"></img>
                            </SmartLink>
                            <img 
                            className={"image-slider-image-2 " + this.state.nextImageOpacity} 
                            src={tryAddImageExtension( this.props.imageData[this.state.nextIndex].image )} 
                            alt="Banner" 
                                ></img>
                    </div>

                    <div className="image-slider-controls-wrapper">
                        {buttons}
                    </div>
                </div>
            );
        }
        catch(e){
            console.error(e);
        }
    }
    
}

export default ImageSlider;
