import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';

import { withLocalize, Translate } from 'react-localize-redux';
import TermsAndConditionsTranslation from '../../../../translations/terms_and_conditions.json';

import { getBaseName } from '../../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

class TermsAndConditions extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(TermsAndConditionsTranslation);
    }

    render() {
        return (
            <div className={isBrowser ? "generic-wrapper align-left blue-sub-headings browser-wrapper" : "generic-wrapper align-left blue-sub-headings"}>

                <Helmet>
                    <title>Terms and Conditions - Ctrl V</title>
                    <link rel="canonical" href={`${getBaseName()}/legal/terms-and-conditions/`}  />
                    <meta property="og:title" content="Terms and Conditions - Ctrl V" />
                    <meta property="og:url" content={`${getBaseName()}/legal/terms-and-conditions/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:title" content="Terms and Conditions - Ctrl V" />
                    <meta name="keywords" content="terms,conditions" />
                </Helmet>

                <div className="generic-heading generic-heading-padding">
                    <Translate id="title" />
                </div>
                <div className="generic-section blue-text">
                    <Translate id="body-1" />
                </div>
                <div className="generic-section blue-text">
                    <Translate id="body-2" />
                </div>
                <div className="generic-section blue-text">
                    <Translate id="body-3" />
                </div>

                <div className="generic-section">
                    <Translate id="body-4.section-1" />
                    <span className="blue-text">
                        <Translate id="body-4.ctrl-v" />
                    </span>
                    <Translate id="body-4.section-2" />
                </div>

                <div className="generic-section">
                    <Translate id="body-5.section-1" />
                    <span className="blue-text">
                        <Translate id="body-5.agreement" />
                    </span>
                    <Translate id="body-5.section-2" />
                    <span className="blue-text">
                        <Translate id="body-5.services" />
                    </span>
                    
                    <Translate id="body-5.section-3" />
                    <span className="blue-text">
                        <Translate id="body-5.premises" />
                    </span>
                    <Translate id="body-5.section-4" />
                </div>

                <div className="generic-section">
                    <Translate id="body-6.title" />
                    
                    <ul className="numbered-list">
                        <li><Translate id="body-6.list.item-1" /></li>
                        <li><Translate id="body-6.list.item-2" /></li>
                        <li><Translate id="body-6.list.item-3" /></li>
                        <li><Translate id="body-6.list.item-4" /></li>
                        <li>
                            <Translate id="body-6.list.item-5.section-1" />
                            <span className="blue-text">
                                <Translate id="body-6.list.item-5.business-partners" />
                            </span>
                            <Translate id="body-6.list.item-5.section-2" />
                            <span className="blue-text">
                                <Translate id="body-6.list.item-5.claims" />
                            </span>
                            <Translate id="body-6.list.item-5.section-3" />
                        </li>
                        <li><Translate id="body-6.list.item-6" /></li>
                        <li>
                            <Translate id="body-6.list.item-7.section-1" />
                            <Link to="/legal/ctrl-v-rules">
                                <span className="blue-text">
                                    <Translate id="body-6.list.item-7.rules" />
                                </span>
                            </Link>
                            <Translate id="body-6.list.item-7.section-2" />
                        </li>
                        <li><Translate id="body-6.list.item-8" /></li>
                        <li><Translate id="body-6.list.item-9" /></li>
                        <li><Translate id="body-6.list.item-10" /></li>
                        <li><Translate id="body-6.list.item-11" /></li>
                        <li><Translate id="body-6.list.item-12" /></li>
                    </ul>
                </div>

                <div className="generic-section">
                    <Translate id="body-7" />
                </div>

                <div className="generic-section">
                    <Translate id="body-8" />
                </div>

                <div className="generic-section">
                    <Translate id="body-9" />
                </div>

                <div className="generic-section">
                    <Translate id="body-10" />
                </div>

                <div className="generic-section blue-text">
                    <Translate id="body-11" />
                </div>
            </div>
        );
    }
}

export default withLocalize(TermsAndConditions);