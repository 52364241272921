import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';
import '../../GenericPage.css'

import { withLocalize, Translate } from 'react-localize-redux';
import MediaReleaseTranslation from '../../../../translations/media_release.json';

import { getBaseName } from '../../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

class MediaRelease extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(MediaReleaseTranslation);
    }

    render() {
        return (
            <div className={isBrowser ? "generic-wrapper align-left blue-sub-headings browser-wrapper" : "generic-wrapper align-left blue-sub-headings"}>
                
                <Helmet>
                    <title>Media, Photo and Video Release | Ctrl V - Virtual Reality</title>
                    <meta name="description" content="Media release notification for all attendees of the Ctrl V Virtual Reality Arcade that ensures all players agree to the terms listed."/>
                    <link rel="canonical" href={`${getBaseName()}/legal/media-release/`} />
                    <meta property="og:title" content="Media, Photo and Video Release | Ctrl V - Virtual Reality" />
                    <meta property="og:description" content="Media release notification for all attendees of the Ctrl V Virtual Reality Arcade that ensures all players agree to the terms listed." />
                    <meta property="og:url" content={`${getBaseName()}/legal/media-release/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Media release notification for all attendees of the Ctrl V Virtual Reality Arcade that ensures all players agree to the terms listed." />
                    <meta name="twitter:title" content="Media, Photo and Video Release | Ctrl V - Virtual Reality" />
                    <meta name="keywords" content="media,photo,video,waiver,liability,legal" />
                </Helmet>

                <div className="generic-heading generic-heading-padding">
                    <Translate id="title" />
                </div>

                <div className="generic-section">
                    <Translate id="body-1" />
                </div>

                <div className="generic-section">
                    <Translate id="body-2" />
                </div>

                <div className="generic-section">
                    <Translate id="body-3" />
                </div>

                <div className="generic-section">
                    <Translate id="body-4" />
                </div>

                <div className="generic-section">
                    <Translate id="body-5" />
                </div>

                <div className="generic-section">
                    <Translate id="body-6" />
                </div>

                <div className="generic-section">
                    <Translate id="body-7" />
                </div>
            </div>
        );
    }
}

export default withLocalize(MediaRelease);