import React, { Component } from 'react';

import { withLocalize, Translate } from 'react-localize-redux';
import GameGalleryTranslation from '../../translations/game_gallery.json';

import FeaturedGames from './featured_games/FeaturedGames';

import { getBaseName } from '../../utilities/GlobalConsts';

import './GameGallery.css';

class GameGallery extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(GameGalleryTranslation);
    }

    renderFeaturedGames = () => {
        if(this.props.showFeaturedGames) {
            return(<FeaturedGames />);
        } else return null;
    }

    render() {
        return(
            <React.Fragment>

                {this.renderFeaturedGames()}

                <p className="games-paragraph location-padding-wrapper location-parent-request">
                    <Translate id="max-players.before" />
                    <span className="blue-text">
                        <Translate id="max-players.blue-text" />
                    </span>
                    <Translate id="max-players.after" />
                </p>

                <embed src={`${getBaseName()}/game-library/${this.props.locationId}`} width="90%" height="800" />
            </React.Fragment>
        );
    }
}

export default withLocalize(GameGallery);