import React, { Component } from 'react';

import { withLocalize, Translate } from 'react-localize-redux';
import PrivacyPolicyTranslation from '../../../../translations/privacy_policy.json';

import { isBrowser } from 'react-device-detect';

import { getBaseName } from '../../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

class PrivacyPolicy extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(PrivacyPolicyTranslation);
    }

    render() {
        return(
            <div className={isBrowser ? "generic-wrapper align-left blue-sub-headings browser-wrapper" : "generic-wrapper align-left blue-sub-headings"}>
                
                <Helmet>
                    <title>View our Privacy Policy | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Ctrl V takes privacy very seriously and has published this policy to ensure that all visitors know what our prerogatives are at the virtual reality arcade."/>
                    <link rel="canonical" href={`${getBaseName()}/legal/privacy-policy/`} />
                    <meta property="og:title" content="View our Privacy Policy | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Ctrl V takes privacy very seriously and has published this policy to ensure that all visitors know what our prerogatives are at the virtual reality arcade." />
                    <meta property="og:url" content={`${getBaseName()}/legal/privacy-policy/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Ctrl V takes privacy very seriously and has published this policy to ensure that all visitors know what our prerogatives are at the virtual reality arcade." />
                    <meta name="twitter:title" content="View our Privacy Policy | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="privacy" />
                </Helmet>

                <div className="generic-heading generic-heading-padding">
                    <Translate id="title" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="collection.title" />
                </div>
                <div className="generic-section">
                    <Translate id="collection.body-1.first-line" />
                    <ul>
                        <li><Translate id="collection.body-1.list.item-1" /></li>
                        <li><Translate id="collection.body-1.list.item-2" /></li>
                        <li><Translate id="collection.body-1.list.item-3" /></li>
                        <li><Translate id="collection.body-1.list.item-4" /></li>
                        <li><Translate id="collection.body-1.list.item-5" /></li>
                    </ul>
                </div>

                <div className="generic-sub-heading">
                    <Translate id="ctrl-room.title" />
                </div>
                <div className="generic-section">
                    <Translate id="ctrl-room.body-1" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="using.title" />
                </div>
                <div className="generic-section">
                    <Translate id="using.body-1.first-line" />
                    <ul>
                        <li><Translate id="using.body-1.list.item-1" /></li>
                        <li><Translate id="using.body-1.list.item-2" /></li>
                        <li><Translate id="using.body-1.list.item-3" /></li>
                        <li><Translate id="using.body-1.list.item-4" /></li>
                        <li><Translate id="using.body-1.list.item-5" /></li>
                        <li><Translate id="using.body-1.list.item-6" /></li>
                        <li><Translate id="using.body-1.list.item-7" /></li>
                        <li><Translate id="using.body-1.list.item-8" /></li>
                        <li><Translate id="using.body-1.list.item-9" /></li>
                    </ul>
                </div>
                <div className="generic-section">
                    <Translate id="using.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="using.body-3" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="securing.title" />
                </div>
                <div className="generic-section">
                    <Translate id="securing.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="securing.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="securing.body-3" />
                </div>
                
                <div className="generic-sub-heading">
                    <Translate id="cross-border.title" />
                </div>
                <div className="generic-section">
                    <Translate id="cross-border.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="cross-border.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="cross-border.body-3" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="updating.title" />
                </div>
                <div className="generic-section">
                    <Translate id="updating.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="updating.body-2" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="other-websites.title" />
                </div>
                <div className="generic-section">
                    <Translate id="other-websites.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="other-websites.body-2" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="contact.title" />
                </div>
                <div className="generic-section">
                    <Translate id="contact.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="contact.body-2" />
                    <span className="blue-text">
                        <a href="mailto: contact@ctrlv.ca">
                            contact@ctrlv.ca
                        </a>
                    </span>
                </div>
            </div>
        );
    }
}

export default withLocalize(PrivacyPolicy);