import React, { Component } from 'react';
import './MobileNav.css'

class MobileNav extends Component {

    
    shouldComponentUpdate() {
        return false;   
    }

    render() {
        
        return (
            <div className="mobile-menu-icon" onClick={this.props.MenuClicked}>
                <div className="mobile-menu-block">
                    <div className="mobile-menu-title">
                        Menu
                    </div>
                    <div className="mobile-menu-hamburger">
                        <div className="menu-icon-div"></div>
                        <div className="menu-icon-div"></div>
                        <div className="menu-icon-div"></div>
                    </div>
                </div>
            </div>
        );

    }
}

export default MobileNav;