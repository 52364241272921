/*global google*/

import React, { Component } from 'react';
import Axios from 'axios';
import { isBrowser } from "react-device-detect";
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

import FacilityHours from '../../../facility_hours/FacilityHours';

import { withLocalize, Translate } from 'react-localize-redux';
import FaqSection from '../../frequently_asked_questions/faq_section/FaqSection'
import LocationTranslations from '../../../../translations/location.json';
import BookNowTranslations from '../../../../translations/modals.json'

import ImageSlider from '../../../image_slider/ImageSlider';
import ArviSlider from '../../../arvi_slider/ArviSlider';
import GameGallery from '../../../game_gallery/GameGallery';
import NewsletterSignUp from '../../../newsletter_sign_up/NewsletterSignUp';
import Popup from '../bottom_alert/Popup';
import GrandOpeningPopup from '../../../grand_opening_popup/GrandOpeningPopup'

import {
    GET_LOCATION_DETAILS_FOR_WEBSITE,
    getExtension,
    WEBCACHE_API,
    setLocalStorage,
    getBaseName
} from '../../../../utilities/GlobalConsts';

import './Location.css';
import Reviews from '../../../reviews/Reviews.js';
import LocationDropdown from './location_dropdown/LocationDropdown';
import FeaturedGames from '../../../game_gallery/featured_games/FeaturedGames';
import DynamicImg from '../../../dynamic_image/DynamicImg';
import SmartLink from '../../../image_slider/SmartLink';
import Grid from '@material-ui/core/Grid';
import BottomAlert from '../bottom_alert/bottomAlert';
import {Helmet} from 'react-helmet';

const Bogo = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/43ush3_BOGO_50_Thursday_Slider';
const MantaRay = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/manta_ray_desktop';

const trevrImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/trevr';

const Birthday = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/birthday';
const Group = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/group';
const Mobile = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/mobile';

const extraGirlImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/b47def5f-extra-life-page-back-girl-1024x576';
const extraBoyImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/9d75bf6d-extra-life-page-back-boy-1024x576';

const TreVRVCard = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/trevr_vcard';

const Archer = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Archer%20-%20Created%20Game%20Slider'
const Sigma = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Mission%20Sigma%20-%20Created%20Game%20Slider'
const Sanctum = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Sanctum%20-%20Created%20Game%20Slider'
const Christmas = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Christmas%20-%20Created%20Game%20Slider'
const Fear = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/House%20of%20Fear%20-%20Created%20Game%20Slider'
const Cyberpunk = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Cyberpunk%20-%20Created%20Game%20Slider'
const Jungle = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Jungle%20Quest%20-%20Created%20Game%20Slider'
const Signal = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Signal%20Lost%20-%20Created%20Game%20Slider'
const Prison = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/The%20Prison%20-%20Created%20Game%20Slider'
const Survival = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Survival%20-%20Created%20Game%20Slider'
const Chernobyl = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Chernobyl%20-%20Created%20Game%20Slider'
const Alice = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Alice%20-%20Created%20Game%20Slider'
const Arena = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Arena%20-%20Created%20Game%20Slider'

const REDIRECTED_LOCATIONS = [28];



class Plano extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(LocationTranslations);
        this.props.addTranslation(BookNowTranslations);

        this.emailCanvas = React.createRef();
        this.emailCanvasImg = React.createRef();
    }

    state = {
        reviews: null,
        hours: null,
        pathname: "plano",
        showBirthdayModal: false,
        showcorporateModal: false,
        showGroupModal: false,
        showMobileModal: false,
        banners: [],
        arvibanners: [
            {id: 1, img: Sigma, lnk: "#"},
            {id: 2, img: Sanctum, lnk: "#"},
            {id: 3, img: Christmas, lnk: "#"},
            {id: 4, img: Cyberpunk, lnk: "#"},
            {id: 5, img: Jungle, lnk: "#"},
            {id: 6, img: Prison, lnk: "#"},
            {id: 7, img: Survival, lnk: "#"},
            {id: 8, img: Chernobyl, lnk: "#"},
            {id: 9, img: Alice, lnk: "#"},
            // {id: 10, img: Arena, lnk: "#"},
            // {id: 11, img: Archer, lnk: "#"},
            // {id: 12, img: Fear, lnk: "#"},
            // {id: 13, img: Signal, lnk: "#"}
        ],
        discounts: [],
        locationData: {
            location_id: 32,
            address: {
                street_number: "3000",
                street: "Custer Road",
                unit: "250",
                city: "Plano",
                province: "Texas"
            },
            contact: {

                email: "plano@ctrlvarcade.com",
                phone: null
            },
            google_maps_url: "!1m14!1m8!1m3!1d418.0777917864327!2d-96.7328549!3d33.040079!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c18669523d83d%3A0x92745d575513ed25!2sParkwood%20Square%2C%203000%20Custer%20Rd%20%23250%2C%20Plano%2C%20TX%2075075%2C%20USA!5e0!3m2!1sen!2sca!4v1628704695369!5m2!1sen!2sca",
            map_url: "https://goo.gl/maps/FDJdp7YoF2BoDeno9",
        },
        showPopup: true
    }

    // getReviews = (placeId) => {
    //     if (google && google.maps.places) {
    //         let places = new google.maps.places.PlacesService(document.createElement('div'));
    //         places.getDetails({
    //             placeId: placeId
    //         }, (place, status) => {
    //             if (status === google.maps.places.PlacesServiceStatus.OK) {
    //                 this.setState({
    //                     reviews: place.reviews
    //                 })
    //             }
    //         })
    //     } else {
    //         console.error("Google is not defined, or places library not loaded");
    //     }
    // }

    getLocationData = async (pathname) => {
        try {
            let response = await Axios.get(GET_LOCATION_DETAILS_FOR_WEBSITE + pathname);

            if (response['data'] !== undefined && response['data'] !== "") {
                this.setState({
                    locationData: response['data']
                });

                return response['data'];
            } else {
                console.error("No location details error");
                // window.location.replace("/404");
            }

        } catch (err) {
            console.error("error getting Location Data");
            console.error(err);
            // window.location.replace("/404");
        }
    }

    componentDidMount = async () => {

        // details for location
        let locationData = await this.getLocationData(this.state.pathname);

        const reviewUrl = locationData.google_reviews_url;
        const placeId = reviewUrl.substring(reviewUrl.indexOf("?placeid=") + 9);

        // google reivews API
        // this.getReviews(placeId);

        this.getBannersForLocation();
        this.getDiscountsForLocation();
    }

    getBannersForLocation = async () => {
        const locId = this.state.locationData.location_id;
        try {
            const response = await Axios.get(WEBCACHE_API.GET_LOCATION_BANNERS + `?location_id=${locId}`);
            this.setState({ banners: response.data.banner });
        }
        catch (e) {
            console.error(e);
        }
    }
    getDiscountsForLocation = async () => {
        const locId = this.state.locationData.location_id;
        try {
            const response = await Axios.get(
                WEBCACHE_API.GET_LOCATION_DISCOUNTS + `?location_id=${locId}`);
            if (response.data.success) {
                this.setState({
                    discounts: response.data.locationDiscount
                })
            }
        }
        catch (e) {
            console.error("An error occurred when retrieving the discounts");
            console.error(e);
        }
    }

    checkHashScroll = () => {
        let hash = window.location.hash;
        if (hash) {
            let element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView();
            }
        }
    }
    renderDiscounts = () => {
        let discounts = this.state.discounts;
        if (discounts) {
            discounts = this.state.discounts.map((elem) => {
                return (
                    <FaqSection
                        key={elem.disid}
                        title={elem.name}
                        body={(
                            <div>
                                <div>
                                    {elem.long_description}
                                </div>
                                <div className="pricing-discounts-footer">
                                    {elem.example}
                                </div>
                                {elem.url && elem.url !== "#" ?
                                    <div>
                                        <SmartLink to={elem.url}>
                                            Click here to see more information!
                                        </SmartLink>
                                    </div> : null
                                }
                            </div>
                        )}
                        buttonColor="#0098da"
                        buttonStyle={{
                            marginLeft: '4px',
                            marginRight: '4px',
                            width: window.innerWidth >= 1400 ? '32%' : "100%",
                            minWidth: window.innerWidth >= 1400 ? '18em' : '0'
                        }}
                        modalStyle={{
                            backgroundColor: "#222222ee",
                            color: "#eee"
                        }}
                    />
                );
            });
        }
        return discounts;
    }
    renderPricing() {
        return (
            <div className="parallax-wrapper"
                style={{ backgroundColor: '#050505' }}>
                <div className="section-wrapper align-center padded">
                    <span className="anchor" id="pricing"></span>
                    <div className="generic-heading align-center generic-heading-padding">
                        Pricing
                    </div>
                    <div className="align-center pricing-body">
                        <Translate id="pricing.body-1.before" />
                        {" "}{this.state.locationData.currency_symbol}
                        {this.state.locationData.pricing ? (this.state.locationData.pricing.find((elem) => {
                            return elem.package_name === 'VR Station';
                        }).package_price + " ") : null}
                        <Translate id="pricing.body-1.after" />
                    </div>
                    <div className="align-center pricing-body">
                        <Translate id="pricing.body-2" />
                    </div>
                    {this.state.discounts && this.state.discounts.length >= 1 ?
                        <div className="align-center pricing-discounts">
                            <div className="align-center pricing-discounts-table">
                                {this.renderDiscounts()}
                            </div>
                        </div> : null}
                </div>
            </div>
        );
    }

    renderGiftCard() {
        if (!isBrowser) {
            return (
                <div className="parallax-wrapper"
                    style={{ backgroundColor: '#050505' }}>
                    <div className="section-wrapper align-center padded">
                        <div className="generic-heading align-center generic-heading-padding">
                            <Translate id="virtual_v_card.title"></Translate>
                        </div>
                        <div className="generic-heading align-center generic-heading-padding" style={{ color: "#0098DA", fontSize: "150%", marginTop: "-3%" }}>
                            <Translate id="virtual_v_card.body-1"></Translate>
                        </div>
                        <div>
                            <img src={`${TreVRVCard}.${getExtension()}`} width="200px" alt="giftcardtrever"/>
                        </div>
                        <div className="align-center pricing-body" style={{ fontSize: "150%" }}>
                            <Translate id="virtual_v_card.body-2"></Translate>
                        </div>
                        <a href={`${getBaseName()}/buy-gift-cards/`} >
                            <button className="generic-location-button add-pulse-animation" style={{ fontSize: "150%" }}>
                                <Translate id="virtual_v_card.button" />
                            </button>
                        </a>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="parallax-wrapper"
                    style={{ backgroundColor: '#050505' }}>
                    <div className="section-wrapper align-center padded">
                        <div className="generic-heading align-center generic-heading-padding">
                            <Translate id="virtual_v_card.title"></Translate>
                        </div>
                        <Grid container spacing={0} justify="center" >
                            <Grid item md={5} lg={3}>
                                <div>
                                <img src={`${TreVRVCard}.${getExtension()}`} width="300px" alt="giftcardtrever"/>
                                </div>
                            </Grid>

                            <Grid item md={5} lg={4}>
                                <div className="generic-heading align-center generic-heading-padding" style={{ color: "#0098DA", fontSize: "150%", marginTop: "-3%" }}>
                                    <Translate id="virtual_v_card.body-1"></Translate>
                                </div>

                                <div className="align-center pricing-body" style={{ fontSize: "150%" }}>
                                    <Translate id="virtual_v_card.body-2"></Translate>
                                </div>
                                <a href={`${getBaseName()}/buy-gift-cards/`} >
                                    <button className="generic-location-button add-pulse-animation" style={{ fontSize: "150%" }}>
                                        <Translate id="virtual_v_card.button" />
                                    </button>
                                </a>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )
        }

    }

    redirectForCrowdCtrl = () => {
        setLocalStorage("suppressbookingpopup", new Date().setMonth(12), { suppress: true })
        window.location = `${getBaseName()}/booking/location/${this.state.locationData.location_id}`;
    }
    renderPopup() {
        const openPopupButton = (
            <div className="spanning warning-gradient stick-to-bottom"
                onClick={() => {
                    this.setState({ showPopup: true });
                }}
            >
                Temporary Closure Due to COVID-19
            </div>
        );
        if (this.state.showPopup) {
            return (
                <React.Fragment>
                    {openPopupButton}
                    <Popup closePopup={
                        () => {
                            this.setState({ showPopup: false });
                        }
                    }>
                        <div>
                            <div className="padded">
                                Ctrl V {this.state.locationData.display_name}
                                {" "} is temporarily closed due to the recent
                                situation involving the COVID-19 pandemic.
                            </div>
                            <div className="padded">
                                Please
                                <HashLink to="#newsletter" onClick={() => { this.setState({ showPopup: false }) }}> subscribe to our newsletter </HashLink>
                                and
                                follow us on social media for the latest updates.
                            </div>
                            <div className="padded">
                                We look forward to
                                helping you escape reality again soon!
                            </div>
                            <div className="spanning">
                                <div className="styled-div padded ctrlv-blue-button"
                                    onClick={() => {
                                        this.redirectForCrowdCtrl();
                                    }}>
                                    Book a Future Date
                                </div>
                            </div>
                        </div>
                    </Popup>
                </React.Fragment>
            );
        }
        else {
            return openPopupButton;
        }
    }

    render() {
        if (this.state.locationData === null) return null;

        if ((REDIRECTED_LOCATIONS.includes(this.state.locationData.location_id) || this.state.locationData.company_id === 3) && this.state.locationData.home_page !== "") {
            window.location.replace(this.state.locationData.home_page);
            return null;
        }

        return (
            <div className="location-page-wrapper">
                <Helmet>
                    <title> Ctrl V Plano - Virtual Reality Arcade</title>
                    <meta name="description" content={"The world's first VR arcade for affordable, active, family fun!"} />
                    <link rel="canonical" href={`${getBaseName()}/location/plano`} />
                    <meta property="og:title" content="Ctrl V Plano - Virtual Reality Arcade" />
                    <meta property="og:description" content={"The world's first VR arcade for affordable, active, family fun!"} />
                    <meta property="og:url" content={`${getBaseName()}/location/plano`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content={"The world's first VR arcade for affordable, active, family fun!"} />
                    <meta name="twitter:title" content="Ctrl V Plano - Virtual Reality Arcade" />
                    <meta name="keywords" content={`plano,virtual reality,vr,arcade,fun,availability,location,map,directions,pricing,promotions,discounts`}/>
                </Helmet>

                <div className="location-image-slider">
                    <ImageSlider imageData={this.state.banners ?
                        this.state.banners.map((elem) => {
                            return {
                                image: elem.banner_url,
                                link: elem.link_url
                            }
                        }) : [
                            {
                                image: MantaRay,
                                link: '#'
                            },
                            {
                                image: Bogo,
                                link: '#'
                            }
                        ]} />
                </div>

                <BottomAlert locationID={this.state.locationData.location_id}/>
                {/* {this.renderPopup()} */}

                <div className="parallax-wrapper" style={{ backgroundColor: `#050505` }}>

                    <span className="anchor" id="calendar"></span>

                    <div className="generic-heading align-center generic-heading-padding">
                        <Translate id="availability-calendar" />
                    </div>

                    <div className="calendar-wrapper">
                        <div className="calendar-frame-helper">
                            <iframe title="Availability Calendar" src={`${getBaseName()}/availability-calendar/${this.state.locationData.location_id}/1`} width="100%" height="540px" onLoad={this.checkHashScroll} />
                        </div>
                        <div className="generic-heading generic-heading-padding align-center">
                            <Translate id="booking-section" />
                        </div>
                    </div>
                </div>

                {this.renderPricing()}

                {this.renderGiftCard()}

                <div className="parallax-wrapper" style={{ backgroundImage: `url(${extraBoyImg}.${getExtension()})` }}>

                    <div className={isBrowser ? "package-gallery section-wrapper" : "package-gallery"}>
                        <div className="generic-heading generic-heading-padding">
                            <Translate id="packages.title" />
                        </div>
                        <div className="align-center">
                            <Translate id="packages.body" />
                        </div>
                        {this.state.locationData.location_id !== 12 ?
                            (<div className={isBrowser ? "gallery-cell" : "mobile-gallery-cell"}>
                                <Link to={`/birthdays/${this.state.pathname}`} >
                                    <div className="generic-heading">
                                        <Translate id="packages.birthday" />
                                    </div>

                                    <DynamicImg
                                        imageName={Birthday}
                                        fallbackExt='png'
                                        imgClass="generic-image"
                                        alt="Birthday"
                                        width="100%"
                                        height="auto"
                                    />

                                    <div className={isBrowser ? "image-overlay" : ""}></div>
                                </Link>
                            </div>) : null}
                        <div className={isBrowser ? "gallery-cell" : "mobile-gallery-cell"}>
                            <HashLink to={`/events/${this.state.pathname}#group`} >
                                <div className="generic-heading">
                                    <Translate id="packages.group-events" />
                                </div>
                                <DynamicImg
                                    imageName={Group}
                                    fallbackExt='png'
                                    imgClass="generic-image"
                                    alt="Group"
                                    width="100%"
                                    height="auto"
                                />

                                <div className={isBrowser ? "image-overlay" : ""}></div>
                            </HashLink>
                        </div>
                        <div className={isBrowser ? "gallery-cell" : "mobile-gallery-cell"}>
                            <HashLink to={`/events/${this.state.pathname}#mobile`} >
                                <div className="generic-heading">
                                    <Translate id="packages.mobile-events" />
                                </div>
                                <DynamicImg
                                    imageName={Mobile}
                                    fallbackExt='png'
                                    imgClass="generic-image"
                                    alt="Mobile"
                                    width="100%"
                                    height="auto"
                                />

                                <div className={isBrowser ? "image-overlay" : ""}></div>
                            </HashLink>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper location-game-section-padding" style={{ backgroundColor: `#050505` }}>

                    <span className="anchor" id="games"></span>

                    <div>
                        <a href='https://www.ctrlv.ca/escape-rooms'><p className='atitle'><Translate id="games-section.arvi-games.heading" /></p></a>

                        <p className="atext">
                            <Translate id="games-section.arvi-games.start" />
                            <a href='https://www.ctrlv.ca/escape-rooms'><span className='blue-text'>
                                <Translate id="games-section.arvi-games.blue1" />
                            </span></a>
                            <Translate id="games-section.arvi-games.middle" />
                            <span className='blue-text'>
                                <Translate id="games-section.arvi-games.blue2" />
                            </span>
                            <Translate id="games-section.arvi-games.end" />
                        </p>

                        <a href="https://www.ctrlv.ca/escape-rooms"><ArviSlider imageData={this.state.arvibanners ?
                            this.state.arvibanners.map((elem) => {
                                return {
                                    image: elem.img,
                                }
                            }) : [
                                {
                                    image: MantaRay,
                                    link: '#'
                                },
                                {
                                    image: Bogo,
                                    link: '#'
                                }
                            ]} /></a>
                    </div>

                    <FeaturedGames />

                    <LocationDropdown title={<Translate id="games-section.checkout-games" />} body={(
                        <div className="location-games-wrapper">

                            <GameGallery locationId={this.state.locationData.location_id} showFeaturedGames={false} />

                            <p className="games-paragraph location-padding-wrapper location-parent-request">
                                <span className="blue-text">
                                    <Translate id="games-section.parent-request.before" />
                                </span>
                                <Translate id="games-section.parent-request.after" />
                            </p>

                            <div className="location-section-header centered" id="games">
                                <Translate id="games-section.secret-games" />
                            </div>
                        </div>
                    )} />
                </div>
                <div className="parallax-wrapper" style={{ backgroundImage: `url(${extraGirlImg}.${getExtension()})` }}>

                    <div className={isBrowser ? "section-wrapper" : "location-details"}>
                        <div className="location-wrapper location-padding-wrapper">

                            <div className={isBrowser ? "browser-section" : ""}>
                                <div className={isBrowser ? "browser-column" : ""}>

                                    <div className="hours-wrapper location-padding-wrapper">
                                        <div className="location-location-line">
                                            <a target="_blank" href={this.state.locationData.map_url} rel="noopener noreferrer">
                                                {this.state.locationData.address.street_number} {this.state.locationData.address.street}, {this.state.locationData.address.unit !== "" ? "Unit #" + this.state.locationData.address.unit : null} {this.state.locationData.address.city}, {this.state.locationData.address.province}
                                            </a>
                                        </div>

                                        <div className="location-location-line">
                                            <a href={"mailto:" + this.state.locationData.contact.email}>
                                                {this.state.locationData.contact.email}
                                            </a>
                                        </div>
                                        <div className="location-location-line">
                                            <a href={"tel:" + this.state.locationData.contact.phone}>
                                                {this.state.locationData.contact.phone}
                                            </a>
                                        </div>

                                        <FacilityHours location_id={this.state.locationData.location_id} />
                                    </div>
                                </div>
                                <div className={isBrowser ? "browser-column" : "directions-wrapper"}>
                                    <iframe title="Directions Frame" src={`https://www.google.com/maps/embed?pb=${this.state.locationData.google_maps_url}`} width="100%" height="450" frameBorder="0"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="parallax-wrapper" style={{ backgroundColor: `#050505` }}>

                    <div className={isBrowser ? "location-faq location-faq-browser-width" : "location-faq"}>
                        <div className="generic-heading align-center generic-heading-padding">
                            <Translate id="mini-faq.title" />
                        </div>

                        <FaqSection
                            title={<Translate id="mini-faq.whats-included.question" />}
                            body={
                                <div>
                                    <div>
                                        <Translate id="mini-faq.whats-included.title" />
                                    </div>
                                    <div className="faq-list">
                                        <ul>
                                            <li><Translate id="mini-faq.whats-included.bullet-1" /></li>
                                            <li>
                                                <Translate id="mini-faq.whats-included.bullet-2" />
                                            </li>
                                            <li>
                                                <Translate id="mini-faq.whats-included.bullet-3" />
                                            </li>
                                            <li>
                                                <Translate id="mini-faq.whats-included.bullet-4" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        />
                        <FaqSection
                            title={<Translate id="mini-faq.do-you-accept-cash.question" />}
                            body={
                                <div>
                                    <Translate id="mini-faq.do-you-accept-cash.body" />
                                </div>
                            }
                        />
                        <FaqSection
                            title={<Translate id="mini-faq.station-refund-policy.question" />}
                            body={
                                <div>
                                    <div className="faq-list">
                                        <ul>
                                            <li><Translate id="mini-faq.station-refund-policy.bullet-1" /></li>
                                            <li><Translate id="mini-faq.station-refund-policy.bullet-2" /></li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        />
                        <FaqSection
                            title={<Translate id="mini-faq.mobile-event-refund-policy.question" />}
                            body={
                                <div>
                                    <div className="faq-list">
                                        <ul>
                                            <li><Translate id="mini-faq.mobile-event-refund-policy.bullet-1" /></li>
                                            <li><Translate id="mini-faq.mobile-event-refund-policy.bullet-2" /></li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        />
                        <FaqSection
                            title={<Translate id="mini-faq.group-event-refund-policy.question" />}
                            body={
                                <div>
                                    <div className="faq-list">
                                        <ul>
                                            <li><Translate id="mini-faq.group-event-refund-policy.bullet-1" /></li>
                                            <li><Translate id="mini-faq.group-event-refund-policy.bullet-2" /></li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        />
                        <FaqSection
                            title={<Translate id="mini-faq.disclaimer.question" />}
                            body={
                                <div>
                                    <div>
                                    </div>
                                    <div className="faq-list">
                                        <ul>
                                            <li><Translate id="mini-faq.disclaimer.bullet-1" /></li>
                                            <li><Translate id="mini-faq.disclaimer.bullet-2" /></li>
                                            <li><Translate id="mini-faq.disclaimer.bullet-3" /></li>
                                            <li><Translate id="mini-faq.disclaimer.bullet-4" /></li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        />
                        <div className="align-center">
                            <Link to="/faq">
                                <button className="generic-location-button">
                                    <Translate id="mini-faq.show-more-button" />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

                {/* <div className="parallax-wrapper" style={{ backgroundImage: `url(${trevrImg}.${getExtension()})` }}>
                    <span className="anchor" id="reviews"></span>
                    <div className={isBrowser ? "location-review-contact-wrapper" : null}>
                        <div className={isBrowser ? "location-review-wrapper" : null}>
                            <div className="generic-heading align-center generic-heading-padding">
                                <Translate id="reviews.title" />
                            </div>

                            <Reviews
                                reviews={this.state.reviews}
                            />
                        </div>
                    </div>
                </div> */}

                <div className="parallax-wrapper" style={{ backgroundColor: `#050505` }}>
                    <span className="anchor" id="newsletter"></span>
                    <span className="anchor" id="mailinglist"></span>
                    <div className={isBrowser ? "location-review-contact-wrapper" : null}>
                        <div className={isBrowser ? "location-review-wrapper" : null}>

                            <div className="generic-heading generic-heading-padding align-center">
                                <Translate id="newsletter-signup.title" />
                            </div>

                            <div className="contact-form-wrapper location-padding-wrapper">
                                <div className="location-section-header">
                                    <Translate id="newsletter-signup.signup" />
                                </div>

                                <NewsletterSignUp />
                            </div>
                        </div>
                    </div>
                </div>
                {this.checkHashScroll()}
            </div>
        );
    }
}

export default withLocalize(Plano);
