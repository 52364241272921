import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';
import { withRouter } from 'react-router-dom';

import { withLocalize, Translate } from 'react-localize-redux';
import AnniVRsaryTranslation from '../../../translations/annivrsary.json';

import { getBaseName } from '../../../utilities/GlobalConsts';

import '../GenericPage.css';
import '../annivrsary/Annivrsary.css';
import DynamicImg from '../../dynamic_image/DynamicImg';
import Helmet from 'react-helmet';

const AnniVRsaryImage = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/5th_AnniVRsary';
// const AnniVRsaryTitle = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/5th_AnniVRsary_Title'

class AnniVRsary extends Component {
    
    constructor(props) {
        super(props);
        this.props.addTranslation(AnniVRsaryTranslation);
    }

    render() {
        var twitchSection = isBrowser ? 
        <div>
            <div className="generic-wrapper">
                <iframe
                height="400"
                width="80%"
                src="https://player.twitch.tv/?channel=ctrlvca&parent=ctrlv.ca&parent=www.ctrlv.ca&parent=ctrlvarcade.com&parent=www.ctrlvarcade.com&parent=localhost"
                allowfullscreen="<allowfullscreen>">
                </iframe>
            </div>

            <hr className="stats-divider margin"></hr> 
        </div> : null;

        return(
            <div>

                <Helmet>
                    <title>5th AnniVRsary | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Ctrl V is celebrating its 5th AnniVRsary with a special live stream featuring the parents of Ctrl V's co-founder and CEO."/>
                    <link rel="canonical" href={`${getBaseName()}/annivrsary/`} />
                    <meta property="og:title" content="5th AnniVRsary | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Ctrl V is celebrating its 5th AnniVRsary with a special live stream featuring the parents of Ctrl V's co-founder and CEO." />
                    <meta property="og:url" content={`${getBaseName()}/annivrsary/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Ctrl V is celebrating its 5th AnniVRsary with a special live stream featuring the parents of Ctrl V's co-founder and CEO." />
                    <meta name="twitter:title" content="5th AnniVRsary | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="anniversary,live,stream" />
                </Helmet>

                <div className={isBrowser ? "generic-wrapper browser-wrapper" : "generic-wrapper"}>
                    <div className="annivrsary-heading">
                        <Translate id="section-1.body.start" />
                    </div>

                    <div className="annivrsary-title">
                        <span className="blue-text glow annivrsary-stroke">
                            <Translate id="section-1.body.blue-1" />
                        </span>
                        <sup className="superscript-font blue-text glow annivrsary-stroke">
                            <Translate id="section-1.body.blue-2" />
                        </sup>
                        <span className="blue-text glow annivrsary-stroke">
                            <Translate id="section-1.body.blue-3" />
                        </span>
                    </div>

                    <div className="annivrsary-heading">
                        <Translate id="section-1.body.end" />
                    </div>

                    <hr className="stats-divider margin"></hr>

                    <div className="generic-wrapper">
                        <Translate id="section-2.body.start" />
                        <span className="blue-text">
                            <Translate id="section-2.body.blue" />
                        </span>
                        <Translate id="section-2.body.end" />
                    </div>
                </div>

                <div align="center">
                    <iframe width="50%" height="500px"
                        src="https://www.youtube.com/embed/XCZCc7GBqpQ"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                </div>

                <div className={isBrowser ? "generic-wrapper browser-wrapper" : "generic-wrapper"}>
                    <div className="trevr-img">
                        <Translate id="section-3.body.start" />
                        <span className="blue-text">
                            <Translate id="section-3.body.blue-1" />
                        </span>
                        <Translate id="section-3.body.middle-1" />
                        <span className="blue-text">
                            <Translate id="section-3.body.blue-2" />
                        </span>
                        <Translate id="section-3.body.middle-2" />
                        <span className="blue-text">
                            <Translate id="section-3.body.blue-3" />
                        </span>
                        <Translate id="section-3.body.end" />
                    </div>

                    <hr className="stats-divider margin"></hr>
                    
                    <div className="generic-wrapper generic-heading">
                        <div>
                            <Translate id="section-4.body.start" />
                        </div>
                        <div>
                            <span className="blue-text">
                                <Translate id="section-4.body.blue" />
                            </span>
                        </div>
                    </div>

                    <div>
                        <DynamicImg
                            imageName={AnniVRsaryImage}
                            fallbackExt='jpg'
                            alt="Ctrl V Staff"
                            imgClass="box-shadow-image"
                            width="100%"
                        />
                    </div>

                    <div className="generic-wrapper generic-heading">
                        <Translate id="section-5.body.start" />
                        <span className="blue-text">
                            <Translate id="section-5.body.blue" />
                        </span>
                        <Translate id="section-5.body.end" />
                    </div>
                </div>

            </div>
        );
    }

}

export default withLocalize(withRouter(AnniVRsary));