import React from 'react';
import './Popup.css';
const popup = (props) =>{

    return(
        <div className="popupBackdrop"
        onClick={props.closePopup}>
            <div className="popupPositionWrapper">
                <div className="popupWrapper">
                    <div className="popupMain" 
                    onClick={(e)=>{
                        e.stopPropagation();
                    }}>
                        <div className="popupCloseButton"
                        onClick={props.closePopup}>
                            X
                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>        
    );
}

export default popup;