import React, { Component } from 'react';
import { isBrowser } from "react-device-detect";
import { withRouter } from "react-router-dom";

import { withLocalize, Translate } from 'react-localize-redux';
import HomeTranslation from '../../../translations/home.json';
import BottomAlert from '../locations/bottom_alert/bottomAlert'

import './Home.css';
import '../GenericPage.css';
import GameGallery from '../../game_gallery/GameGallery';
import Video from '../../video/Video';
import HomePopup from './../../hompage_popup/HomePopup';

import {
    LOCAL_STORAGE,
    getLocalStorage,
    setLocalStorage,
    localStorageObjectExpired,
    getExtension,
    getBaseName
} from '../../../utilities/GlobalConsts';
// import ReviewScroller from './review_scroller/ReviewScroller';

import ParallaxJs from '../../parallax_js/ParallaxJs.js';
import {Helmet} from 'react-helmet';
import { VisibilityOff } from '@material-ui/icons';


const extraGirlImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/b47def5f-extra-life-page-back-girl-1024x576';
const extraBoyImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/9d75bf6d-extra-life-page-back-boy-1024x576';

const notSmolGirl = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Website_Assets_Girl_3";
const smolGirl = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Website_Assets_Girl_1";
const smolBoy = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Website_Assets_Guy_3";
const bigBoy = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Website_Assets_Guy_1";
const spaceMike = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Website_Assets_Mike_1";

const spacePortal = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/025eaacf-spaceportal";
const background = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/616c0d5b-space-background";
const mantaRayCutout = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/0a1f8e53-spacemanta"

class Home extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(HomeTranslation);
    }

    componentWillUnmount = () => {
        clearTimeout(this.personLoopTimeoutObj);
    }

    state = {
        parallaxObjects: [
            {
                image: background,
                class: "parallax-js-image-background",
                dataDepth: "0",
                loadDelay: -1
            },
            {
                image: spacePortal,
                class: "parallax-js-image-center",
                dataDepth: "0.02",
                loadDelay: 1000
            },
            {
                image: mantaRayCutout,
                class: "parallax-js-image-center",
                dataDepth: "0.06",
                loadDelay: 1500
            },
            {
                image: spaceMike,
                class: "space-mike",
                dataDepth: "0.1",
                loadDelay: -1
            },
            {
                image: smolBoy,
                class: "hide-parallax smol-boy",
                dataDepth: "0.1",
                loadDelay: -1
            },
            {
                image: bigBoy,
                class: "hide-parallax big-boy",
                dataDepth: "0.1",
                loadDelay: -1
            },
            {
                image: notSmolGirl,
                class: "hide-parallax not-smol-girl",
                dataDepth: "0.1",
                loadDelay: -1
            },
            {
                image: smolGirl,
                class: "hide-parallax smol-girl",
                dataDepth: "0.1",
                loadDelay: -1
            }
        ],
        curIndex: 3,
        locationId: null
    }

    personLoop = () => {
        let parallaxClone = this.state.parallaxObjects;

        parallaxClone[3].class = "space-mike";
        parallaxClone[4].class = "smol-boy";
        parallaxClone[5].class = "big-boy";
        parallaxClone[6].class = "not-smol-girl";
        parallaxClone[7].class = "smol-girl";

        let curIndex = this.state.curIndex;
        curIndex++;
        if(curIndex === 8) {
            curIndex = 3;
        }

        for(let i = 3; i <= 7; i++) {
            if(i === curIndex) {
                parallaxClone[curIndex].class += " parallax-js-image-center";
            } else {
                parallaxClone[i].class += " hide-parallax";
            }
        }

        this.setState({
            parallaxObjects: parallaxClone,
            curIndex: curIndex
        })

        this.personLoopTimeoutObj = setTimeout(this.personLoop, 2500);
    }

    componentDidMount() {
        this.personLoop();
        let location = getLocalStorage('location');
        if(location){
            this.setState({locationId: location.location_id})
        }
        let lastRedirect = getLocalStorage('last_redirect');

        if(location && !localStorageObjectExpired(location)) {
            if(!lastRedirect || localStorageObjectExpired(lastRedirect)) {
                // set localStorage to prevent redirects again, if they want to view a different location
                setLocalStorage(LOCAL_STORAGE.LAST_REDIRECT.KEY, LOCAL_STORAGE.LAST_REDIRECT.EXPIRY_DURATION);
                this.props.history.push('/location/' + location.closestLocation)
            }
        }
    }

    // This function is for the COVID 19 emergency
    redirectForCrowdCtrl = () =>{
        setLocalStorage("suppressbookingpopup", new Date().setMonth(12), true )
        window.location = `${getBaseName()}/booking`
    }

    render() {
        return(
            <div className="home-wrapper">

                <Helmet>
                    <title> Ctrl V | Virtual Reality Arcade</title>
                    <meta name="description" content="Ctrl V is the first virtual reality arcade in the world and is safe, active fun for the whole family!"/>
                    <link rel="canonical" href={`${getBaseName()}`} />
                    <meta property="og:title" content="Ctrl V | Virtual Reality Arcade" />
                    <meta property="og:description" content="Ctrl V is the first virtual reality arcade in the world and is safe, active fun for the whole family!" />
                    <meta property="og:url" content={`${getBaseName()}`}  />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Ctrl V is the first virtual reality arcade in the world and is safe, active fun for the whole family!" />
                    <meta name="twitter:title" content="Ctrl V | Virtual Reality Arcade" />
                    <meta name="keywords" content="virtual,reality,arcade,virtual reality,vr,fun,active,safe,everyone,kids,party,team,group,affordable,birthday" />
                </Helmet>

                {/* <HomePopup /> */}

                <div className={ isBrowser ? "desktop-manta-ray-container" : "mobile-manta-ray-container" }>

                    <ParallaxJs objects={this.state.parallaxObjects} limitX={100} limitY={5} />

                    <div className={ isBrowser ? "manta-ray-heading manta-ray-heading-2 desktop-banner" : "manta-ray-heading manta-ray-heading-2 mobile-banner" }>
                        <div className="black-box">
                            <Translate id="banner.title" />
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${extraGirlImg}.${getExtension()})`}}>

                    <div className="browser-section">
                        <div className={isBrowser ? "browser-column" : ""}>
                            <div className={ isBrowser ? "browser-text-padding larger-paragraph" : "paragraph" }>
                                <Translate id="what-is-section.body" />
                            </div>

                            <div className={ isBrowser ? "browser-text-padding larger-paragraph" : "paragraph" }>
                                <Translate id="what-is-section.after-body" />
                            </div>
                        </div>
                        <div className={isBrowser ? "browser-column" : ""}>
                            <div className={ isBrowser ? "browser-text-padding home-page-video" : null }>
                                <Video video="99hD3NOEzQA"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundColor: `#111111`}}>

                    <div className="generic-heading game-section-padding">
                        <Translate id="games-section.title" />
                    </div>

                    <GameGallery locationId="1" showFeaturedGames={true} />

                    <p className="paragraph">
                        <span className="blue-text">
                            <Translate id="games-section.parental-warning.before" />
                        </span>
                        <Translate id="games-section.parental-warning.main" />
                    </p>

                </div>
                <div className="parallax-wrapper" style={{backgroundImage: `url(${extraBoyImg}.${getExtension()})`}}>
                    <div className="alignment-wrapper">
                        <button className={ isBrowser ? "ctrlv-blue-button-browser" : "ctrlv-blue-button" } onClick={() => this.props.history.push("/locations")}>
                            <Translate id="button-section.location" />
                        </button>
                    </div>

                    <div className="alignment-wrapper">
                        <Translate id="button-section.or" />
                    </div>

                    <div className="alignment-wrapper">
                        <a href={`${getBaseName()}/booking/location/`}>
                            <button className={ isBrowser ? "ctrlv-blue-button-browser" : "ctrlv-blue-button" }>
                                <Translate id="button-section.book-now" />
                            </button>
                        </a>
                    </div>

                    {/* <div className="homepage-review-wrapper">
                        <ReviewScroller />
                    </div> */}
                </div>
                <BottomAlert locationID={this.state.locationId} homePage={true}/>
            </div>
        );
    }
}

export default withLocalize(withRouter(Home));
