import React, { Component } from 'react';
import Axios from 'axios';

import { isBrowser } from 'react-device-detect';
import { HashLink } from 'react-router-hash-link';

import { withLocalize, Translate } from 'react-localize-redux';
import BirthdayTranslations from '../../../translations/birthdays.json';

import {
    WEBCACHE_API,
    getExtension,
    getBaseName
} from '../../../utilities/GlobalConsts';

import './Packages.css';
import '../GenericPage.css';
import Video from '../../video/Video.js';
import {Helmet} from 'react-helmet';

const background1 = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/aab9f1a5-arcade-fade-dodgeball';
const background2 = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/7e84fa94-arcade-fade-wonder';
const background3 = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/ed0d1541-waterloo-party-room-fade';

const extraGirlImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/b47def5f-extra-life-page-back-girl-1024x576';
const extraBoyImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/9d75bf6d-extra-life-page-back-boy-1024x576';
const BIRTHDAY_REPLACEMENT_STRINGS = ["max_capacity", "min_stations", "package_price", "example_stations", "max_stations", "party_room_duration"];



class BirthdaysWaterloo extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(BirthdayTranslations);

        this.state = {
            location: "waterloo",
            packagePrice: undefined,
            numStations: undefined,
            priceDisabled: false
        }
    }

    birthdayStringReplacement = (birthdayData, birthdayKey) => {
        let result = birthdayData[birthdayKey];

        // eslint-disable-next-line
        for (const key in BIRTHDAY_REPLACEMENT_STRINGS) {
            result = result.replace(`[${BIRTHDAY_REPLACEMENT_STRINGS[key]}]`, birthdayData[BIRTHDAY_REPLACEMENT_STRINGS[key]]);
        }

        return result;
    }

    getLocationData = async () => {

        try {
            let response = await Axios.get(WEBCACHE_API.GET_LOCATION_EVENTDATA + this.state.location);

            let birthdayData = response.data.birthdayData.birthdaydata;
            birthdayData.party_description = this.birthdayStringReplacement(birthdayData, 'party_description');
            birthdayData.footnote = this.birthdayStringReplacement(birthdayData, 'footnote');
            birthdayData.vr_is_for_everyone = this.birthdayStringReplacement(birthdayData, 'vr_is_for_everyone');
            birthdayData.whats_included = this.birthdayStringReplacement(birthdayData, 'whats_included');
            this.setState({
                packagePrice: birthdayData.package_price,
                priceDisabled: birthdayData.package_price === "-1.00",

                partyDescription: birthdayData.party_description,
                partyDescription_id: birthdayData.party_description_id,

                footnote: birthdayData.footnote,
                footnote_id: birthdayData.footnote_id,

                vrIsForEveryone: birthdayData.vr_is_for_everyone,
                vrIsForEveryone_id: birthdayData.vr_is_for_everyone_id,

                whatsIncluded: birthdayData.whats_included,
                whatsIncluded_id: birthdayData.whats_included_id,

                locationId: birthdayData.location_id
            });

        } catch (err) {
            console.error(err);
        }

    }

    componentDidMount = () => {
        this.getLocationData();
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Birthday Parties | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Celebrate your birthday at Ctrl V with VR stations offering content for all ages! Bring your own food to our Party Room after the VR experience!" />
                    <link rel="canonical" href={`${getBaseName()}/birthdays/waterloo/`} />
                    <meta property="og:title" content="Birthday Parties | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Celebrate your birthday at Ctrl V with VR stations offering content for all ages! Bring your own food to our Party Room after the VR experience!" />
                    <meta property="og:url" content={`${getBaseName()}/birthdays/waterloo/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Celebrate your birthday at Ctrl V with VR stations offering content for all ages! Bring your own food to our Party Room after the VR experience!" />
                    <meta name="twitter:title" content="Birthday Parties | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content={`birthday,party,kids,teen,adult,waterloo`} />
                </Helmet>

                {this.state.partyDescription_id !== "-2" && this.state.footnote_id !== "-2" ?
                    (<div className="parallax-wrapper" style={isBrowser ? { backgroundImage: `url(${background1}.${getExtension()})` } : { backgroundImage: `url(${extraBoyImg}.${getExtension()})` }}>
                        <div className="event-section">
                            <div className="vertical-align-helper">
                                <div className={isBrowser ? "event-right" : "mobile-event"}>
                                    <div className="generic-heading generic-heading-padding">
                                        <Translate id="section-1.title" />
                                    </div>
                                    <div className="generic-sub-heading blue-text">
                                        <Translate id="section-1.sub-title" />
                                    </div>
                                    {this.state.partyDescription_id !== "-2" ?
                                        (<div className="generic-section">
                                            {this.state.partyDescription}
                                        </div>) : null
                                    }
                                    {!this.state.priceDisabled ?
                                        (<div className="generic-heading align-center">
                                            ${ this.state.packagePrice}*
                                        </div>) : null
                                    }
                                    {this.state.footnote_id !== "-2" ?
                                        (<div className="generic-footnote">
                                            {this.state.footnote}
                                        </div>) : null
                                    }
                                    <div className="align-center">
                                        <a href={`${getBaseName()}/booking/location/${this.state.locationId}`}>
                                            <button className="generic-location-button generic-pulse-animation">
                                                <Translate id="book-now-at" /> {this.state.location !== undefined ? this.state.location.toUpperCase() : null}
                                            </button>
                                        </a>
                                        <HashLink to={`#how-to`}>
                                            <button className="generic-location-button generic-pulse-animation">
                                                <Translate id="how-to-book" />
                                            </button>
                                        </HashLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>) : null
                }
                {this.state.vrIsForEveryone_id !== "-2" ?
                    (<div className="parallax-wrapper" style={{ backgroundImage: `url(${background2}.${getExtension()}` }}>
                        <div className="event-section">
                            <div className="vertical-align-helper">
                                <div className={isBrowser ? "event-left" : "mobile-event"}>
                                    <div className="generic-heading">
                                        <Translate id="section-2.title" />
                                    </div>
                                    <div className="generic-section">
                                        {this.state.vrIsForEveryone}
                                    </div>
                                    <div className="align-center">
                                        <a href={`${getBaseName()}/booking/location/${this.state.locationId}`}>
                                            <button className="generic-location-button generic-pulse-animation">
                                                <Translate id="book-now-at" /> {this.state.location !== undefined ? this.state.location.toUpperCase() : null}
                                            </button>
                                        </a>
                                        <HashLink to={`#how-to`}>
                                            <button className="generic-location-button generic-pulse-animation">
                                                <Translate id="how-to-book" />
                                            </button>
                                        </HashLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>) : null
                }

                {this.state.whatsIncluded_id !== "-2" ?
                    (<div className="parallax-wrapper" style={isBrowser ? { backgroundImage: `url(${background3}.${getExtension()})` } : { backgroundImage: `url(${extraGirlImg}.${getExtension()}))` }}>
                        <div className="event-section">
                            <div className="vertical-align-helper">
                                <div className={isBrowser ? "event-right" : "mobile-event"}>
                                    <div className="generic-heading">
                                        <Translate id="section-3.title" />
                                    </div>

                                    <div className="generic-section">
                                        {this.state.whatsIncluded}
                                    </div>
                                    <div className="align-center">
                                        <a href={`${getBaseName()}/booking/location/${this.state.locationId}`}>
                                            <button className="generic-location-button generic-pulse-animation">
                                                <Translate id="book-now-at" /> {this.state.location !== undefined ? this.state.location.toUpperCase() : null}
                                            </button>
                                        </a>
                                        <HashLink to={`#how-to`}>
                                            <button className="generic-location-button generic-pulse-animation">
                                                <Translate id="how-to-book" />
                                            </button>
                                        </HashLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>) : null
                }
                <div className="parallax-wrapper" style={{ backgroundImage: `url(${extraGirlImg}.${getExtension()}` }}>
                    <div className={isBrowser ? "browser-section" : "mobile-wrapper"}>
                        <div className={isBrowser ? "browser-column" : ""}>
                            <div className={isBrowser ? "birthday-padding align-center" : ""}>

                                <div className="generic-heading">
                                    <Translate id="section-4.title" />
                                </div>
                                <div className="generic-section">
                                    <Translate id="section-4.body.before" />
                                    <a href={`${getBaseName()}/birthday-invitation/`} target="_blank" rel="noopener noreferrer">
                                        <Translate id="section-4.body.link" />
                                    </a>
                                    <Translate id="section-4.body.after" />
                                </div>

                                <div className="align-center">
                                    <a href={`${getBaseName()}/booking/location/${this.state.locationId}`}>
                                        <button className="generic-location-button generic-pulse-animation">
                                            <Translate id="book-now-at" /> {this.state.location !== undefined ? this.state.location.toUpperCase() : null}
                                        </button>
                                    </a>
                                    <HashLink to={`#how-to`}>
                                        <button className="generic-location-button generic-pulse-animation">
                                            <Translate id="how-to-book" />
                                        </button>
                                    </HashLink>
                                </div>
                            </div>
                        </div>
                        <div className={isBrowser ? "browser-column" : ""}>
                            <div className={isBrowser ? "birthday-padding" : ""}>
                                <Video video="hLH_ah9mA80" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="parallax-wrapper" style={{ backgroundImage: `url(${extraBoyImg}.${getExtension()}` }}>
                    <div className={isBrowser ? "browser-section" : "mobile-wrapper"}>
                        <span className="anchor" id="how-to"></span>
                        <div className={isBrowser ? "birthday-video-center" : ""}>
                            <div className="generic-heading">
                                <div className="align-center">
                                    How to Book a Birthday Party!
                                </div>
                                <div className={isBrowser ? "birthday-video" : ""}>
                                    <Video video="sreBkHgsDDU" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withLocalize(BirthdaysWaterloo);
