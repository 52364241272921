import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';
import { Link } from 'react-router-dom';

import { withLocalize, Translate } from 'react-localize-redux';
import AboutTranslation from '../../../translations/about.json';

import '../GenericPage.css';
import './About.css';

import ParallaxJs from '../../parallax_js/ParallaxJs.js';
import DynamicImg from '../../dynamic_image/DynamicImg.js';
import {Helmet} from 'react-helmet';

import {
    getExtension,
    getBaseName
} from '../../../utilities/GlobalConsts';

const shootyImg = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/d3521bbc-vr2_final2-1024x576";
const aisleImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/aisle';
const stationImg = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/9e2b2a02-stations-high-1-1024x681"

const ryanHead = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/RyanHead';
const jamesHead = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/JamesHead';
const robHead = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/RobHead';

const notSmolGirl = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Website_Assets_Girl_3";
const smolGirl = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Website_Assets_Girl_1";
const smolBoy = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Website_Assets_Guy_3";
const bigBoy = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Website_Assets_Guy_1";
const spaceMike = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Website_Assets_Mike_1";

const spacePortal = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/025eaacf-spaceportal";
const background = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/616c0d5b-space-background";
const mantaRayCutout = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/0a1f8e53-spacemanta"

const trevrImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/trevr';
const extraGirlImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/b47def5f-extra-life-page-back-girl-1024x576';
const extraBoyImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/9d75bf6d-extra-life-page-back-boy-1024x576';

class About extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(AboutTranslation);
    }
    componentWillUnmount = () => {
        clearTimeout(this.personLoopTimeoutObj);
    }

    state = {
        parallaxObjects: [
            {
                image: background,
                class: "parallax-js-image-background",
                dataDepth: "0",
                loadDelay: -1,
                ext: 'jpg'
            },
            {
                image: spacePortal,
                class: "parallax-js-image-center",
                dataDepth: "0.02",
                loadDelay: 1000,
                ext: 'png'
            },
            {
                image: mantaRayCutout,
                class: "parallax-js-image-center",
                dataDepth: "0.06",
                loadDelay: 1500,
                ext: 'png'
            },
            {
                image: spaceMike,
                class: "space-mike",
                dataDepth: "0.1",
                loadDelay: -1,
                ext: 'png'
            },
            {
                image: smolBoy,
                class: "hide-parallax smol-boy",
                dataDepth: "0.1",
                loadDelay: -1,
                ext: 'png'
            },
            {
                image: bigBoy,
                class: "hide-parallax big-boy",
                dataDepth: "0.1",
                loadDelay: -1,
                ext: 'png'
            },
            {
                image: notSmolGirl,
                class: "hide-parallax not-smol-girl",
                dataDepth: "0.1",
                loadDelay: -1,
                ext: 'png'
            },
            {
                image: smolGirl,
                class: "hide-parallax smol-girl",
                dataDepth: "0.1",
                loadDelay: -1,
                ext: 'png'
            }
        ],
        curIndex: 3
    }

    personLoop = () => {
        let parallaxClone = this.state.parallaxObjects;
        
        parallaxClone[3].class = "space-mike";
        parallaxClone[4].class = "smol-boy";
        parallaxClone[5].class = "big-boy";
        parallaxClone[6].class = "not-smol-girl";
        parallaxClone[7].class = "smol-girl";

        let curIndex = this.state.curIndex;
        curIndex++;
        if(curIndex === 8) {
            curIndex = 3;
        }

        for(let i = 3; i <= 7; i++) {
            if(i === curIndex) {
                parallaxClone[curIndex].class += " parallax-js-image-center";
            } else {
                parallaxClone[i].class += " hide-parallax";
            }
        }

        this.setState({
            parallaxObjects: parallaxClone,
            curIndex: curIndex
        })

        this.personLoopTimeoutObj = setTimeout(this.personLoop, 2500);
    }

    componentDidMount() {
        this.personLoop();
    }

    render() {
        return(
            <React.Fragment>
                <Helmet>
                    <title>The Ctrl V Story | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Learn more about virtual reality, who we are, what we do, our partnerships, social endeavours and more."/>
                    <link rel="canonical" href={`${getBaseName()}/about/`} /> 
                    <meta property="og:title" content="The Ctrl V Story | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Learn more about virtual reality, who we are, what we do, our partnerships, social endeavours and more." />
                    <meta property="og:url" content={`${getBaseName()}/about/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Learn more about virtual reality, who we are, what we do, our partnerships, social endeavours and more." />
                    <meta name="twitter:title" content="The Ctrl V Story | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="about,story,background,team" />
                </Helmet>

                <div className="about-wrapper">
                    <div className="parallax-wrapper" style={{backgroundColor: `#050505`}}>

                        <div className={ isBrowser ? "desktop-manta-ray-container" : "mobile-manta-ray-container" }>
                            <ParallaxJs objects={this.state.parallaxObjects} limitX={100} limitY={5} />
                        </div>

                        <div className="generic-section">
                            <p className="align-center">
                                <Translate id="section-1.body-1" />
                            </p>
                            <p className="align-center">
                                <Translate id="section-1.body-2" />
                            </p>
                        </div>
                    </div>

                    <div className="parallax-wrapper" style={{backgroundImage: `url(${extraGirlImg}.${getExtension()})`}}>

                        <div className="generic-heading generic-heading-padding">
                            <Translate id="section-2.title" />
                        </div>

                        <div className={isBrowser ? "browser-section" : "mobile-wrapper"}>
                            <div className={isBrowser ? "browser-column" : ""}>
                                <div className={isBrowser ? "browser-text-padding" : ""}>
                                    <div className="generic-sub-heading">
                                        <Translate id="section-2.body.what.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="section-2.body.what.body" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="section-2.body.who.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="section-2.body.who.body" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="section-2.body.where.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="section-2.body.where.body-1.before" />

                                        <span className="blue-text">
                                            <Link to="/location-map">
                                                <Translate id="section-2.body.where.body-1.link-text" />
                                            </Link>
                                        </span>
                                        
                                        <Translate id="section-2.body.where.body-1.after" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="section-2.body.where.body-2.before" />
                                        
                                        <span className="blue-text">
                                            <a href="https://www.virtualrealityfranchise.com/" target="_blank" rel="noopener noreferrer">
                                                <Translate id="section-2.body.where.body-2.link-text" />
                                            </a>
                                        </span>
                                        
                                        <Translate id="section-2.body.where.body-2.after" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="section-2.body.when.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="section-2.body.when.body" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="section-2.body.why.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="section-2.body.why.body" />
                                    </div>
                                </div>
                            </div>
                            <div className={isBrowser ? "browser-column" : ""}>
                                <DynamicImg
                                    imageName={aisleImg}
                                    fallbackExt='jpg'
                                    imgClass="generic-image"
                                    alt="Ctrl V Glow"
                                    width="100%"
                                    height="auto"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="parallax-wrapper" style={{backgroundColor: `#050505`}}>

                        <div className="generic-heading  generic-heading-padding">
                            <Translate id="section-3.title" /> 
                        </div>

                        <div className={isBrowser ? "browser-section" : "mobile-wrapper"}>
                            <div className={isBrowser ? "browser-column" : ""}>
                                
                                <DynamicImg
                                    imageName={shootyImg}
                                    fallbackExt='jpg'
                                    imgClass="generic-image"
                                    alt="Shooty Girl"
                                    width="100%"
                                    height="auto"
                                />
                            </div>

                            <div className={isBrowser ? "browser-column" : ""}>
                                <div className={isBrowser ? "browser-text-padding" : ""}>
                                    <div className="generic-sub-heading">
                                        <Translate id="section-3.body-1.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="section-3.body-1.body" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="section-3.body-1.body-2" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="section-3.body-2.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="section-3.body-2.body" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="parallax-wrapper" style={{backgroundColor: `#050505`}}>
                        <div className="generic-heading generic-heading-padding">
                            <Translate id="section-5.title" />
                        </div>                
                        
                        <div className={isBrowser ? "browser-section" : "mobile-wrapper"}>
                            <div className={isBrowser ? "browser-column" : ""}>
                                <DynamicImg
                                    imageName={stationImg}
                                    fallbackExt='png'
                                    imgClass="generic-image"
                                    alt="Glowing Station"
                                    width="100%"
                                    height="auto"
                                />
                            </div>
                            <div className={isBrowser ? "browser-column" : ""}>
                                <div className={isBrowser ? "browser-text-padding" : ""}>
                                    <div className="generic-section">
                                        <Translate id="section-5.body" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="section-5.body-2" />
                                    </div>
                                </div>
                            </div>
                        </div>     
                    </div> 
                    
                    <div className="parallax-wrapper" style={{backgroundImage: `url(${trevrImg}.${getExtension()})`}}>
                    
                        <div className="generic-heading generic-heading-padding">
                            <Translate id="section-6.title" />
                        </div>
                        
                        <div className={isBrowser ? "browser-section" : "mobile-wrapper"}>
                            <div className={isBrowser ? "browser-column" : ""}>
                                <div className={isBrowser ? "browser-text-padding" : ""}>
                                    <div className="generic-section">
                                        <Translate id="section-6.body" />
                                    </div>
                                </div>
                            </div>

                            <div className={isBrowser ? "browser-column center-image" : "center-image"}>
                                
                                <DynamicImg
                                    imageName="https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/b0ab7489-extra-life_cmnhosp_white"
                                    fallbackExt='png'
                                    imgClass=""
                                    alt="Glowing Station"
                                    width="auto"
                                    height="auto"
                                />
                            </div>
                        </div>  
                    </div>        
                </div>
            </React.Fragment>
        );
    }
}

export default withLocalize(About);