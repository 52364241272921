import React, { Component } from 'react';
import Axios from 'axios';
import {
    WEBCACHE_API,
    getExtension
} from '../../utilities/GlobalConsts'

import './Search.css';
import SmartLink  from '../image_slider/SmartLink';

class Search extends Component {

    constructor(props){
        super(props);

        this.state = {
            keywords: "",
            results: undefined,
            showSearch: false
        }

        this.lastPost = 0;
        this.searchRef = React.createRef();
    }

    stopwords = ["above", "after", "again", "against", "all", "am", "an", "and", "any", "are", "aren't", "as", "at", "be", "because", "been", "before", "being", "below", "between", "both", "but", "by", "can't", "cannot", "could", "couldn't", "did", "didn't", "do", "does", "doesn't", "doing", "don't", "down", "during", "each", "few", "for", "from", "further", "had", "hadn't", "has", "hasn't", "have", "haven't", "having", "he", "he'd", "he'll", "he's", "her", "here", "here's", "hers", "herself", "him", "himself", "his", "how", "how's", "i'd", "i'll", "i'm", "i've", "if", "in", "is", "isn't", "it", "it's", "its", "itself", "let's", "me", "more", "most", "mustn't", "my", "myself", "no", "nor", "not", "of", "off", "on", "once", "only", "or", "other", "ought", "our", "ours", "ourselves", "out", "over", "own", "same", "shan't", "she", "she'd", "she'll", "she's", "should", "shouldn't", "so", "some", "such", "than", "that", "that's", "the", "their", "theirs", "them", "themselves", "then", "there", "there's", "these", "they", "they'd", "they'll", "they're", "they've", "this", "those", "through", "to", "too", "under", "until", "up", "very", "was", "wasn't", "we", "we'd", "we'll", "we're", "we've", "were", "weren't", "when's","while", "who's", "whom", "why's", "won't", "would", "wouldn't", "you", "you'd", "you'll", "you're", "you've", "yours", "yourself", "yourselves"];

    componentDidUpdate (){
        if(this.state.showSearch){
            this.searchRef.current.focus();
        }
    }

    getSearchResults = async () => {
        // remove extra spaces in query, and split on spaces
        let keywords = this.state.keywords.replace(/\s+/g,' ').split(' ');

        // filter duplicates out
        keywords = keywords.map(item =>{
            return item.toLowerCase();
        })
        keywords = keywords.filter((item, index) => {
            const isFirstIndex = (keywords.indexOf(item) === index);
            const notStopWord = (!this.stopwords.includes(item));
            const notEmpty = (item.length > 0);

           return isFirstIndex && notStopWord && notEmpty;
        });
        try {
            let response = await Axios.post(WEBCACHE_API.GET_SEARCH_RESULTS, { keywords: keywords });

            if(response.data.success) {
                this.setState({
                    results: response.data.results
                });
            } else {
                this.setState({
                    results: []
                });
            }

        } catch(err) {
            console.error("Error getting search results");
            console.error(err);
        }
    }

    updateQuery = (event) => {
        this.setState({
            keywords: event.target.value
        })
    }

    handleSearchKeyDown = (e) =>{
        if(e.keyCode===13){
            this.getSearchResults();
        }
        else if(e.keyCode === 27){
            this.searchRef.current.blur();
        }
    }

    getControls = () => {
        if(this.state.showSearch) {
            return (
                <div className="search-control-wrapper">
                    <div className="search-controls">
                        <input
                        ref={this.searchRef}
                        type="text"
                        value={this.state.keywords}
                        onChange={this.updateQuery}
                        onKeyDown={this.handleSearchKeyDown}
                        className="search-textbox" />
                        <button
                        onClick={this.getSearchResults} className="search-button">Search</button>
                    </div>
                    <div className="search-result-list">
                        {this.displayResults()}
                    </div>
                </div>
            );
        } else return null;
    }
    getInlineControls = () =>{
        if(this.state.showSearch){
            return(
                <div className="inline-search-wrapper">
                    <div>
                        <input
                            ref={this.searchRef}
                            type="text"
                            value={this.state.keywords}
                            onChange={this.updateQuery}
                            onKeyDown={this.handleSearchKeyDown}
                            className="inline-search-textbox" />
                        <button
                            onClick={this.getSearchResults}
                            className="inline-search-button">
                                Go
                            </button>
                    </div>
                    <div className="search-result-list-inline">
                        {this.displayResults()}
                    </div>
                </div>
            )
        }
    }
    displayResults = () => {
        if(!this.state.showSearch) return null;
        if(this.state.results !== undefined && this.state.results.length === 0) return (<div className="search-result">NO RESULTS</div>)

        let divyBois = []
        for(const key in this.state.results) {
            divyBois.push(
                <SmartLink
                key={key}
                to={this.state.results[key].page_url} onClick={this.toggleSearch}>
                    <div className="search-result">
                        {this.state.results[key].anchor_description}
                    </div>
                </SmartLink>
            )
        }
        return divyBois;
    }

    toggleSearch = () => {
        const newState = {...this.state};
        newState.showSearch = !this.state.showSearch;

        if(!newState.showSearch) {
            newState.results = undefined;
        }

        this.setState(newState);

    }

    render = () => {
        if(this.props.inline){
            // The ternary is for when it opens, we only want the text to be clickable
            return(
                <div
                id="search"
                className="searchText"
                onClick={this.state.showSearch? ()=>{} : this.toggleSearch }
                >
                    <p onClick={this.state.showSearch? this.toggleSearch : ()=>{} }>Search</p>
                    {this.getInlineControls()}
                </div>
            )
        }
        else{
            return (
                <div >
                    <img style={{display:this.props.display}}
                        id="search"
                        className="searchIcon"
                        alt='Search'
                        onClick={this.toggleSearch}
                        src={`https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/search_icon.${getExtension()}`}
                    />
                        {this.getControls()}
                </div>
            );
        }
    }
}

export default Search;
