import React, { Component } from 'react';
import DynamicImg from '../../dynamic_image/DynamicImg';
const SmashBoxImage = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/smashbox';

class SmashboxLeague extends Component {
    render() {
        return (
            <div className="generic-wrapper">
                <div className="generic-heading">
                    SmashBox League
                </div>
                
                <DynamicImg
                    imageName={SmashBoxImage}
                    fallbackExt='jpg'
                    imgClass="generic-image"
                    alt="Smashbox Banner"
                    width="100%"
                    height="auto"
                />

                <div className="generic-sub-heading">
                    July - August, 2019
                </div>

                <div className="generic-section">
                    Ctrl V’s annual <span className="blue-text">VR Dodgeball League</span> is happening this summer!
                </div>
                <div className="generic-section">
                    Join teams of three people to compete against other Ctrl V customers in the epic game, Smashbox Arena!
                </div>
                <div className="generic-section">
                    Only <span className="blue-text">$99.99</span> + tax per person for up to eight (8) weeks of dodging, diving, ducking and dominating plus an official <span className="blue-text">Ctrl V Smashbox League T-shirt!</span>
                </div>

                <button className="generic-location-button">Sign up now</button>
            </div>
        );
    }
}

export default SmashboxLeague;