import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Axios from 'axios';
import './FeaturedGames.css';
import { withLocalize, Translate } from 'react-localize-redux';
import GameGalleryTranslation from '../../../translations/game_gallery.json';
import {tryAddImageExtension } from '../../../utilities/GlobalConsts';

import {
    WEBCACHE_API
} from '../../../utilities/GlobalConsts';

class FeaturedGames extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(GameGalleryTranslation);
    }

    state = {
        featuredGames: []
    }

    // retrieves 5 featured games of the month
    getFeaturedGames = async () => {
        let date = moment();

        let month = date.format('MM');
        let year = date.format('YYYY');

        // get first monday of month
        let isoDate = moment().set('year', year).set('month', month - 1).set('date', 1).isoWeekday(8)
        if(isoDate.date() === 8) {
            isoDate = isoDate.isoWeekday(-6)
        }

        // check if its before first monday of month (reset day)
        if(date.diff(isoDate) < 0 && date.format('dd') !== isoDate.format('dd')) {
            month--;
        }
        try {
            let response = await Axios.get(`${WEBCACHE_API.GET_FEATURED_GAMES}/${year}/${parseInt(month)}`);
            if(response.data.success) {
                this.setState({
                    featuredGames: response.data.featuredGames
                });
            }

        } catch(err) {
            console.error("Error getting Featured Games")
            console.error(err);
        }
    }
    renderFeaturedGames = () => {
        let featuredGames = [];

        // eslint-disable-next-line
        for(let key in this.state.featuredGames) {
            featuredGames.push(
                <div className={isBrowser ? "featured-game" : null} key={key}>
                    <div className="featured-game-padding">
                        <Link to={`/games/${this.state.featuredGames[key].game_url_name}`}>
                            <img src={tryAddImageExtension(this.state.featuredGames[key].game_logo)} alt="Game Logo" className="generic-image featured-game-image" />
                            <div className="image-overlay"></div>
                        </Link>
                    </div>
                </div>
            )
        }

        return (
            featuredGames.length > 0 ?
                <div className="featured-games-section-wrapper">
                    <div className="featured-games-heading">
                        <Translate id="featured-games" />
                    </div>

                    <div className="featured-game-wrapper">
                        {featuredGames}
                    </div>
                </div>
            : null
        )
    }

    componentDidMount = () => {
        // get 5 featured games for the month
        this.getFeaturedGames();
    }

    render() {
        return this.renderFeaturedGames()
    }
}

export default withLocalize(FeaturedGames);
