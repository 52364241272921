/* global safari */

import React, { Component } from 'react';
import './UnsupportedBrowser.css';
import { withLocalize, Translate } from 'react-localize-redux';
import UnsupportedBrowserTranslations from '../../translations/unsupported_browser.json';

import {
    LOCAL_STORAGE,
    getLocalStorage,
    localStorageObjectExpired,
    setLocalStorage
} from '../../utilities/GlobalConsts';

class UnsupportedBrowser extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(UnsupportedBrowserTranslations);

        this.state = {
            showModal: this.isFirstVisit() && this.isUnsupportedBrowser()
        }
    }

    isFirstVisit = () => {
        const unsupportedBrowserCheck = getLocalStorage(LOCAL_STORAGE.UNSUPPORTED_BROWSER_CHECK.KEY);
        return (unsupportedBrowserCheck === null || localStorageObjectExpired(unsupportedBrowserCheck))
    }

    isUnsupportedBrowser = () => {
        const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        
        return isSafari || isIE;
    }

    closeModal = () => {
        setLocalStorage(LOCAL_STORAGE.UNSUPPORTED_BROWSER_CHECK.KEY, LOCAL_STORAGE.UNSUPPORTED_BROWSER_CHECK.EXPIRY_DURATION);
        this.setState({
            showModal: false
        })
    }

    escapeCloseModal = (event) => {
        if(event.keyCode === 27) {
            this.closeModal();
        }
    }

    preventClose = (event) => {
        event.stopPropagation();
    }

    render() {

        if(this.state.showModal) {
            return (
                <div className="unsupported-browser-modal-wrapper" onClick={this.closeModal} tabIndex="0" onKeyDown={this.escapeCloseModal}>
                    <div className="unsupported-browser-modal" onClick={this.preventClose}>
                        <button className="close-button" onClick={ this.closeModal}>
                            X
                        </button>

                        <Translate id="message" />
                    </div>
                </div>

            )
        } else return null;
    }
}

export default withLocalize(UnsupportedBrowser);