import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';

import { withLocalize, Translate } from 'react-localize-redux';
import TermsOfUseTranslation from '../../../../translations/terms_of_use.json';

import { getBaseName } from '../../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

import '../../GenericPage.css';

class TermsOfUse extends Component {
    
    constructor(props) {
        super(props);

        this.props.addTranslation(TermsOfUseTranslation);
    }

    render() {
        return(
            <div className={isBrowser ? "generic-wrapper align-left blue-sub-headings browser-wrapper" : "generic-wrapper align-left blue-sub-headings"}>

                <Helmet>
                    <title>View our Terms of Use | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Terms of Use for Ctrl V - Virtual Reality. Each user must read and abide by these terms. Those below the age of 18 must be represented by an adult."/>
                    <link rel="canonical" href={`${getBaseName()}/legal/terms-of-use/`} />
                    <meta property="og:title" content="View our Terms of Use | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Terms of Use for Ctrl V - Virtual Reality. Each user must read and abide by these terms. Those below the age of 18 must be represented by an adult." />
                    <meta property="og:url" content={`${getBaseName()}/legal/terms-of-use/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Terms of Use for Ctrl V - Virtual Reality. Each user must read and abide by these terms. Those below the age of 18 must be represented by an adult." />
                    <meta name="twitter:title" content="View our Terms of Use | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="terms" />
                </Helmet>

                <div className="generic-heading generic-heading-padding">
                    <Translate id="title" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="introduction.title" />
                </div>
                <div className="generic-section">
                    <Translate id="introduction.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="introduction.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="introduction.body-3" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="license-of-use.title" />
                </div>
                <div className="generic-section">
                    <Translate id="license-of-use.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="license-of-use.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="license-of-use.body-3.first-line" />
                    <ul>
                        <li><Translate id="license-of-use.body-3.list.item-1" /></li>
                        <li><Translate id="license-of-use.body-3.list.item-2" /></li>
                        <li><Translate id="license-of-use.body-3.list.item-3" /></li>
                        <li><Translate id="license-of-use.body-3.list.item-4" /></li>
                        <li><Translate id="license-of-use.body-3.list.item-5" /></li>
                        <li><Translate id="license-of-use.body-3.list.item-6" /></li>
                    </ul>
                </div>
                <div className="generic-section">
                    <Translate id="license-of-use.body-4" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="acceptable-use.title" />
                </div>
                <div className="generic-section">
                    <Translate id="acceptable-use.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="acceptable-use.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="acceptable-use.body-3" />
                </div>
                <div className="generic-section">
                    <Translate id="acceptable-use.body-4" />
                </div>
                <div className="generic-section">
                    <Translate id="acceptable-use.body-5" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="restricted-access.title" />
                </div>
                <div className="generic-section">
                    <Translate id="restricted-access.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="restricted-access.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="restricted-access.body-3" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="user-content.title" />
                </div>
                <div className="generic-section">
                    <Translate id="user-content.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="user-content.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="user-content.body-3" />
                </div>
                <div className="generic-section">
                    <Translate id="user-content.body-4" />
                </div>
                <div className="generic-section">
                    <Translate id="user-content.body-5" />
                </div>
                <div className="generic-section">
                    <Translate id="user-content.body-6" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="no-warrenties.title" />
                </div>
                <div className="generic-section">
                    <Translate id="no-warrenties.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="no-warrenties.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="no-warrenties.body-3" />
                </div>
                <div className="generic-section">
                    <Translate id="no-warrenties.body-4" />
                </div>
                <div className="generic-section">
                    <Translate id="no-warrenties.body-5" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="limitations.title" />
                </div>
                <div className="generic-section">
                    <Translate id="limitations.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="limitations.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="limitations.body-3" />
                </div>
                <div className="generic-section">
                    <Translate id="limitations.body-4" />
                </div>
                <div className="generic-section">
                    <Translate id="limitations.body-5" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="exceptions.title" />
                </div>
                <div className="generic-section">
                    <Translate id="exceptions.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="exceptions.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="exceptions.body-3" />
                </div>
                <div className="generic-section">
                    <Translate id="exceptions.body-4" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="reasonableness.title" />
                </div>
                <div className="generic-section">
                    <Translate id="reasonableness.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="reasonableness.body-2" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="other-parties.title" />
                </div>
                <div className="generic-section">
                    <Translate id="other-parties.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="other-parties.body-2" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="unenforceable-provisions.title" />
                </div>
                <div className="generic-section">
                    <Translate id="unenforceable-provisions.body-1" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="indemnity.title" />
                </div>
                <div className="generic-section">
                    <Translate id="indemnity.body-1" />
                </div>
                
                <div className="generic-sub-heading">
                    <Translate id="breaches.title" />
                </div>
                <div className="generic-section">
                    <Translate id="breaches.body-1" />
                </div>
                
                <div className="generic-sub-heading">
                    <Translate id="variation.title" />
                </div>
                <div className="generic-section">
                    <Translate id="variation.body-1" />
                </div>
                
                <div className="generic-sub-heading">
                    <Translate id="assignment.title" />
                </div>
                <div className="generic-section">
                    <Translate id="assignment.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="assignment.body-2" />
                </div>
                
                <div className="generic-sub-heading">
                    <Translate id="severability.title" />
                </div>
                <div className="generic-section">
                    <Translate id="severability.body-1" />
                </div>
                
                <div className="generic-sub-heading">
                    <Translate id="enitre-agreement.title" />
                </div>
                <div className="generic-section">
                    <Translate id="enitre-agreement.body-1" />
                </div>
                
                <div className="generic-sub-heading">
                    <Translate id="law.title" />
                </div>
                <div className="generic-section">
                    <Translate id="law.body-1" />
                </div>
            </div>
        );
    }
}

export default withLocalize(TermsOfUse);