import { useRef, useEffect, useState } from 'react';
import * as React from 'react';
import FacebookChat from './fb_chat'
import { isBrowser } from 'react-device-detect';
import MailIcon from '@material-ui/icons/Mail';
import IconButton from "@material-ui/core/IconButton";
import './modal.css';
import { BasicTextFields, ControlledOpenSelect } from './formComponents';
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Axios from 'axios';
import {
    GET_LOCATIONS_FOR_WEBSITE,
    LOCAL_STORAGE,
    getLocalStorage,
    ctrlVAPI
} from '../../utilities/GlobalConsts';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';
import CircularProgress from "@material-ui/core/CircularProgress";
import Recaptcha from 'react-google-invisible-recaptcha';
import './customer_contact.css';

const theme = createTheme({
    overrides: {
        Button: {
            primary: {
                color: "white",
            },
        },
    }
});

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const Modal = (props) => {
    const recaptcha = React.useRef();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [submit, setSubmit] = useState(false);
    const [message, setMessage] = useState("");
    const [locationOptions, setLocationOptions] = useState([]);
    const [chosenOption, setChosenOption] = useState(undefined);
    const [successMessage, setSuccessMessage] = useState(false);
    const [failureMessage, setFailureMessage] = useState(false);
    const [sendProgress, setSendProgress] = useState(false);

    const validSubmission = () => {
        return (ValidateEmail(email) && (firstName !== "") && (lastName !== "") && (typeof (message) === 'string') && (message.length < 500) && (message.length > 0))
    }

    const sendQuestionEmail = async () => {
        setSubmit(true);
        if (validSubmission()) {
            let recaptchaToken = await recaptcha.current.execute()
            let apiData = {
                "location_id": chosenOption.location_id,
                "firstName": firstName,
                "lastName": lastName,
                "message": message,
                "email": email,
                "recaptcha": recaptchaToken
            }

            try {
                setSendProgress(true)
                let result = await Axios.post(ctrlVAPI + "/api/customer/sendEmailQuestion", apiData);
                // let result = {data: {success: true}}
                if (result.data.success) {
                    setSuccessMessage(true);
                    setSendProgress(false);
                }
            } catch (err) {
                setSendProgress(false);
                setFailureMessage(true);
                console.error(err);
            }
        }
    }

    const clearModal = () => {
        props.closeModal();
        setSuccessMessage(false);
        setFailureMessage(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
        setSubmit(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            let response = await Axios.get(GET_LOCATIONS_FOR_WEBSITE);
            let storedLocation = getLocalStorage(LOCAL_STORAGE.LOCATION_DETAILS.KEY);
            let res = response.data.res;
            res.sort((a, b) => {
                if (a.display_name < b.display_name) return -1;
                else if (a.display_name > b.display_name) return 1;
                else return 0;
            })
            let data = res.filter((location) => location.company_id !== 3);
            for (var location of data) {
                location.display_name = location.display_name + ", " + location.province_code
            }
            data.push({ location_id: -1, display_name: "Corporate Inquiries" }) //corporate inquiry has a location_id of -2
            data.push({ location_id: -2, display_name: "Franchising Inquiries" })//franchising inquiry has a location_id of -1
            setLocationOptions(data);
            if (storedLocation) {
                for (var loc of response.data.res) {
                    if (loc.location_id === storedLocation.location_id) {
                        setChosenOption(loc);
                    }
                }
            }
        }
        fetchData()
    }, [])

    const divStyle = {
        display: props.displayModal ? 'block' : 'none'
    };

    // function closeModal(e) {
    //     e.stopPropagation()
    //     props.closeModal()
    // }

    function ValidateEmail(mail) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    }

    const messageLength = () => {
        if (typeof (message) !== "string") {
            return (
                <Typography style={{ textAlign: "center", fontSize: "100%" }}>
                    Characters Remaining: 500
                </Typography>)
        } else if (typeof (message) === "string" && message.length <= 500) {
            return (
                <Typography style={{ textAlign: "center", fontSize: "100%" }}>
                    Characters Remaining: {500 - message.length}
                </Typography>)
        } else {
            return (
                <Typography style={{ textAlign: "center", fontSize: "100%", color: "red" }}>
                    Over Character Limit
                </Typography>)
        }
    }

    return (
        <MuiThemeProvider theme={theme}>
            <div
                className="modal"
                style={divStyle} >
                <div
                    className="modal-content center"
                    onClick={e => e.stopPropagation()}>
                    {successMessage && (
                        <React.Fragment>
                            <Grid item xs={12} style={{ marginTop: "12px", textShadow: "none" }}>
                                <Alert onClose={() => { clearModal() }}>Message Successfully Sent</Alert>
                            </Grid>
                        </React.Fragment>)}
                    {!successMessage && (<div className="modal-header">
                        <span
                            className="close"
                            onClick={clearModal}>&times;
                     </span>
                        <center>Have A Question? Email Us!</center>
                    </div>)}
                    <Grid container justifyContent="center" style={{ textShadow: "none" }}>
                        {failureMessage && (<Grid item xs={12}>
                            <Alert severity="error" onClose={() => { setFailureMessage(false) }}>Something Went Wrong. Please Try Again</Alert>
                        </Grid>)}
                        {!successMessage && (
                            <React.Fragment>
                                <Grid item xs={7} style={{ marginTop: "10px" }}>
                                    <center>Email Us For a Quick Response</center>
                                </Grid>
                                <Grid item xs={7}>
                                    <BasicTextFields
                                        name={"first_name"}
                                        id={"first_name"}
                                        extraClass="override-left-padding"
                                        style={{ textAlign: "center"}}
                                        setter={setFirstName}
                                        formLabel={"First Name"}
                                        value={firstName}
                                        submit={submit}
                                        error={(submit, value) => submit && value === ""}
                                        errorMsg={"Invalid Input"}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <BasicTextFields
                                        name={"last_name"}
                                        id={"last_name"}
                                        extraClass="override-left-padding"
                                        style={{ textAlign: "center" }}
                                        setter={setLastName}
                                        formLabel={"Last Name"}
                                        value={lastName}
                                        submit={submit}
                                        error={(submit, value) => submit && value === ""}
                                        errorMsg={"Invalid Input"}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <BasicTextFields
                                        setter={setEmail}
                                        name={"email"}
                                        id={"email"}
                                        extraClass="override-left-padding"
                                        formLabel={"Email Address"}
                                        value={email}
                                        submit={submit}
                                        error={(submit, value) => {
                                            return submit && !ValidateEmail(value);
                                        }}
                                        errorMsg={"Please Enter A Valid Email Address Email"}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <ControlledOpenSelect
                                        formLabel={"Who would you like to contact?"}
                                        setter={(value) => {
                                            for (var location of locationOptions) {
                                                if (location.location_id === value) {
                                                    setChosenOption(location);
                                                }
                                            }
                                        }}
                                        items={locationOptions.map((location) => {
                                            return {
                                                id: location.location_id,
                                                display: location.display_name,
                                            };
                                        })}
                                        value={chosenOption}
                                        submit={submit}
                                        error={submit && !chosenOption}
                                    />
                                </Grid>
                                <Grid item xs={7} style={{ marginTop: "4.8%" }}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        fullWidth
                                        rows={4}
                                        placeholder="Enter Your Message"
                                        variant="outlined"
                                        value={message}
                                        onChange={(event) => {
                                            setMessage(event.target.value)
                                        }}
                                        error={message.length > 500 || (message.length === 0 && submit)}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    {messageLength()}
                                </Grid>
                                <Grid item xs={7} style={{ paddingBottom: "10px" }}>
                                    {sendProgress ? (
                                        <center>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ backgroundColor: "#333333", marginTop: "-15px" }}
                                                onClick={async () => {
                                                    sendQuestionEmail()
                                                }}>
                                                Submit
                                                            </Button>
                                            {"       "}
                                            <CircularProgress style={{ marginTop: "10px" }} />
                                        </center>
                                    ) : (
                                            <center>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ backgroundColor: "#333333", marginTop: "10px" }}
                                                    onClick={async () => {
                                                        sendQuestionEmail()
                                                    }}>
                                                    Submit
                                                </Button>
                                            </center>
                                        )}

                                </Grid>
                            </React.Fragment>
                        )}

                    </Grid>
                </div>
            </div>
            <Recaptcha
                ref={recaptcha}
                sitekey="6LfNk68ZAAAAAMLyhNwudPkFKJf4CZCenmBYlQZ0"
                onResolved={async () => {
                    var recaptchaToken = await recaptcha.current.getResponse();
                    return recaptchaToken;
                }}
                style={{ display: "none" }}
            />
        </MuiThemeProvider>
    );
}

const MobileContact = (props) => {
    let [showMail, setShowMail] = useState(2);
    let [messengerOn, setMessengerOn] = useState(false);

    useInterval(() => {
        setShowMail(showMail + 1)
    }, 3000)

    return (
        <div>
            <div>
                {(props.displayModal || (showMail % 2 === 0 && !messengerOn)) && (
                    <IconButton onClick={() => {
                        props.setDisplayModal(true)
                    }} style={{
                        position: "fixed",
                        right: "24px",
                        bottom: "24px",
                        backgroundColor: "#0098DA",
                        zIndex: "10000000000000000"
                    }}>
                        <MailIcon style={{ color: "#ffffff", width: "38px", height: "38px" }} />
                    </IconButton>)}
            </div>
            <FacebookChat
                onFunction={() => {
                    setMessengerOn(true)
                }}
                offFunction={() => {
                    setMessengerOn(false)
                }}
                mobile={true} />
        </div>
    )
}

const CustomerContact = () => {
    let [displayModal, setDisplayModal] = useState(false);
    let [displayFB, setDisplayFB] = useState(false);

    React.useEffect(() => {
        let storedLocation = getLocalStorage(LOCAL_STORAGE.LOCATION_DETAILS.KEY)
        if (!storedLocation) {
            setDisplayFB(false);
        } else {
            setDisplayFB(true);
        }
    }, [])

    useInterval(() => {
        let storedLocation = getLocalStorage(LOCAL_STORAGE.LOCATION_DETAILS.KEY)
        if (storedLocation) {
            setDisplayFB(true);
        }
    }, 3000)

    if (isBrowser) {
        if (displayFB) {
            return (
                <div>
                    <IconButton onClick={() => { setDisplayModal(true) }} aria-label="Send Mail" style={{ position: "fixed", right: "100px", bottom: "24px", backgroundColor: "#0098DA", zIndex: "1000" }}>
                        <MailIcon style={{ color: "#ffffff", width: "38px", height: "38px" }} />
                    </IconButton>
                    <FacebookChat onFunction={() => { }}
                        offFunction={() => { }} />
                    <Modal
                        displayModal={displayModal}
                        closeModal={() => { setDisplayModal(false) }}
                    />
                </div>
            )
        } else {
            return (
                <div>
                    <IconButton onClick={() => {
                        setDisplayModal(true)
                    }} aria-label="Send Mail" style={{ position: "fixed", right: "20px", bottom: "24px", backgroundColor: "#0098DA", zIndex: "1000" }}>
                        <MailIcon style={{ color: "#ffffff", width: "38px", height: "38px" }} />
                    </IconButton>
                    <Modal
                            displayModal={displayModal}
                            closeModal={() => { setDisplayModal(false) }}
                    />
                </div>
            )
        }

    } else {
        if (displayFB) {
            return (
                <div>
                    <MobileContact displayModal={displayModal} setDisplayModal={setDisplayModal} />
                    <Modal
                        displayModal={displayModal}
                        closeModal={() => { setDisplayModal(false) }}
                    />
                </div>
            )
        } else {
            return (
                <div>
                    <IconButton onClick={() => { setDisplayModal(true) }} aria-label="Send Mail" style={{ position: "fixed", right: "20px", bottom: "24px", backgroundColor: "#0098DA", zIndex: "1001" }}>
                        <MailIcon style={{ color: "#ffffff", width: "38px", height: "38px" }} />
                    </IconButton>
                    <Modal
                        displayModal={displayModal}
                        closeModal={() => { setDisplayModal(false) }}
                    />
                </div>
            )
        }
    }
}

export default CustomerContact;
