import React, { Component } from 'react';
import './LocationDropdown.css';

class LocationDropdown extends Component { 

    state = {
        showDropdown: false,
        toggled: false
    }

    toggleDropDown = () => {
        this.setState({
            showDropdown: !this.state.showDropdown
        });
    }

    // this is a little hacky. Basically if you clicked games, open the dropdown but only once.
    checkParams = () => {
        if(window.location.hash === "#games" && !this.toggled) {
            this.toggled = true;

            this.setState({
                showDropdown: true
            });
        } else if(window.location.hash !== "#games" && this.toggled) {
            this.toggled = false;
        }
    }

    componentWillMount = () => {
        clearInterval(this.timeoutObj);
    }

    componentDidMount = () => {
        this.timeoutObj = setInterval(this.checkParams, 100);
    }

    render() {
        return (
            <div className="location-dropdown-wrapper">
                <div 
                    className={this.state.showDropdown ? "location-dropdown-heading-open location-dropdown-heading" : "location-dropdown-heading" } 
                    onClick={this.toggleDropDown}>
                    {this.props.title}
                </div>

                <div className={this.state.showDropdown ? "location-dropdown-body location-show-dropdown" : "location-dropdown-body location-hide-dropdown" }>
                    {this.props.body}
                </div>

            </div>
        );
    }
}

export default LocationDropdown;