import React, { Component } from 'react';

class Icon extends Component {

    render() {
        return(
            <div className="social-icon">
                <a href={this.props.link} target="_blank" rel="noopener noreferrer">
                    <img src={this.props.image} width="20px" height="20px" aria-label={this.props.label} alt="Icon"></img>
                </a>
            </div>
        );
    }
}

export default Icon;