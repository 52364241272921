import React, { Component } from 'react';
import Axios from 'axios';
import GoogleMapReact from 'google-map-react';

import './LocationMap.css';
import LocationPin from './map_pins/LocationPin';
import {Helmet} from 'react-helmet';

import {
    CTRL_V_MAP_PIN,
    GET_LOCATIONS_FOR_WEBSITE,
    GOOGLE_MAP_API_KEY,
    getBaseName
} from '../../../utilities/GlobalConsts';

class LocationMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            locations: null
        }
    }

    getLocations = () => {
        Axios.get(GET_LOCATIONS_FOR_WEBSITE)
            .then((response) => {
                this.setState({
                    locations: response['data']['res']
                });
            });
    }

    componentDidMount = () => {
        this.getLocations();
    }

    render() {
        let pins = [];

        // eslint-disable-next-line
        for(let key in this.state.locations) {
            pins.push(
                <LocationPin image={CTRL_V_MAP_PIN}
                    lat={this.state.locations[key].coordinates.x}
                    lng={this.state.locations[key].coordinates.y}
                    isSatalite={!this.state.locations[key].uses_crowd_ctrl}
                    locationName={this.state.locations[key].web_name}
                    key={key}
                    link={"/location/" + this.state.locations[key].web_name} />
            );
        }

        return (
            <React.Fragment>
                
                <Helmet>
                    <title>Ctrl V Virtual Reality Arcade - Location Map</title>
                    <meta name="description" content="Ctrl V Virtual Reality Arcade - An interactive map showing all Ctrl V locations with corresponding address and facility photo."/>
                    <link rel="canonical" href={`${getBaseName()}/location-map/`} />
                    <meta property="og:title" content="Ctrl V Virtual Reality Arcade - Location Map" />
                    <meta property="og:description" content="Ctrl V Virtual Reality Arcade - An interactive map showing all Ctrl V locations with corresponding address and facility photo." />
                    <meta property="og:url" content={`${getBaseName()}/location-map/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Ctrl V Virtual Reality Arcade - An interactive map showing all Ctrl V locations with corresponding address and facility photo." />
                    <meta name="twitter:title" content="Ctrl V Virtual Reality Arcade - Location Map" />
                    <meta name="keywords" content="location,map,directions" />
                </Helmet>

                <div className="location-map-wrapper">
                    <div className="map-wrapper">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
                            defaultCenter={{lat:41.850033, lng:-87.6500523}}
                            defaultZoom={3}
                            yesIWantToUseGoogleMapApiInternals 
                            options={{
                                mapTypeControl: true,
                                gestureHandling: 'greedy',
                                minZoom: 2,
                                size: { 
                                    width: '100%', 
                                    height: '100%'
                                }
                            }}>
                                {pins}
                        </GoogleMapReact>
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

export default LocationMap;

