import React from 'react';
import { withLocalize } from 'react-localize-redux';

import './LanguageToggle.css';

// stateless react function for language toggle in footer.
// TODO: maybe set local storage for default language on a user when they swap it
const LanguageToggle = ({languages, activeLanguage, setActiveLanguage}) => {

  return (
    <div className="footer-links">
      
      {languages.map(lang => 
        <span className="language" key={lang.code} onClick={() => setActiveLanguage(lang.code)}>{ lang.name }</span>
      )}
    </div>
  );
};

export default withLocalize(LanguageToggle);