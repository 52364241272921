import React, { Component } from 'react';
import { isBrowser } from "react-device-detect";
import { WEBCACHE_API as Api, tryAddImageExtension, getBaseName} from '../../../utilities/GlobalConsts';

import { withLocalize, Translate } from 'react-localize-redux';
import GamesTranslation from '../../../translations/games.json';

import Video from '../../video/Video';
import Axios from 'axios';

import './Games.css';
import LightboxImage from '../../lightbox_image/LightboxImage';
import {Helmet} from 'react-helmet';

class Games extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(GamesTranslation);
    }

    state = {
        game: undefined,
        lightboxImages: [],
        selectedImage: undefined,
        showLightbox: false
    }

    // get game data from endpoint
    getGame = async () => {

        // get url
        const curPath = window.location.pathname;

        // get url segment after 'games/' piece
        let pathname = curPath.substring(curPath.lastIndexOf('games/') + 6);

        try {
            // get Games
            let game = await Axios.get(Api.GET_GAME_BY_URL + pathname);

            // if it succeeded, set state. otherwise show 404
            if(game.data.success) {
                this.setState({
                    game: game.data.game
                });
            } else {
                window.location.replace('/404');
            }

        } catch(err) {
            console.error(err);
        }

    }

    componentDidMount = () => {
        // get game data for dom
        this.getGame();
    }

    getDeveloperSection = () => {
        const developerId = this.state.game.developer_is_publisher ? "the-developer-and-publisher" : "the-developer";

        return (
            <div className={isBrowser ? "games-section" : "mobile-game-section"}>
                <div className="game-body">
                    <div className={isBrowser ? "game-logo" : null}>
                        <img src={tryAddImageExtension(this.state.game.developer_logo)} alt="Developer Logo" className="game-logo-img"  />
                    </div>
                    <div className={isBrowser ? "game-description" : null}>
                        <div className={isBrowser ? "game-title center-game-title" : "game-title"}>
                            <Translate id={developerId} /> - {this.state.game.developer_name}
                        </div>
                        <div className={isBrowser ? "game-description-padding" : "mobile-game-description"} dangerouslySetInnerHTML={{__html: this.state.game.developer_description}} />
                    </div>
                </div>
            </div>
        )
    }

    getPublisherSection = () => {
        if(this.state.game.developer_is_publisher) return null;

        return (
            <div className={isBrowser ? "games-section" : "mobile-game-section"}>
                <div className="game-body">
                    <div className={isBrowser ? "game-logo" : null}>
                        <img src={tryAddImageExtension(this.state.game.publisher_logo)} alt="Publisher Logo" className="game-logo-img"  />
                    </div>
                    <div className={isBrowser ? "game-description" : null}>
                        <div className={isBrowser ? "game-title center-game-title" : "game-title"}>
                            <Translate id="the-publisher" /> - {this.state.game.publisher_name}
                        </div>
                        <div className={isBrowser ? "game-description-padding" : "mobile-game-description"} dangerouslySetInnerHTML={{__html: this.state.game.publisher_description}} />
                    </div>
                </div>
            </div>
        )
    }

    selectImage = (index) => {
        this.setState({
            selectedImage: index,
            showLightbox: true
        });
    }

    getGameGalleryImages = () => {

        let gallery = [];
        // eslint-disable-next-line
        for(let key in this.state.game.game_gallery) {
            gallery.push(
                <img key={key} src={this.state.game.game_gallery[key]} alt="Gallery" className="game-logo-img" onClick={() => this.selectImage(key)} />
            )
        }

        return gallery;
    }

    render() {
        if(this.state.game === undefined) return null;

        return (
            <div className={isBrowser ? "games-wrapper": "mobile-games-wrapper"}>
                <Helmet>
                    <title>{this.state.game.game_title} | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content={`Game description, video and images for ${this.state.game.game_title} | Ctrl V - Virtual Reality Arcade`}/>
                    <link rel="canonical" href={`${getBaseName()}/games/${this.state.game.url_name}`} />
                    <meta property="og:title" content={`${this.state.game.game_title} | Ctrl V - Virtual Reality Arcade`} />
                    <meta property="og:description" content={`Game description, video and images for ${this.state.game.game_title} | Ctrl V - Virtual Reality Arcade`} />
                    <meta property="og:url" content={`${getBaseName()}/games/${this.state.game.url_name}`}  />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content={`Game description, video and images for ${this.state.game.game_title} | Ctrl V - Virtual Reality Arcade`} />
                    <meta name="twitter:title" content={`${this.state.game.game_title} | Ctrl V - Virtual Reality Arcade`}/>
                    <meta name="keywords" content={`game,library,menu,${this.state.game.game_title}`} />
                </Helmet>

                <div className={isBrowser ? "games-section" : "mobile-game-section"}>
                    <div className={isBrowser ? "game-title left-game-title" : "game-title"}>
                        <Translate id="the-game" /> - {this.state.game.game_title}
                    </div>
                    <div className="game-body">
                        <div className={isBrowser ? "game-logo" : null}>
                            <img src={tryAddImageExtension( this.state.game.game_logo ) } alt="Game Logo" className="game-logo-img" />
                        </div>
                        <div className={isBrowser ? "game-description" : null}>
                            <div className={isBrowser ? "game-description-padding" : "mobile-game-description"} dangerouslySetInnerHTML={{__html: this.state.game.game_description}} />
                        </div>
                    </div>
                </div>

                <div className={isBrowser ? "games-section" : null}>
                    <div className={isBrowser ? "game-title left-game-title" : "game-title"}>
                        <Translate id="the-trailer" /> - {this.state.game.game_title}
                    </div>
                    <div className="game-body">
                        <Video image={tryAddImageExtension( this.state.game.game_logo )} video={this.state.game.game_video} />
                    </div>
                </div>

                <div className="games-section">
                    <div className="game-title center-game-title">
                        <Translate id="the-screenshots" /> - {this.state.game.game_title}
                    </div>
                    <div className={isBrowser ? "game-body game-body-gallery" : "game-body mobile-gallery"}>
                        {this.getGameGalleryImages()}
                    </div>
                </div>

                <LightboxImage images={this.state.game.game_gallery}
                selectedImage={this.state.selectedImage}
                outerClick={()=>{this.setState({showLightbox:"false"})}}
                display={`${this.state.showLightbox}`} />

                {this.getDeveloperSection()}

                {this.getPublisherSection()}
            </div>
        );
    }
}

export default withLocalize(Games);
