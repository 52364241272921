import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';
import { withRouter } from 'react-router-dom';

import { withLocalize, Translate } from 'react-localize-redux';
import CareersTranslation from '../../../translations/careers.json';

import { getBaseName } from '../../../utilities/GlobalConsts';

import '../GenericPage.css';
import '../careers/Careers.css';

import {Helmet} from 'react-helmet';
import DynamicImg from '../../dynamic_image/DynamicImg';

const TreVRResume = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/TreVR%20Resume";

class Careers extends Component {

    constructor(props) {
        super(props);
        this.props.addTranslation(CareersTranslation);
    }

    render() {
        return(
            <div className={isBrowser ? "generic-wrapper browser-wrapper" : "generic-wrapper"}>
                <Helmet>
                    <title>Careers | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Apply for a position at Ctrl V now and change your reality!"/>
                    <link rel="canonical" href={`${getBaseName()}/careers/`} />
                    <meta property="og:title" content="Careers | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Apply for a position at Ctrl V now and change your reality!" />
                    <meta property="og:url" content={`${getBaseName()}/careers/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Apply for a position at Ctrl V now and change your reality!" />
                    <meta name="twitter:title" content="Careers | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="career,careers,job,jobs,mobile,virtual,reality,vr" />
                </Helmet>

                <DynamicImg
                    imageName={TreVRResume}
                    fallbackExt='jpg'
                    alt="TreVr holding a resume"
                    width="50%"
                />

                <div>
                    <div className="generic-text bottom-padding">
                        <div className="align-center">
                            <Translate id="section-1.body.start" />
                        </div>
                    </div>

                    <div className="generic-text bottom-padding">
                        <div className="align-center">
                            <Translate id="section-1.body.middle-1" />
                            <Translate id="section-1.body.middle-2" />
                        </div>
                    </div>

                    <div className="generic-text bottom-padding">
                        <div className="align-center">
                            <Translate id="section-1.body.middle-3" />
                            <Translate id="section-1.body.end" />
                        </div>
                    </div>
                </div>

                <div>
                    <div className="generic-text">
                        <div className="align-center">
                            <Translate id="section-2.body.start" />
                        </div>
                    </div>
                    <div className ="heading-2">
                        <div className="align-center">
                            <span className="blue">
                                <Translate id="section-2.body.blue" />
                            </span>
                        </div>
                    </div>

                    <br/>
                    <hr/>
                    <br/>

                    <div className="generic-text bottom-padding">
                        <div className="align-center">
                            <Translate id="section-3.body.start" />
                        </div>
                    </div>
                </div>

                <div className="heading">
                    CANADA
                </div>

                <div className="province-heading top-padding">
                    ALBERTA
                </div>

                <div>
                    <div className="location-heading">
                        <div>
                            Edmonton (West)
                            <div className="location-text">
                                <a href="mailto: edmonton-west@ctrlv.ca">
                                    edmonton-west@ctrlv.ca
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="top-padding">
                    <div className="location-heading">
                        <div>
                            Lethbridge
                            <div className="location-text">
                                <a href="mailto: lethbridge@ctrlv.ca">
                                    lethbridge@ctrlv.ca
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="top-padding">
                    <div className="location-heading">
                        <div>
                            Red Deer
                            <div className="location-text">
                                <a href="mailto: reddeer@ctrlv.ca">
                                    reddeer@ctrlv.ca
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="province-heading top-padding-2">
                    ONTARIO
                </div>

                <div>
                    <div className="location-heading">
                        <div>
                            Guelph
                            <div className="location-text">
                                <a href="mailto: guelph@ctrlv.ca">
                                    guelph@ctrlv.ca
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="top-padding">
                    <div className="location-heading">
                        <div>
                            Hamilton
                            <div className="location-text">
                                <a href="mailto: hamilton@ctrlv.ca">
                                    hamilton@ctrlv.ca
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="top-padding">
                    <div className="location-heading">
                        <div>
                            Waterloo
                            <div className="location-text">
                                <a href="mailto: waterloo@ctrlv.ca">
                                    waterloo@ctrlv.ca
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <br/>
                <br/>

                <div className="heading top-padding">
                    UNITED STATES
                </div>

                <div className="province-heading top-padding-2">
                    TEXAS
                </div>

                <div>
                    <div className="location-heading">
                        <div>
                            Plano
                            <div className="location-text">
                                <a href="mailto: plano@ctrlvarcade.com">
                                    plano@ctrlvarcade.com
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withLocalize(withRouter(Careers));
