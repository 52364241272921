import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';

import './CoveragePreview.css';

class CoveragePreview extends Component {

    state = {
        clicked: false
    }

    clickHandler = () => {
        this.setState({clicked: true});
    }

    render() {
        if(this.state.clicked) {
            return(<Redirect push to={"/pressroom/" + this.props.post_type + "_" + this.props.index} />)
        }

        return (
            <div className={ isBrowser ? "coverage-browser-wrapper" : null }>
                <div className="coverage-wrapper blue-sub-headings coverage-preview-wrapper" onClick={this.clickHandler}>
                    
                    <img src={this.props.image} alt="Coverage" className="generic-image"/>

                    <div className="generic-sub-heading">
                        {this.props.title}
                    </div>
                </div>
            </div>
        );
    }
}

export default CoveragePreview;