import React, { Component } from 'react';
import Axios from 'axios';
import { isBrowser } from 'react-device-detect'

import { withLocalize, Translate } from 'react-localize-redux';
import EventTranslations from '../../../translations/events.json';

import {
    WEBCACHE_API,
    getExtension,
    getBaseName
} from '../../../utilities/GlobalConsts';

import './Packages.css';
import '../GenericPage.css';
import {Helmet} from 'react-helmet';

const xavrImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/xavr.png';
const EVENT_REPLACEMENT_STRINGS = ['package_price', 'num_stations'];

const topSectionParallax = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/v2_2018_09_3f125806-arcade-fade-wide";
const dodgeball = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/aab9f1a5-arcade-fade-dodgeball"
const arcadeFadeImg = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/8b9da068-arcade-fade-mobile";
const mobileFlyer = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Red%20Deer%20Mobile%20Station%20Rental%20Flyer.png"
const school = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Arcade%20Fade%20School'

class EventsRedDeer extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(EventTranslations);

        this.state = {
            location: "red-deer",
            groupPrice: undefined,
            groupMinStations: undefined,
            priceDisabled: false
        }
    }

    eventStringReplacement = (eventData, eventKey) => {
        let result = eventData[eventKey];

        // eslint-disable-next-line
        for(const key in EVENT_REPLACEMENT_STRINGS) {
            result = result.replace(`[${EVENT_REPLACEMENT_STRINGS[key]}]`, eventData[EVENT_REPLACEMENT_STRINGS[key]]);
        }

        return result;
    }

    getLocationData = async () => {

        try {
            let response = await Axios.get(WEBCACHE_API.GET_LOCATION_EVENTDATA + this.state.location);

            if(response.data.length === 0) {
                window.location.href = '/';
            }

            let eventData = response.data.eventData.eventData;
            eventData.group_description = this.eventStringReplacement(eventData, 'group_description');
            eventData.group_footnote = this.eventStringReplacement(eventData, 'group_footnote');
            eventData.mobile_event_description = this.eventStringReplacement(eventData, 'mobile_event_description');
            this.setState({
                package_price: eventData.package_price,
                priceDisabled: eventData.package_price === "-1.00",
                group_description: eventData.group_description,
                group_description_id: eventData.group_description_id,
                group_footnote: eventData.group_footnote,
                group_footnote_id: eventData.group_footnote_id,
                mobile_event_description: eventData.mobile_event_description,
                mobile_event_description_id: eventData.mobile_event_description_id,
                locationId: eventData.location_id,
                email: eventData.location_email
            });

        } catch(err) {
            console.error(err);
        }

    }

    componentDidMount = () => {
        this.getLocationData();
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Events | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Mobile and Group events. You come to us, or we bring the VR to you."/>
                    <link rel="canonical" href={`${getBaseName()}/events/red-deer/`} />
                    <meta property="og:title" content="Events | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Mobile and Group events. You come to us, or we bring the VR to you." />
                    <meta property="og:url" content={`${getBaseName()}/events/red-deer/`}/>
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Mobile and Group events. You come to us, or we bring the VR to you." />
                    <meta name="twitter:title" content="Events | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content={`event,company,staff,work,office,mobile,group,team,red-deer`}/>
                </Helmet>

                {this.state.group_description_id !== "-2" && this.state.group_footnote_id !== "-2"?
                    (<div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${dodgeball}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                        <span className="anchor" id="team"></span>

                        <div className="event-section">
                            <div className="vertical-align-helper">
                                <div className={ isBrowser ? "event-right" : "mobile-event" }>
                                    <div className="generic-heading">
                                        <Translate id="team-building.title" />
                                    </div>
                                    <div className="generic-sub-heading">
                                        <Translate id="team-building.sub-title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="team-building.paragraph" />
                                    </div>
                                    {!this.state.priceDisabled?
                                    (<div className="generic-sub-heading align-center">
                                        <Translate id="team-building.starting-at" />
                                    </div>):null
                                    }
                                    {!this.state.priceDisabled?
                                    (<div className="generic-heading align-center">
                                        $<Translate id="team-building.price-6" />*
                                    </div>):null
                                    }
                                    <div className="generic-footnote">
                                        <Translate id="team-building.footnote" />
                                    </div>
                                    <div className="align-center">
                                        <a href={`${getBaseName()}/booking/location/${this.state.locationId}`}>
                                            <button className="generic-location-button generic-pulse-animation">
                                                <Translate id="book-now-at" /> { this.state.location !== undefined ? this.state.location.toUpperCase() : null }
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>):null
                }
                {this.state.group_description_id !== "-2" && this.state.group_footnote_id !== "-2"?
                    (<div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${topSectionParallax}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                        <span className="anchor" id="private"></span>

                        <div className="event-section">
                            <div className="vertical-align-helper">
                                <div className={ isBrowser ? "event-left" : "mobile-event" }>
                                    <div className="generic-heading">
                                        <Translate id="private-events.title" />
                                    </div>
                                    <div className="generic-sub-heading">
                                        <Translate id="private-events.sub-title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="private-events.paragraph" />
                                    </div>
                                    {!this.state.priceDisabled?
                                    (<div className="generic-sub-heading align-center">
                                        <Translate id="private-events.only" />
                                    </div>):null
                                    }
                                    {!this.state.priceDisabled?
                                    (<div className="generic-heading align-center">
                                        $<Translate id="private-events.price-9" />*
                                    </div>):null
                                    }
                                    <div className="generic-footnote">
                                        <Translate id="private-events.footnote" />
                                    </div>
                                    <div className="align-center">
                                        <a href={`${getBaseName()}/booking/location/${this.state.locationId}`}>
                                            <button className="generic-location-button generic-pulse-animation">
                                                <Translate id="book-now-at" /> { this.state.location !== undefined ? this.state.location.toUpperCase() : null }
                                            </button>
                                        </a>
                                    </div>
                                    <div className="generic-sub-heading">
                                        <div className="align-center">
                                            <a className="blue-text underline-hover" href={`mailto: ${this.state.email}`}>{this.state.email}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>):null
                }
                {this.state.mobile_event_description_id !== '-2'?
                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${arcadeFadeImg}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>

                    <span className="anchor" id="mobile"></span>

                    <div className="event-section">
                        <div className="vertical-align-helper">
                            <div className={ isBrowser ? "event-right" : "mobile-event" }>
                                <div className="generic-heading">
                                    <Translate id="mobile-events.title" />
                                </div>
                                <div className="generic-sub-heading">
                                    <Translate id="mobile-events.sub-title" />
                                </div>
                                <div className="generic-section">
                                    {this.state.mobile_event_description}
                                </div>
                                    {!this.state.priceDisabled?
                                    (<div className="generic-sub-heading align-center">
                                        <Translate id="mobile-events.starting-at" />
                                    </div>):null
                                    }
                                    {!this.state.priceDisabled?
                                    (<div className="generic-heading align-center">
                                        $<Translate id="mobile-events.price" />*
                                    </div>):null
                                    }
                                <div className="generic-footnote">
                                    <Translate id="mobile-events.footnote" />
                                </div>
                                <div className="align-center">
                                    <a href={mobileFlyer}>
                                        <button className="generic-location-button generic-pulse-animation">
                                            <Translate id="mobile-events.more-info" />
                                        </button>
                                    </a>
                                </div>
                                <div className="generic-sub-heading">
                                    <div className="align-center">
                                        <a className="blue-text underline-hover" href={`mailto: ${this.state.email}`}>{this.state.email}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :null
                }
                {this.state.group_description_id !== "-2" && this.state.group_footnote_id !== "-2"?
                    (<div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${school}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                        <span className="anchor" id="school"></span>

                        <div className="event-section">
                            <div className="vertical-align-helper">
                                <div className={ isBrowser ? "event-left" : "mobile-event" }>
                                    <div className="generic-heading">
                                        <Translate id="school-events.title" />
                                    </div>
                                    <div className="generic-sub-heading">
                                        <Translate id="school-events.sub-title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="school-events.paragraph" />
                                    </div>
                                    <div className="generic-sub-heading">
                                        <div className="align-center">
                                            <a className="blue-text underline-hover" href={`mailto: ${this.state.email}`}>{this.state.email}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>):null
                }
            </div>
        )
    }
}

export default withLocalize(EventsRedDeer);
