import React, { useState } from 'react';
import './bottomAlert.css';
import Popup from './Popup';
import Axios from 'axios';
import {
    WEBCACHE_API,
} from '../../../../utilities/GlobalConsts';



export default function BottomComponent(props) {
    const [openModal, setOpenModal] = useState(true);
    const [data, setData] = useState(null);

    React.useEffect(() => {
        const fetchData = async () => {
            let data = await Axios.get(WEBCACHE_API.GET_LOCATION_BOTTOM_ALERTS);
            let result = data.data[0].jsonb_agg;
            for (let alertData of result) {
                if (alertData.locationsActive.includes(props.locationID)) {
                    setData(alertData);
                }

                if (props.homePage && !alertData.showHome) {
                    setData(null);
                }
            }
        }
        fetchData()
    }, [props.locationID, props.homePage])


    if (data) {
        return (
            <React.Fragment>
                {data.chooseLink && (
                    <a href={data.navigateLink} style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                        <div className="spanning warning-gradient stick-to-bottom"
                            style={{ color: data.textColor, background: `linear-gradient(${data.startingBackgroundColor}, ${data.finishingBackgroundColor}`, fontSize: data.fontSize }}
                        >
                            {data.alertText}
                        </div>
                    </a>
                )}
                {!data.chooseLink && (
                    <React.Fragment>
                        <div className="spanning warning-gradient stick-to-bottom"
                            style={{ color: data.textColor, background: `linear-gradient(${data.startingBackgroundColor}, ${data.finishingBackgroundColor}`, fontSize: data.fontSize }}
                            onClick={() => { setOpenModal(true) }}
                        >
                            {data.alertText}
                        </div>
                        {openModal && (
                            <Popup closePopup={() => { setOpenModal(false) }}>
                                <div dangerouslySetInnerHTML={{ __html: data.modalText }}>
                                </div>
                                {data.modalButton && (
                                    <a href={data.modalButtonLink} target="_blank" rel="noopener noreferrer">
                                        <button className="ctrlv-blue-button">{data.modalButtonText}</button>
                                    </a>
                                )}
                            </Popup>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    } else {
        return (
            <div></div>
        )
    }

}
