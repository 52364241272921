import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';
import ParallaxJs from '../../parallax_js/ParallaxJs';
import {
    getLocalStorage,
    localStorageObjectExpired,
    getBaseName
} from '../../../utilities/GlobalConsts';

import '../GenericPage.css';
import './Extralife.css';
import DynamicImg from '../../dynamic_image/DynamicImg';

const MASK_LOCATIONS = [1, 3, 4, 9, 19, 29, 31];

const extraGirlImg = 'https://storage.googleapis.com/ctrlv-www/v2/2020/09/extra_life_2020_girl_back';
// const extraBoyImg = 'https://storage.googleapis.com/ctrlv-www/v2/2020/09/extra_life_2020_boy_back';

// const extraLifeLogo = 'https://storage.googleapis.com/ctrlv-www/v2/2020/09/extra_life_2020_el.png';
// const cmnLogo = 'https://storage.googleapis.com/ctrlv-www/v2/2020/09/extra_life_2020_cmn.png';


const extraLifeBoy = 'https://storage.googleapis.com/ctrlv-www/v2/2020/09/extra_life_2020_boy_t.png';
const robit = 'https://storage.googleapis.com/ctrlv-www/v2/2020/09/extra_life_2020_girl_t.png';
const background = 'https://storage.googleapis.com/ctrlv-www/v2/2020/09/extra_life_2020_kids_background.png';

const trevrMask = 'https://storage.googleapis.com/ctrlv-www/v2/2020/09/extra_life_2020_trevr_mask.png';
const spiderManImage = 'https://storage.googleapis.com/ctrlv-www/v2/2020/09/extra_life_2020_spiderman.png';

const Total2022Image = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Extra%20Life%20Total%20Raised%20Banner';

// if a future dev is re-adding sponsors, look at gitlab pre (2020-09-22). deleted to save memory
class Extralife extends Component {

    state = {
        parallaxObjects: [
            {
                image: background,
                class: "parallax-js-image",
                dataDepth: "0",
                loadDelay: -1
            },
            {
                image: robit,
                class: "parallax-js-image",
                dataDepth: "0.1",
                loadDelay: 750
            },
            {
                image: extraLifeBoy,
                class: "parallax-js-image",
                dataDepth: "0.2",
                loadDelay: 1000
            }
        ],

        spiderManImageClicked: false
    }

    tryRedirect = () => {
        window.open(`${getBaseName()}/booking/location`, '_blank');
    }

    donateNowLink = () => {
        window.open('https://www.extra-life.org/index.cfm?fuseaction=donorDrive.team&teamID=66905', '_blank');
    }

    toggleSpiderManVideo = () => {
        let oldState = this.state;
        oldState.spiderManImageClicked = true;
        this.setState(oldState);
    }

    renderSpiderManInteract = () => {
        if(this.state.spiderManImageClicked) {
            return (
                <video controls width="100%" autoplay="1">
                    <source src="https://cdn.cloudflare.steamstatic.com/steam/apps/256752895/movie_max.webm" type="video/webm"></source>
                </video>
            );
        } else {
            return (
                <img src={spiderManImage} alt="Spider-man" onClick={this.toggleSpiderManVideo} className="generic-image"></img>
            );
        }
    }

    shouldRenderMasks = () => {
        let location = getLocalStorage('location');

        if(location && !localStorageObjectExpired(location)) {
            return (MASK_LOCATIONS.includes(location.location_id))
        }

        return false;
    }

    render() {
        return(
            <React.Fragment>
            {/* <div className="parallax-wrapper" style={{backgroundImage: `url(${extraBoyImg}.png)`}}>
                <div className={isBrowser ? "browser-column " : ""}>
                    <div className="align-center extra-life-section-wrapper extra-life-furore-font">

                        <div className="generic-heading" >
                            FROM <span className="blue-text">OCT. 1</span> TO <span className="blue-text">NOV. 7</span>
                        </div>
                        <DynamicImg
                            imageName="https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/7a06ab15-website-logo"
                            fallbackExt='png'
                            imgClass=""
                            alt="Glowing Station"
                        />
                        <div className="generic-heading extra-life-section-text-padding">
                            WILL DONATE UP TO
                        </div>
                        <div className="generic-heading extra-life-mega-font">
                            <span className="blue-text">
                                <sup className="small-super-script">$</sup>
                                5.
                                <sup className="small-super-script">00</sup>
                            </span>
                            <sup className="extra-small-super-script">*</sup>
                        </div>
                        <div className="generic-heading extra-life-section-text-padding">
                            <div>
                                FROM EVERY
                            </div>
                            <div>
                                VR STATION BOOKED TO
                            </div>
                        </div>
                        <div className={isBrowser ? "browser-column" : ""}>
                            <div className={isBrowser ? "browser-column" : ""}>
                                <img src={extraLifeLogo} className="extra-life-image-no-overflow"></img>
                            </div>
                            <div className={isBrowser ? "browser-column" : ""}>
                                <img src={cmnLogo} className="extra-life-image-no-overflow"></img>
                            </div>
                        </div>

                        <div className="extra-life-subscript">
                            <sup className="asterisk-super-script">*</sup>Minus applied discounts
                        </div>
                    </div>
                </div>
                <div className={isBrowser ? "browser-column" : ""}>
                </div>
            </div>

            <div className="parallax-wrapper parallax-divider">
                <div className="generic-wrapper browser-wrapper">
                    <button className="heading-book-now generic-location-button" onClick={this.tryRedirect}>Book Now</button>
                    <button className="heading-book-now generic-location-button" onClick={this.donateNowLink}>Donate Now</button>
                </div>
            </div> */}

            <div className="generic-wrapper extra-life-furore-font">
                <DynamicImg
                    imageName={Total2022Image}
                    fallbackExt='png'
                    imgClass="extra-life-image"
                    alt="Total Raised"
                    width="100%"
                    height="auto"
                />
            </div>

            <div className="generic-wrapper extra-life-furore-font">
                <div className={isBrowser ? "browser-column" : ""}>
                    <div className={"extralife-parallax"}>
                        <ParallaxJs objects={this.state.parallaxObjects} limitX={100} limitY={5} notWebp/>
                    </div>
                </div>
                <div className={isBrowser ? "browser-column " : ""}>
                    <div className={isBrowser ? "extra-life-section-left-right-padding" : ""}>
                        <div className="align-center extra-life-section-wrapper">
                            <div className="generic-heading extra-life-section-text-padding">
                                THE CHILDREN'S MIRACLE NETWORK OF HOSPITALS NEEDS OUR SUPPORT
                                <div className="blue-text">NOW MORE THAN EVER.</div>
                            </div>
                            <div className="generic-heading extra-life-section-text-padding">
                                AND WE'RE POSITIVE
                                <div className="blue-text">YOU NEED A BIT OF FUN!</div>
                            </div>
                            <div className="generic-heading extra-life-section-text-padding">
                                WE CAN HELP WITH BOTH!
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="parallax-wrapper parallax-divider">
                <div className="generic-wrapper browser-wrapper">
                    <button className="heading-book-now generic-location-button" onClick={this.tryRedirect}>Book Now</button>
                    <button className="heading-book-now generic-location-button" onClick={this.donateNowLink}>Donate Now</button>
                </div>
            </div>

            <div className="parallax-wrapper extra-life-furore-font" style={{backgroundImage: `url(${extraGirlImg}.png)`}}>
                <div className={isBrowser ? "browser-column " : ""}>
                    <div className="align-center extra-life-section-wrapper">
                        <DynamicImg
                            imageName="https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/7a06ab15-website-logo"
                            fallbackExt='png'
                            imgClass=""
                            alt="Glowing Station"
                            width="100%"
                            height="auto"
                        />
                        <div className="generic-heading">
                            HAS RAISED OVER
                        </div>
                        <div className="generic-heading extra-life-ultra-font">
                            <span className="blue-text add-stroke glow"><sup className="small-super-script">$</sup>130,000</span>
                        </div>
                        <div className="generic-heading extra-life-section-text-padding">
                            <div>
                                FROM THIS EVENT
                            </div>
                            <div>
                                OVER THE PAST 6 YEARS
                            </div>
                        </div>
                        <div className="generic-heading extra-life-section-text-padding">
                            <div>
                                ...BUT JUST LIKE REALITY
                            </div>
                            <div>
                                IT ISN'T ENOUGH
                            </div>

                        </div>
                        {/* <button className="heading-book-now generic-location-button" onClick={this.tryRedirect}>Book Now</button>
                        <button className="heading-book-now generic-location-button" onClick={this.donateNowLink}>Donate Now</button> */}
                    </div>
                </div>
                <div className={isBrowser ? "browser-column" : ""}>
                </div>
            </div>

            <div className="parallax-wrapper parallax-divider">
                <div className="generic-wrapper browser-wrapper">
                    <button className="heading-book-now generic-location-button" onClick={this.tryRedirect}>Book Now</button>
                    <button className="heading-book-now generic-location-button" onClick={this.donateNowLink}>Donate Now</button>
                </div>
            </div>

            {/* <div className="parallax-wrapper">
                <div className={isBrowser ? "browser-column" : ""}>
                    <div className="spider-man-container add-spider-man-pulse-animation">
                        {this.renderSpiderManInteract()}
                    </div>
                </div>
                <div className={isBrowser ? "browser-column " : ""}>
                    <div className={isBrowser ? "extra-life-section-left-right-padding" : ""}>
                        <div className="align-center extra-life-section-wrapper extra-life-spider-man-font">

                            <div className="generic-heading extra-life-title spider-man-letter-spacing">
                                BE THE HERO
                            </div>
                            <div className="generic-heading extra-life-section-text-padding spider-man-red">
                                PLAY SPIDER-MAN VR
                            </div>
                            <div className="generic-heading extra-life-larger-font">
                                <span className="spider-man-free-gradiant">
                                    FREE
                                </span>
                            </div>
                            <div className="generic-heading extra-life-section-text-padding ">
                                WHEN YOU DONATE TO
                            </div>
                            <div className="generic-heading extra-life-section-text-padding">
                                <a href="https://extra-life.org/ctrlv" target="_blank">
                                    <span className=" spider-man-red">
                                        EXTRA-LIFE.ORG/CTRLV
                                    </span>
                                </a>
                            </div>
                            <div className="generic-heading extra-life-section-text-padding">
                                1 DOLLAR = 1 MINUTE
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="parallax-wrapper parallax-divider">
                <div className="generic-wrapper browser-wrapper">
                    <button className="heading-book-now generic-location-button" onClick={this.tryRedirect}>Book Now</button>
                    <button className="heading-book-now generic-location-button" onClick={this.donateNowLink}>Donate Now</button>
                </div>
            </div> */}

            {this.shouldRenderMasks() ?
                <div className="parallax-wrapper extra-life-furore-font">
                    <div className={isBrowser ? "browser-column " : ""}>
                        <div className="align-center extra-life-section-wrapper">
                            <div className="generic-heading extra-life-section-text-padding">
                                Not fond of your own
                                <div className="extra-life-larger-font">FACE?</div>
                                <div className="blue-text">We've got you covered</div>
                                <div className="generic-heading extra-life-mega-font">
                                    <span className="small-super-script">$</span>10<span className="small-super-script"> Masks</span>
                                </div>
                                <div>
                                    100% of proceeds go to the
                                </div>
                                <div>
                                    <span className="blue-text">Children's Miracle Network</span>
                                </div>
                            </div>
                            <div className="generic-heading extra-life-section-text-padding">
                                <div className="extra-life-subscript">
                                    Available in-store
                                </div>
                                <div className="extra-life-subscript">
                                    while supplies last
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={isBrowser ? "browser-column" : ""}>
                        <div className="align-center">
                            <img src={trevrMask} alt="Mask" className="masked-trevr"></img>
                        </div>
                    </div>
                    <div className="parallax-wrapper parallax-divider">
                        <div className="generic-wrapper browser-wrapper">
                            <button className="heading-book-now generic-location-button" onClick={this.tryRedirect}>Book Now</button>
                            <button className="heading-book-now generic-location-button" onClick={this.donateNowLink}>Donate Now</button>
                        </div>
                    </div>
                </div>
            : null
            }

            <div className={ isBrowser ? "generic-wrapper browser-wrapper" : "generic-wrapper"}>

                <div className="generic-heading">
                    ABOUT EXTRA LIFE
                </div>

                <div className="generic-section">
                    <span className="blue-text">Extra Life&nbsp;</span>
                    is a Children’s Miracle Network Hospitals fundraising program within the gaming community. Participants fundraise year-round and pledge to game for 24 hours with one goal: to save and improve the lives of sick and injured kids. Funds raised stay local to help pediatric patients at 170 Children’s Miracle Network Hospitals across North America. Since 2008, Extra Life has raised more than $50 million for member hospitals. The movement, formed by gamers bonded by passion, is committed to saving and improving the lives of local kids.
                </div>
            </div>
            </React.Fragment>
        );
    }
}

export default Extralife;
