import { Component } from 'react';
import { withRouter } from 'react-router-dom'

// wrapper object for resetting scroll on route change
class ResetScroll extends Component {

    componentDidUpdate(prevProps) {
		// check if the location has changed since last render
      	if (this.props.location !== prevProps.location) {
			// scroll to top of the page
        	window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'auto'
			})
      	}
    }

    render() {
    	return this.props.children;
    }
}

export default withRouter(ResetScroll);