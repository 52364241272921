import React, { Component } from 'react';
import YouTube from 'react-youtube';

import "./Video.css";

const VideoPlayButton = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/video_play_button.png";

class Video extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isPlaying: false,
            playerOptions: {
                playerVars: {
                    rel: 0
                }
            },
            bestThumbnail: `https://i.ytimg.com/vi/${this.props.video}/hqdefault.jpg`
        };
    }

    componentDidMount(){
        this.check(this.props.video);
    }

    componentDidUpdate(prevProps) {
        // if the video changed, revert to the overlay / pause
        if(prevProps.video !== this.props.video) {
            this.setState({
                isPlaying: false
            });
        }
    }

    play = () => {
        this.setState({
            isPlaying: true
        });
    }

    youtubeReadyHandler = (event) => {
        event.target.playVideo();
    }

    checkImageSize = (url) => {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = function() {
                resolve(this.width !== 120)
            };
            img.onerror = reject;
            img.src = url;
        });
    }

    check = async(url) =>{
        let imageUrls = [
            `https://i.ytimg.com/vi_webp/${url}/maxresdefault.webp`,
            `https://i.ytimg.com/vi/${url}/maxresdefault.jpg`,
            `https://i.ytimg.com/vi_webp/${url}/hq720.webp`
        ];

        for(let key in imageUrls) {
            let isValidThumbnail;
            try {
                // This is because ytimg is not a CORS-compliant host
                isValidThumbnail = await this.checkImageSize(imageUrls[key]);
            } catch (error) {
                // error means its wront res, not that the resource doesnt exist
                continue;
            }

            if(isValidThumbnail) {
                this.setState({
                    bestThumbnail: imageUrls[key]
                });

                break;
            }
        }
    }

    render() {
        if(this.state.isPlaying) {
            return (
                <div className="youtube-iframe-wrapper">
                    <YouTube
                        videoId={this.props.video}
                        className="youtube-iframe"
                        onReady={this.youtubeReadyHandler}
                        opts={this.state.playerOptions}
                    />
                </div>
            );
        } else { // otherwise show the overlay 
            return (
                <div className="video-image-wrapper"  onClick={this.play}>
                    <img src={this.state.bestThumbnail} alt="video-banner"  className="video-banner"></img>
                    <img src={VideoPlayButton} alt="video-play-button" className="play-button"></img>
                </div>
            );
        }
    }
}

export default Video;
