import React, { useState } from "react";
import "./HomePopup.css";
import DynamicImg from "../dynamic_image/DynamicImg";

const HomeImg = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/TreVR%20COVID%20Mask";

function HomePopup () {
    const [open, setOpen] = useState(true);
    
    function handleClick() {
          setOpen(!open);
    }

    return (open === true ? 
        <div className = "homeBackdrop">
            <div className = "homePositionWrapper">
                <div className = "homeMain">
                    <a href = "https://www.ctrlvarcade.com/location/coral-springs#newsletter" className = "homeImage">
                        <DynamicImg  
                            imageName={HomeImg}
                            fallbackExt='jpg'
                            alt="TreVr Mask"
                            width="100%"
                            height="auto"
                        />
                    </a>
                    <span className = "homeText2">
                    Some Ctrl V locations are required to ask for proof of vaccination. Please click on<span className = "blueText"> "Locations" </span>above and select your nearest Ctrl V (or click on your previously selected location) to see if this will affect you.
                    </span>
                    <div className = "homeCloseButton" onClick = {handleClick}>X</div>
                </div>
            </div>
        </div> : null
    );  
}

export default HomePopup;