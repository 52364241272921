import React, { Component } from 'react';
import './FaqSection.css';

class FaqSection extends Component {

    state = {
        showPopup: false
    }

    // toggle visibility of popup
    togglePopup = (state) => {
        this.setState({
            showPopup: state
        });
    }

    closePopup = (event) => {
        if(event.keyCode === 27) {
            this.setState({
                showPopup: false
            });
        }
    }

    // stop close event from triggering on inside click
    stopClose = (event) => {
        event.stopPropagation();
    }

    render() {
        return (
            <div className="faq-section" 
            style={{...this.props.buttonStyle}} 
            tabIndex="0" 
            onKeyDown={this.closePopup}>
                <div 
                className="faq-section-title" 
                style={{backgroundColor:this.props.buttonColor}}
                onClick={() => this.togglePopup(true)}
                >
                    <div className="faq-section-title-wrapper">
                        {this.props.title} 
                    </div>
                    <span className="expand-icon">+</span>
                </div>

                <div className={this.state.showPopup ? "faq-modal-wrapper show-faq-modal" : "faq-modal-wrapper hide-faq-modal"} 
                    onClick={() => this.togglePopup(false)}>
                    <div className="faq-modal" 
                    style={{...this.props.modalStyle}} 
                    onClick={this.stopClose}>
                        <button className="close-button" onClick={() => this.togglePopup(false)}>
                            X
                        </button>

                        <div className="faq-section-heading">
                            {this.props.title}
                        </div>
                        <div className="faq-section-body">
                            {this.props.body}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default FaqSection;