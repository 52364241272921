import React, { Component } from 'react';
import './NewsletterSignUp.css';
import Axios from 'axios';

import { isBrowser } from 'react-device-detect'

import { withLocalize, Translate } from 'react-localize-redux';
import NewsletterTranslation from '../../translations/newsletter.json';
import { addCustomTagEvent } from '../../utilities/customTag';

class NewsletterSignUp extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(NewsletterTranslation);
    }

    state = {
        email: '',
        firstName: '',
        lastName: '',
        birthdayMonth: '',
        birthdayDay: ''
    }

    // checking and clamping month/day to be numeric, between 0-2 chars, and less than the max possible (12 for month, 31 for day)
    sanityCheck = (key, value) => {
        if(key === "birthdayMonth") {
            return value === "" || (value.toString().length <= 2 && !isNaN(value) && parseInt(value) <= 12 && parseInt(value) >= 0);
        } else if(key === "birthdayDay") {
            return value === "" || (value.toString().length <= 2 && !isNaN(value) && parseInt(value) <= 31 && parseInt(value) >= 0);
        } else return true; // if its not day or month, just return true
    }

    // catch-all binding event for inputs. get key from dom, and save in state
    inputChanged = (event) => {
        // get state key from dom element
        let key = event.target.getAttribute('statekey');
        // get state value from dom element
        let value = event.target.value;

        // sanity check on input. this is reliable, but does not check everything
        if(this.sanityCheck(key, value)) {
            // copy current state immutably
            let newState = {...this.state};
            newState[key] = value;
            this.setState(newState);
        }
    }

    isValidForm = () => {
        return this.state.email !== '' && this.state.firstName !== '' && this.state.lastName !== '' && this.state.birthdayDay !== '' && this.state.birthdayMonth !== '';
    }

    // This is kind of garbage, but i couldnt find an api other than sending this, which is what the wordpress site is also doing
    sendSignUp = () => {
        if(!this.isValidForm()) return;

        // i have no idea what each of these values represent, but it doesnt work without them
        let url = "https://ctrlv.us12.list-manage.com/subscribe/post-json?u=974cb9e9d7e5ba75d41815921&id=7f481c59fa";

        url += "&EMAIL=" + this.state.email;
        url += "&FNAME=" + this.state.firstName;
        url += "&LNAME=" + this.state.lastName;
        url += "&BIRTHDAY%5Bmonth%5D=" + this.state.birthdayMonth;
        url += "&BIRTHDAY%5Bday%5D=" + this.state.birthdayDay;
        url += "&subscribe=Subscribe";

        let _this = this;
        Axios.get(url)
            .finally(function() {
                _this.setState({
                    message: "Thank you! Submission received!"
                })
                addCustomTagEvent({
                    event: 'NewsletterSignUp'
                })
             });
    }

    getSubmitButtonClasses = () => {
        let classes = "news-letter-button ";

        if(isBrowser) classes += "news-letter-button-browser-font ";
        else classes += "news-letter-button-mobile-font ";

        if(this.isValidForm()) classes += "enabled-letter-button"
        else classes += "disabled-letter-button"

        return classes;
    }

    render() {
        return (
            <div className="newsletter-wrapper">
                <div className="newsletter-row">
                    <div className="contact-label">
                        <Translate id="form.email.label" />*
                    </div>
                    <div className="input-wrapper">
                        <Translate>
                            {({ translate }) => <input aria-label="Email" type="text" value={this.state.email} statekey="email" onChange={this.inputChanged} placeholder={translate('form.email.placeholder')} />}
                        </Translate>
                    </div>
                </div>
                <div className="newsletter-row">
                    <Translate id="form.first-name.label" />*
                    <Translate>
                        {({ translate }) => <input aria-label="First Name" type="text" value={this.state.firstName} statekey="firstName" onChange={this.inputChanged} placeholder={translate('form.first-name.placeholder')} />}
                    </Translate>
                </div>
                <div className="newsletter-row">
                    <Translate id="form.last-name.label" />*
                    <Translate>
                        {({ translate }) => <input aria-label="Last Name" type="text" value={this.state.lastName} statekey="lastName" onChange={this.inputChanged} placeholder={translate('form.last-name.placeholder')} />}
                    </Translate>
                </div>
                <div className="newsletter-row">
                    <Translate id="form.birthday.label" />*
                    <div className="birthday-wrapper">
                        <input aria-label="Birthday Month" type="text" value={this.state.birthdayMonth} statekey="birthdayMonth" onChange={this.inputChanged} placeholder="MM"></input>
                        <input aria-label="Birthday Day" type="text" value={this.state.birthdayDay} statekey="birthdayDay" onChange={this.inputChanged} placeholder="DD"></input>
                    </div>
                </div>

                <div className="alignment-wrapper">
                    <button
                        className={ this.getSubmitButtonClasses() }
                        onClick={this.sendSignUp}>
                            Submit
                    </button>
                </div>

                {this.state.message}

            </div>
        );
    }
}

export default withLocalize(NewsletterSignUp);
