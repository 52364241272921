import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { withLocalize, Translate } from 'react-localize-redux';
import NavTranslation from '../../../../translations/navigation.json';

import {
    LOCAL_STORAGE,
    getBaseName
} from '../../../../utilities/GlobalConsts';

import './SideTray.css';
import Search from '../../../search/Search.js';

const DropDownArrow = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/dropdown_arrow_white.png';

class SideTray extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(NavTranslation);
    }

    clearSavedLocation = () => {
        window.localStorage.removeItem(LOCAL_STORAGE.LOCATION.KEY);
        window.localStorage.removeItem(LOCAL_STORAGE.LOCATION_DETAILS.KEY);
        this.props.linkClicked();
        this.props.history.push("/locations");
    }

    getLocationMenuItem = () => {
        if(this.props.locationDetails) {
            return (
                <React.Fragment>
                    <li onClick={this.props.locationsClicked} className="sub-menu-li">
                        {this.props.locationDetails.displayName}
                        <img src={DropDownArrow} alt="Dropdown Arrow" className="side-tray-sub-menu-dropdown"></img>
                    </li>

                    <ul className={this.props.locationsSubMenuClass}>
                        <li><HashLink to={`/location/${this.props.locationDetails.webName}/`} onClick={this.props.linkClicked}>
                            - <Translate id="locations.location" />
                        </HashLink></li>
                        <li><HashLink to={`/birthdays/${this.props.locationDetails.webName}`} onClick={this.props.linkClicked}>
                            - <Translate id="locations.birthdays" />
                        </HashLink></li>
                        <li><HashLink to={`/events/${this.props.locationDetails.webName}`} onClick={this.props.linkClicked}>
                            - <Translate id="locations.events" />
                        </HashLink></li>
                        <li><HashLink to={`/location/${this.props.locationDetails.webName}/#calendar`} onClick={this.props.linkClicked}>
                            - <Translate id="locations.calendar" />
                        </HashLink></li>
                        <li><HashLink to={`/location/${this.props.locationDetails.webName}/#games`} onClick={this.props.linkClicked}>
                            - <Translate id="locations.games" />
                        </HashLink></li>
                        <li><HashLink to={`/escape-rooms`} onClick={this.props.linkClicked}>
                            - <Translate id="locations.escape-rooms" />
                        </HashLink></li>
                        <li><a href={`${getBaseName()}/leaderboards`}>
                            - <Translate id="locations.leaderboards" />
                        </a></li>
                        <li onClick={this.clearSavedLocation} className="link-hover">
                            - <Translate id="change-location" />
                        </li>
                    </ul>
                </React.Fragment>

            );
        } else {
            return (
                <li><Link to="/locations" onClick={this.props.linkClicked}>
                    <Translate id="locations.main" />
                </Link></li>
            );
        }
    }

    getCorperateClasses = () => {
        if(this.props.corporateSubMenuIsOpen) {
            if(this.props.hideCorperate) {
                return "sub-menu corperate-hide-franchsing";
            } else {
                return "sub-menu corperate-side-tray-sub-item";
            }
        } else {
            return "sub-menu closed-side-tray-sub-item"
        }
    }

    getBookingLink = () => {
        if(this.props.locationDetails) {
            return `${getBaseName()}/booking/location/${this.props.locationDetails.location_id}`
        } else {
            return `${getBaseName()}/booking/location`
        }
    }

    render() {

        return (
            <div className={this.props.trayWrapperClass}>
                <ul>
                    <li className="search">
                        <div className="sub-menu-li search-dropdown-arrow">
                            <img src={DropDownArrow} alt="Dropdown Arrow" className="side-tray-sub-menu-dropdown"></img>
                        </div>
                        <Search inline={true}/>
                    </li>
                    <li><a href={this.getBookingLink()}>
                        <Translate id="book-now" />
                    </a></li>

                    {this.getLocationMenuItem()}
                    
                    <li><Link to="/gift-cards" onClick={this.props.linkClicked}>
                        <Translate id="gift-cards" />
                    </Link></li>

                    <li onClick={this.props.freeVrClicked} className="sub-menu-li">
                        <Translate id="free-vr.title" />
                        <img src={DropDownArrow} alt="Dropdown Arrow" className="side-tray-sub-menu-dropdown"></img>
                    </li>

                    <ul className={this.props.freeVrSubMenuClass}>
                        <li><Link to="/free-VR" onClick={this.props.linkClicked}>
                            - <Translate id="free-vr.demo-day" />
                        </Link></li>
                        
                        <li><Link to="/referral" onClick={this.props.linkClicked}>
                            - <Translate id="free-vr.referral" />
                        </Link></li>
                        
                        <li><Link to="/loyalty" onClick={this.props.linkClicked}>
                            - <Translate id="free-vr.loyalty-program" />
                        </Link></li>

                        <li><Link to="/seniors" onClick={this.props.linkClicked}>
                            - <Translate id="free-vr.seniors" />
                        </Link></li>
                    </ul>

                    <li onClick={this.props.waiverVideoClicked} className="sub-menu-li">
                        <Translate id="waiver-video.title" />
                        <img src={DropDownArrow} alt="Dropdown Arrow" className="side-tray-sub-menu-dropdown"></img>
                    </li>
                
                    <ul className={this.props.waiverVideoSubMenuClass}>
                        <li><a href={`${getBaseName()}/waiver`}>
                            - <Translate id="waiver-video.waiver" />
                        </a></li>

                        <li><a href="https://youtu.be/CydlfstKmDw" target="_blank" rel="noopener noreferrer">
                            - <Translate id="waiver-video.video" />
                        </a></li>
                    </ul>

                    <li onClick={this.props.myInfoClicked} className="sub-menu-li">
                        <Translate id="my-info.title" />
                        <img src={DropDownArrow} alt="Dropdown Arrow" className="side-tray-sub-menu-dropdown"></img>
                    </li>
                
                    <ul className={this.props.myInfoSubMenuClass}>
                        <li><a href={`${getBaseName()}/accounts/login/`}>
                            - <Translate id="my-info.account-ctrl" />
                        </a></li>

                        <li><a href={`${getBaseName()}/move-booking/`} target="_blank" rel="noopener noreferrer">
                            - <Translate id="my-info.move-booking" />
                        </a></li>
                    </ul>

                    <li><Link to="/faq" onClick={this.props.linkClicked}>
                        <Translate id="faq" />
                    </Link></li>

                    { !this.props.hideCorperate ? 

                        <li><a href="https://www.virtualrealityfranchise.com/" target="_blank" rel="noopener noreferrer">
                            <Translate id="franchising" />
                        </a></li>

                        : null
                    }

                    <li onClick={this.props.corporateClicked} className="sub-menu-li">
                        <Translate id="corporate.title" />
                        <img src={DropDownArrow} alt="Dropdown Arrow" className="side-tray-sub-menu-dropdown"></img>
                    </li>
                
                    <ul className={this.getCorperateClasses()}>
                        <li><Link to="/about" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.about" />
                        </Link></li>
                        <li><Link to="/location-map" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.location-map" />
                        </Link></li>
                        <li><Link to="/pressroom" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.pressroom" />
                        </Link></li>
                        { this.props.hideCorperate ? 

                            <li><a href="https://www.virtualrealityfranchise.com/" target="_blank" rel="noopener noreferrer">
                                - <Translate id="franchising" />
                            </a></li>

                            : null
                        }
                    </ul>
                    
                    <li onClick={this.props.legalClicked} className="sub-menu-li">
                        <Translate id="corporate.legal.title" />
                        <img src={DropDownArrow} alt="Dropdown Arrow" className="side-tray-sub-menu-dropdown"></img>
                    </li>
                    <ul className={this.props.legalSubMenuClass}>
                        <li><Link to="/legal/terms-of-use" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.legal.terms-of-use" />
                        </Link></li>
                        <li><Link to="/legal/privacy-policy" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.legal.privacy-policy" />
                        </Link></li>
                        <li><Link to="/legal/disclaimer" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.legal.disclaimer" />
                        </Link></li>
                        <li><Link to="/legal/copyright" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.legal.copyright" />
                        </Link></li>
                        <li><Link to="/legal/anti-spam" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.legal.anti-spam" />
                        </Link></li>
                        <li><Link to="/legal/cookies" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.legal.cookies" />
                        </Link></li>
                        <li><Link to="/legal/linking" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.legal.linking" />
                        </Link></li>
                        <li><Link to="/legal/ctrl-v-rules" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.legal.rules" />
                        </Link></li>
                        <li><Link to="/legal/terms-and-conditions" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.legal.terms-and-conditions" />
                        </Link></li>
                        <li><Link to="/legal/media-release" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.legal.media-release" />
                        </Link></li>
                        <li><Link to="/legal/contest-rules" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.legal.contest-rules" />
                        </Link></li>
                        <li><Link to="/legal/sweepstakes-rules" onClick={this.props.linkClicked}>
                            - <Translate id="corporate.legal.sweepstakes-rules" />
                        </Link></li>
                    </ul>
                    {/* <li><Search display="none"/></li> */}
                </ul>
            </div>
        );
    }
}

export default withLocalize(withRouter(SideTray));