import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';

import { withLocalize, Translate } from 'react-localize-redux';
import RulesTranslation from '../../../../translations/rules.json';

import { getBaseName } from '../../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

class UsageRules extends Component { 
    
    constructor(props) {
        super(props);

        this.props.addTranslation(RulesTranslation);
    }

    render() {
        return(
            <div className={isBrowser ? "generic-wrapper align-left blue-sub-headings browser-wrapper" : "generic-wrapper align-left blue-sub-headings"}>

                <Helmet>
                    <title>Usage Rules - Ctrl V</title>
                    <link rel="canonical" href={`${getBaseName()}/legal/ctrl-v-rules/`} />
                    <meta property="og:title" content="Usage Rules - Ctrl V" />
                    <meta property="og:url" content={`${getBaseName()}/legal/ctrl-v-rules/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:title" content="Usage Rules - Ctrl V " />
                    <meta name="keywords" content="rules" />
                </Helmet>

                <div className="generic-heading generic-heading-padding">
                    <Translate id="title" />
                </div>
                <div className="generic-section">
                    <Translate id="pre-list-text" />
                    <ul className="numbered-list">
                        <li><Translate id="list.item-1" /></li>
                        <li><Translate id="list.item-2" /></li>
                        <li><Translate id="list.item-3" /></li>
                        <li><Translate id="list.item-4" /></li>
                        <li><Translate id="list.item-5" /></li>
                        <li><Translate id="list.item-6" /></li>
                        <li><Translate id="list.item-7" /></li>
                        <li><Translate id="list.item-8" /></li>
                        <li><Translate id="list.item-9" /></li>
                        <li><Translate id="list.item-10" /></li>
                        <li><Translate id="list.item-11" /></li>
                        <li><Translate id="list.item-12" /></li>
                        <li><Translate id="list.item-13" /></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default withLocalize(UsageRules);