import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import MediaRelease from './media_release/MediaRelease';
import TermsOfUse from './terms_of_use/TermsOfUse';
import PrivacyPolicy from './privacy_policy/PrivacyPolicy';
import Disclaimer from './Disclaimer/Disclaimer';
import Copyright from './copyright/Copyright';
import AntiSpam from './anti_spam/AntiSpam';
import Cookies from './cookies/Cookies';
import Linking from './linking/Linking';
import UsageRules from './usage_rules/UsageRules';
import TermsAndConditions from './terms_and_conditions/TermsAndConditions';
import Sweepstakes from './contests/sweepstakes/Sweepstakes';
import Contests from './contests/contests/Contests';

class Legal extends Component {

    render() {
        return (
            <Switch>
                <Route path="/legal/terms-of-use/" render={() => <TermsOfUse />}></Route>
                <Route path="/legal/privacy-policy/" render={() => <PrivacyPolicy />}></Route>
                <Route path="/legal/disclaimer/" render={() => <Disclaimer />}></Route>
                <Route path="/legal/copyright/" render={() => <Copyright />}></Route>
                <Route path="/legal/anti-spam/" render={() => <AntiSpam />}></Route>
                <Route path="/legal/cookies/" render={() => <Cookies />}></Route>
                <Route path="/legal/linking/" render={() => <Linking />}></Route>
                <Route path="/legal/ctrl-v-rules" render={() => <UsageRules />}></Route>
                <Route path="/legal/terms-and-conditions/" render={() => <TermsAndConditions />}></Route>
                <Route path="/legal/media-release/" render={() => <MediaRelease />}></Route>
                <Route path="/legal/contest-rules/" render={() => <Contests />}></Route>
                <Route path="/legal/sweepstakes-rules/" render={() => <Sweepstakes />}></Route>
            </Switch>
        );
    }
}

export default Legal;