import React, { useState } from "react";
import "./GrandOpeningPopup.css";
import DynamicImg from "../dynamic_image/DynamicImg";

const GrandOpeningImg = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Plano%20Grand%20Opening%20Popup";

function GrandOpeningPopup () {
    const [open, setOpen] = useState(true);
    
    function handleClick() {
          setOpen(!open);
    }

    return (open === true ? 
        <div className = "GOBackdrop">
            <div className = "GOPositionWrapper">
                <div className = "GOMain">
                    <div className = "GOImage">
                        <DynamicImg  
                            imageName={GrandOpeningImg}
                            fallbackExt='png'
                            alt="Plano Grand Opening"
                            width="100%"
                            height="auto"
                        />
                    </div>
                    <div className = "GOCloseButton" onClick = {handleClick}>X</div>
                </div>
            </div>
        </div> : null
    );  
}

export default GrandOpeningPopup;