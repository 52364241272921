import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";
import Axios from 'axios';
import { isBrowser } from "react-device-detect";

import { withLocalize, Translate } from 'react-localize-redux';
import LocationsTranslation from '../../../translations/locations.json';

import './Locations.css';

import DetectedLocationModal from './detected_location_modal/DetectedLocationModal';
import {Helmet} from 'react-helmet';

import {
    GET_LOCATIONS_FOR_WEBSITE,
    GET_LOCATION_DETAILS_FOR_WEBSITE,
    LOCAL_STORAGE,
    getLocalStorage,
    setLocalStorage,
    localStorageObjectExpired,
    getBaseName
} from '../../../utilities/GlobalConsts';

const SAN_JOSE_LOCATION_ID = 28;

class Locations extends Component {

    locationCoords = {};

    cache = false;

    constructor(props) {
        super(props);

        this.state = {
            closestLocation: null,
            route: null,
            showModal: null,
            locationIsSatallite: null,
            resultsShown: 10,
            isMetric: true,
            loadByCountry: true,
        };

        this.props.addTranslation(LocationsTranslation);
    }

    deg2rad(deg) {
        return deg * (Math.PI/180)
    }

    earthDistance(point1, point2) {
        const earthRadius = 6371;
        let dLat = this.deg2rad(point2[0]-point1[0]);
        let dLon = this.deg2rad(point1[1]-point2[1]);
        let a =
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(this.deg2rad(point1[0])) * Math.cos(this.deg2rad(point2[0])) *
          Math.sin(dLon/2) * Math.sin(dLon/2);

        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        return earthRadius * c;
    }

    getDistances = (position) => {
        let userPos = [position.coords.latitude, position.coords.longitude];

        let distances = [];

        // eslint-disable-next-line
        for(let key in this.locationCoords) {

            let point = [this.locationCoords[key].coordinates.x, this.locationCoords[key].coordinates.y]
            let earthDistance = this.earthDistance(point, userPos)

            distances.push([key, earthDistance]);
        }

        distances.sort(function(a, b) {
            return a[1] - b[1];
        });

        return distances;
    }

    positionLogic = (position) => {

        let location = getLocalStorage(LOCAL_STORAGE.LOCATION.KEY);

        let newState = {
            locationDetected: true,
            loadByCountry: false
        };

        if(location && !localStorageObjectExpired(location)) {

            let distances = this.getDistances(position);

            newState.distances = distances;

        } else {
            let distances = this.getDistances(position);
            let locationObj = this.locationCoords[distances[0][0]];

            let noLocation = getLocalStorage(LOCAL_STORAGE.NO_LOCATION.KEY);

            if(!noLocation || localStorageObjectExpired(noLocation)) {

                newState.showModal = locationObj.display_name + ', ' + locationObj.province_code;
                newState.locationIsSatallite = !locationObj.uses_crowd_ctrl
            }

            newState.distances = distances;
        }

        this.setState(newState);
    }

    locationCheckError = (error) => {

        // sort ascending by display_name
        this.locationCoords.sort((a, b) => {
            if(a.display_name < b.display_name) return -1
            else if (a.display_name > b.display_name) return 1
            else return 0;
        });

        this.setState({
            distances: this.locationCoords
        });
    }

    getLocationData = async (location) => {
        try {
            let response = await Axios.get(GET_LOCATION_DETAILS_FOR_WEBSITE + location);
            return response.data;
        } catch(err) {
            console.error("Error getting location data");
            console.error(err);
        }
    }


    DetectedLocationModalConfirm = async (redirect) => {
        let locationObj = this.locationCoords[this.state.distances[0][0]];

        if(redirect) {
            this.saveLocation(locationObj);

        } else {
            this.setState({
                showModal: null
            });
        }
    }

    showAllResults = () => {
        this.setState({
            resultsShown: this.state.distances.length
        })
    }

    toggleFreedom = () => {
        this.setState({
            isMetric: !this.state.isMetric
        })
    }

    getLocationsForWebsite = async () => {
        try {
            let response = await Axios.get(GET_LOCATIONS_FOR_WEBSITE);

            this.locationCoords = response['data']['res'];
            navigator.geolocation.getCurrentPosition(this.positionLogic);

            // sort ascending by display_name
            this.locationCoords.sort((a, b) => {
                if(a.display_name < b.display_name) return -1
                else if (a.display_name > b.display_name) return 1
                else return 0;
            });

            this.setState({
                distances: this.locationCoords
            });
        } catch(err) {
            console.error("Error getting locations for website")
            console.error(err);
        }
    }

    componentDidMount = async() =>{

        await this.getLocationsForWebsite();
    }

    saveLocation = async (locationObj) => {
        if(locationObj.uses_crowd_ctrl && locationObj.location_id !== SAN_JOSE_LOCATION_ID) {
            window.localStorage.removeItem(LOCAL_STORAGE.NO_LOCATION.KEY);

            let locationSpecificData = await this.getLocationData(locationObj.web_name);

            setLocalStorage(LOCAL_STORAGE.LAST_REDIRECT.KEY, LOCAL_STORAGE.LAST_REDIRECT.EXPIRY_DURATION);
            setLocalStorage(LOCAL_STORAGE.LOCATION.KEY, LOCAL_STORAGE.LOCATION.EXPIRY_DURATION, {
                closestLocation: locationObj.web_name,
                ...locationObj
            });
            setLocalStorage(LOCAL_STORAGE.LOCATION_DETAILS.KEY, LOCAL_STORAGE.LOCATION_DETAILS.EXPIRY_DURATION, {
                ...locationSpecificData
            })

            this.setState({
                route: '/location/' + locationObj.web_name
            });
        }
    }

    addLocationToRenderByCountry = async(locations, locationObj, newLocation) => {
        locations[locationObj.country] = locations[locationObj.country] || {};
        const country = locations[locationObj.country];

        country[locationObj.province_code] = country[locationObj.province_code] || [];
        const province = country[locationObj.province_code];

        province.push(newLocation);
        return locations;
    }

    renderByCountry = (locations) => {
        return Object.entries(locations).sort(([countryKey1], [countryKey2]) => countryKey1.localeCompare(countryKey2)).map(([country, provinces]) => {
            return <div className="country-list" key={country}>
                <div className="country-list-title">{country}</div>
                <div className="country-list-content">
                    {
                        Object.entries(provinces).sort(([provinceKey1], [provinceKey2]) => provinceKey1.localeCompare(provinceKey2)).map(([province, cities]) => {
                            return <div className="province-list-wrapper">
                                <div className="province-list" key={province}>
                                    <div className="province-list-title">{province}</div>
                                    <div className="province-list-content">
                                        {cities}
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>;
        });
    }

    renderNearestLocations = (locations) => {
        return (
            <div className="location-list">
                { locations }
            </div>
        )
    }

    toggleLoadByCountry = () => {
        this.setState({ loadByCountry: !this.state.loadByCountry });
    }

    render() {
        let redirectJsx = null;
        let locations = !this.state.loadByCountry ? [] : {};
        if(this.state.route === null) {
            const locationsToRender = !this.state.loadByCountry ? this.state.distances.slice(0, this.state.resultsShown) : this.state.distances;

            for(let key in locationsToRender) {
                let locationToRender = locationsToRender[key];
                // Try to get the location. If it's an array, grab the first element, else, it's an object
                let locationKey, locationObj = null;
                if (Array.isArray(locationToRender)) {
                    locationKey = locationToRender[0];
                    locationObj = this.locationCoords[locationKey];
                } else {
                    locationKey = locationToRender["location_id"];
                    locationObj = this.locationCoords.find(elem => {
                        return locationKey === elem.location_id;
                    });
                }
                if(!this.state.loadByCountry) {
                    let roundedDistance = Math.round(locationToRender[1] * 10) / 10
                    locations.push(
                        <div className="location-list-item" key={key}>
                            <div className={ isBrowser ? "desktop-list-size" : "mobile-list-size" }>
                                <Link onClick={() => this.saveLocation(locationObj)} to={"/location/" + locationObj.web_name}
                                className={ "location-list-button parallelogram " + (locationObj.company_id !== 1? "location-list-revshare ":"") + (isBrowser ? "desktop-list-size" : "mobile-list-size") }>
                                    <span className="skew-fix">
                                        {locationObj.display_name} ({this.state.isMetric ? roundedDistance + " KM" : Math.round(roundedDistance * 0.621371 * 10) / 10 + " Miles"})
                                    </span>
                                </Link>
                            </div>
                        </div>
                    );
                } else {
                    locationToRender = locationObj;
                    const newLocation = (
                        <div className="location-list-item" key={key}>
                            <div className={ isBrowser ? "desktop-list-size" : "mobile-list-size" }>
                                <Link
                                onClick={() => this.saveLocation(locationToRender)}
                                to={"/location/" + locationToRender.web_name}
                                className={ "parallelogram location-list-button "
                                + (!locationObj.uses_crowd_ctrl ? "location-list-revshare ": "")
                                + (isBrowser ? "desktop-list-size" : "mobile-list-size") }>
                                    <span className="skew-fix">
                                        {locationToRender.display_name}
                                    </span>
                                </Link>
                            </div>
                        </div>
                    );
                    this.addLocationToRenderByCountry(locations, locationObj, newLocation)
                }
            }
        } else {
            redirectJsx = <Redirect push to={this.state.route} />;
        }

        const renderedLocations = !this.state.loadByCountry ? this.renderNearestLocations(locations) : this.renderByCountry(locations);
        return(
            <div className="locations-wrapper">

                <Helmet>
                    <title>Ctrl V Locations | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Ctrl V virtual reality locations" />
                    <link rel="canonical" href={`${getBaseName()}/locations/`} />
                    <meta property="og:title" content="Locations | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="A safe, socially-distanced, physically-active, family-fun activity for all ages!" />
                    <meta property="og:url" content={`${getBaseName()}/locations/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Ctrl V virtual reality locations" />
                    <meta name="twitter:title" content="Locations | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="arcade,select,locations" />
                </Helmet>

                { !this.state.locationIsSatallite ?
                    <DetectedLocationModal location={this.state.showModal} logicHandler={this.DetectedLocationModalConfirm} />
                    : null
                }

                {redirectJsx}

                    <div className={ isBrowser ? "locations-list-wrapper" : null }>
                        <div className="locations-heading">
                            <Translate id="locations" />
                        </div>

                        {!this.state.loadByCountry ?
                            <button className="generic-location-button" onClick={this.toggleFreedom}><Translate id="show-in" /> { !this.state.isMetric ? "KM" : "Miles" }</button>
                            : null
                        }

                        <Link to="/location-map">
                            <button className="generic-location-button"><Translate id="see-location-map" /></button>
                        </Link>

                        { this.state.resultsShown !== -1 && !this.state.loadByCountry ?
                            <button className="generic-location-button" onClick={this.showAllResults}><Translate id="load-all" /></button>
                        : null }

                        {
                            this.state.resultsShown !== -1 && this.state.locationDetected ?
                                <button className="generic-location-button" onClick={this.toggleLoadByCountry}>
                                    { this.state.loadByCountry ? <Translate id="load-closest-location" /> : <Translate id="load-by-country" /> }
                                </button>
                                : null
                        }

                        { renderedLocations }
                    </div>
                    <div className='bottom-spacing'></div>
            </div>
        );
    }
}

export default withLocalize(Locations);
