import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';

import { withLocalize, Translate } from 'react-localize-redux';
import LinkingTranslation from '../../../../translations/linking.json';

import { getBaseName } from '../../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

class Linking extends Component { 

    constructor(props) {
        super(props);

        this.props.addTranslation(LinkingTranslation);
    }

    render() {
        return (
            <div className={isBrowser ? "generic-wrapper align-left blue-sub-headings browser-wrapper" : "generic-wrapper align-left blue-sub-headings"}>

                <Helmet>
                    <title>View our Linking Policy | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Linking and the usage of links relating to Ctrl V is governed under this policy and must be obeyed. If any custom requests are needed please contact Ctrl V."/>
                    <link rel="canonical" href={`${getBaseName()}/legal/linking/`} /> 
                    <meta property="og:title" content="View our Linking Policy | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Linking and the usage of links relating to Ctrl V is governed under this policy and must be obeyed. If any custom requests are needed please contact Ctrl V." />
                    <meta property="og:url" content={`${getBaseName()}/legal/linking/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Linking and the usage of links relating to Ctrl V is governed under this policy and must be obeyed. If any custom requests are needed please contact Ctrl V." />
                    <meta name="twitter:title" content="View our Linking Policy | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="linking" />
                </Helmet>

                <div className="generic-heading generic-heading-padding">
                    <Translate id="title" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="status.title" />
                </div>
                <div className="generic-section">
                    <Translate id="status.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="status.body-2" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="links-to.title" />
                </div>
                <div className="generic-section">
                    <Translate id="links-to.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="links-to.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="links-to.body-3" />
                </div>
                <div className="generic-section">
                    <Translate id="links-to.body-4" />
                </div>
                <div className="generic-section">
                    <Translate id="links-to.body-5" />
                </div>
                <div className="generic-section">
                    <Translate id="links-to.body-6" />
                </div>
                
                <div className="generic-sub-heading">
                    <Translate id="links-from.title" />
                </div>
                <div className="generic-section">
                    <Translate id="links-from.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="links-from.body-2" />
                </div>
                
                <div className="generic-sub-heading">
                    <Translate id="removal.title" />
                </div>
                <div className="generic-section">
                    <Translate id="removal.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="removal.body-2" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="changes.title" />
                </div>
                <div className="generic-section">
                    <Translate id="changes.body-1" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="contact.title" />
                </div>
                <div className="generic-section">
                    <Translate id="contact.body-1" />
                </div>
                <div className="generic-section">
                    <span className="blue-text">
                        <a href="mailto: contact@ctrlv.ca">
                            contact@ctrlv.ca
                        </a>
                    </span>
                    
                </div>
            </div>
        );
    }
}

export default withLocalize(Linking);