import React, { Component } from 'react';

import { withLocalize, Translate } from 'react-localize-redux';
import CopyrightTranslation from '../../../../translations/copyright.json';

import { isBrowser } from 'react-device-detect';

import { getBaseName } from '../../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

class Copyright extends Component {
    
    constructor(props) {
        super(props);

        this.props.addTranslation(CopyrightTranslation);
    }

    getCurrentYear = () => {
        const d = new Date();
        return d.getFullYear();
    }

    render() {
        return(
            <div className={isBrowser ? "generic-wrapper align-left blue-sub-headings browser-wrapper" : "generic-wrapper align-left blue-sub-headings"}>

                <Helmet>
                    <title>Copyright Notice and Policy | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="All information on the Ctrl V website is protected under copyright laws and may not be duplicated without permission of Ctrl V Inc."/>
                    <link rel="canonical" href={`${getBaseName()}/legal/copyright/`} />
                    <meta property="og:title" content="Copyright Notice and Policy | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="All information on the Ctrl V website is protected under copyright laws and may not be duplicated without permission of Ctrl V Inc." />
                    <meta property="og:url" content={`${getBaseName()}/legal/copyright/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="All information on the Ctrl V website is protected under copyright laws and may not be duplicated without permission of Ctrl V Inc." />
                    <meta name="twitter:title" content="Copyright Notice and Policy | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="copyright" />
                </Helmet>

                <div className="generic-heading generic-heading-padding">
                    <Translate id="title" />
                </div>

                <div className="generic-section">
                    © 2016-{this.getCurrentYear()} Ctrl V Inc. and Ctrl V Partners LLC
                </div>

                <div className="generic-sub-heading">
                    <Translate id="ownership.title" />
                </div>
                <div className="generic-section">
                    <Translate id="ownership.body-1" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="license.title" />
                </div>
                <div className="generic-section">
                    <Translate id="license.body-1" />
                    <ul>
                        <li><Translate id="license.body-2" /></li>
                        <li><Translate id="license.body-3" /></li>
                        <li><Translate id="license.body-4" /></li>
                    </ul>
                </div>
                <div className="generic-section">
                    <Translate id="license.body-5" />
                </div>
                <div className="generic-section">
                    <Translate id="license.body-6" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="data-mining.title" />
                </div>
                <div className="generic-section">
                    <Translate id="data-mining.body-1" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="permissions.title" />
                </div>
                <div className="generic-section">
                    <Translate id="permissions.body-1" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="enforcement.title" />
                </div>
                <div className="generic-section">
                    <Translate id="enforcement.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="enforcement.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="enforcement.body-3" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="infringing.title" />
                </div>
                <div className="generic-section">
                    <Translate id="infringing.body-1" />
                </div>
            </div>
        );
    }
}

export default withLocalize(Copyright);