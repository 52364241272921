import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';
import unsubscribeTranslations from '../../../translations/unsubscribe.json';
import { withLocalize, Translate } from 'react-localize-redux';
import { getBaseName, getExtension } from '../../../utilities/GlobalConsts';

const TREVOR_IMAGE = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/trevr_crying';

class Unsubscribe extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(unsubscribeTranslations);
    }

    render = () => {
        return (
            <div className="generic-wrapper">
                <div className="trevr-img">
                    <img alt="Ctrl V's mascot, TreVR" aria-label="TreVR" width="20%" src={`${TREVOR_IMAGE}.${getExtension()}`} />
                </div>
                <div className="generic-heading">
                    <div className="align-center">
                        <Translate id="success" />
                    </div>
                </div>

                <br></br>
                
                <div className="generic-sub-heading">
                    <div className="align-center">
                        <Translate id="empathize" />
                    </div>
                </div>

                <br></br>
                <br></br>

                <div className="generic-sub-heading">
                    <div className="align-center">
                        <Translate id="soon" />
                    </div>
                </div>

                <div className="alignment-wrapper">
                    <a href={`${getBaseName()}/booking/location/`}>
                        <button className={ isBrowser ? "ctrlv-blue-button-browser add-pulse-animation" : "ctrlv-blue-button add-pulse-animation" }>
                            <Translate id="book-now-button" />
                        </button>
                    </a>
                </div>
            </div>
        )
    }
}

export default withLocalize(Unsubscribe);