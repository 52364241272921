import React from 'react';
import {Link} from 'react-router-dom';

const smartLink = (props) =>{
    if(props.to.match('www\\.') || props.to.match('https')){ //If this is an absolute link
        return (
            <a href={props.to}>
                {props.children}
            </a>
        )
    }
    else{ //If this is a local link
        return(
            <Link to={props.to}>
                {props.children}
            </Link>
        )
    }
}

export default smartLink;