import React, { Component } from 'react';

class DynamicImg extends Component {

    getSources = () => {
        let sources = [];

        sources.push(
            <source key={1}
                type="image/webp"
                srcSet={`${this.props.imageName}.webp`}
                height={this.props.height}
                width={this.props.width}
                alt="DynamicImg"
            />
        )
        sources.push(
            <source key={2}
                type="image/jp2"
                srcSet={`${this.props.imageName}.png`}
                height={this.props.height}
                width={this.props.width}
                alt="DynamicImg"
            />
        )
        sources.push(
            <img key={3}
                src={`${this.props.imageName}.${this.props.fallbackExt}`}
                height={this.props.height}
                width={this.props.width}
                className={this.props.imgClass}
                alt="DynamicImg"
            />
        )

        return sources;
    }

    render = () => {
        return (
            <picture>
                {this.getSources()}
            </picture>
        )
    }
}

export default DynamicImg;