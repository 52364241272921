import React, { Component } from 'react';
import SocialIcons from './social_icons/SocialIcons';
import { Link } from "react-router-dom";
import { isBrowser } from 'react-device-detect';

import LanguageToggle from '../language_toggle/LanguageToggle';
import { withLocalize, Translate } from 'react-localize-redux';
import FooterTranslation from '../../translations/footer.json';
import { getBaseName } from '../../utilities/GlobalConsts';


import './Footer.css';

class Footer extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(FooterTranslation);
    }

    getCurrentYear = () => {
        const d = new Date();
        return d.getFullYear();
    }

    getFooterElements = () => {
        if (isBrowser) {
            return (
                <div className="footer-wrapper">
                    <SocialIcons />

                    <p className="footer-paragraph footer-links blue-text">
                        <Link to="/about">
                            <Translate id="about" />
                        </Link>
                        &nbsp;&nbsp; | &nbsp;&nbsp;
                        <a href={`${getBaseName()}/waiver`} target="_blank" rel="noopener noreferrer">
                            <Translate id="waiver" />
                        </a>
                        &nbsp;&nbsp; | &nbsp;&nbsp;
                        <a href="https://youtu.be/CydlfstKmDw" target="_blank" rel="noopener noreferrer">
                            <Translate id="video" />
                        </a>
                        &nbsp;&nbsp; | &nbsp;&nbsp;
                        <a href={`${getBaseName()}/careers`} target="_blank" rel="noopener noreferrer">
                            <Translate id="careers" />
                        </a>
                        &nbsp;&nbsp; | &nbsp;&nbsp;
                        <Link to="/legal/terms-of-use">
                            <Translate id="terms-of-use" />
                        </Link>
                    </p>
                    <p className="footer-paragraph footer-copyright">© 2016-{this.getCurrentYear()} Ctrl V</p>
                    <LanguageToggle />
                </div>
            );
        } else {
            return (
                <div className="mobile-footer-padding">
                    <SocialIcons />

                    <div className="footer-paragraph footer-links blue-text">
                        <p>
                            <Link to="/about">
                                <Translate id="about" />
                            </Link>
                            &nbsp;&nbsp; | &nbsp;&nbsp;
                            <a href={`${getBaseName()}/waiver`} target="_blank" rel="noopener noreferrer">
                                <Translate id="waiver" />
                            </a>
                        </p>
                        <p>
                            <a href="https://youtu.be/CydlfstKmDw" target="_blank" rel="noopener noreferrer">
                                <Translate id="video" />
                            </a>
                        </p>
                        <a href={`${getBaseName()}/careers`} target="_blank" rel="noopener noreferrer">
                                <Translate id="careers" />
                        </a>
                        &nbsp;&nbsp; | &nbsp;&nbsp;
                        <p>
                            <Link to="/legal/terms-of-use">
                                <Translate id="terms-of-use" />
                            </Link>
                        </p>

                    </div>
                    <p className="footer-paragraph footer-copyright">© 2016-{this.getCurrentYear()} Ctrl V</p>
                    <LanguageToggle />
                </div>
            )
        }
    }

    render() {
        return this.getFooterElements();
    }
}

export default withLocalize(Footer);