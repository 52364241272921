import React, { Component } from 'react';
import './LightboxImage.css';

class LightboxImage extends Component {
    

    render = () => {
        if(this.props.images.length === 0 || this.props.selectedImage === undefined || this.props.display === "false") return null;

        return (
            <div className="lightbox-image-container" display={this.props.display}>
                <div className="lightbox-image-wrapper" onClick={this.props.outerClick}>
                    <img src={this.props.images[this.props.selectedImage]} className="lightbox-image" alt="Lightbox" />
                </div>
            </div>
        )
    }
}

export default LightboxImage;