import React, { Component } from 'react';

import {
    LOCAL_STORAGE,
    getLocalStorage,
    localStorageObjectExpired,
    getExtension
} from '../../../utilities/GlobalConsts'

import Icon from './Icon';
import './SocialIcons.css';
const facebook = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/social_icons/facebook_icon';
const twitter = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/social_icons/twitter_icon';
const instagram = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/social_icons/instagram_icon';
const youtube = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/social_icons/youtube_icon';
const twitch = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/social_icons/twitch_icon';
const linkedin = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/social_icons/linkedin_icon';
const tiktok = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/social_icons/tiktok_icon';

const DEFAULT_SOCIALS = {
    facebook_url: "https://www.facebook.com/CtrlVWaterloo/",
    twitter_url: "https://twitter.com/ctrlvcanada",
    instagram_url: "https://www.instagram.com/ctrlvcanada/",
    youtube_url: "https://www.youtube.com/channel/UCln8r7sEK68fLMBl5F6eOcQ",
    twitch_url: "https://www.twitch.tv/ctrlvca",
    linkedin_url: "https://ca.linkedin.com/company/ctrlvfranchising",
    tiktok_url: "https://www.tiktok.com/@ctrlvwaterloo"
};

class SocialIcons extends Component {

    constructor(props) {
        super(props);

        let locationDetails = getLocalStorage(LOCAL_STORAGE.LOCATION_DETAILS.KEY);

        let socialsToUpdate = {};
        console.debug("locationDetails", locationDetails)
        if(locationDetails && !localStorageObjectExpired(locationDetails)) {
            const socials = locationDetails.social_media;
            console.debug("socials", socials);
            if (socials.facebook_url)
                socialsToUpdate['facebook_url'] = socials.facebook_url
            if (socials.twitter_url)
                socialsToUpdate['twitter_url'] = socials.twitter_url
            if (socials.instagram_url)
                socialsToUpdate['instagram_url'] = socials.instagram_url
            if (socials.youtube_url)
                socialsToUpdate['youtube_url'] = socials.youtube_url
            if (socials.twitch_url)
                socialsToUpdate['twitch_url'] = socials.twitch_url
            if (socials.linkedin_url)
                socialsToUpdate['linkedin_url'] = socials.linkedin_url
            if (socials.tiktok_url)
                socialsToUpdate['tiktok_url'] = socials.tiktok_url
        }
        console.debug("socialsToUpdate", socialsToUpdate)
        this.state = {...DEFAULT_SOCIALS, ...socialsToUpdate}
        console.debug("this.state", this.state)
    }

    renderIcons = () => {

    }

    render() {
        return (
            <div className="social-icon-wrapper">

                { this.state.facebook_url !== undefined ?
                    <Icon link={this.state.facebook_url} image={facebook + "." + getExtension()} label="Facebook" />
                    : null
                }

                { this.state.instagram_url !== undefined ?
                    <Icon link={this.state.instagram_url} image={instagram + "." + getExtension()} label="Instagram" />
                    : null
                }

                { this.state.twitter_url !== undefined ?
                    <Icon link={this.state.twitter_url} image={twitter + "." + getExtension()} label="Twitter" />
                    : null
                }

                { this.state.youtube_url !== undefined ?
                    <Icon link={this.state.youtube_url} image={youtube + "." + getExtension()} label="YouTube" />
                    : null
                }

                <Icon link={this.state.twitch_url} image={twitch + "." + getExtension()} label="Twitch" />
                <Icon link={this.state.linkedin_url} image={linkedin + "." + getExtension()} label="LinkedIn" />
                <Icon link={this.state.tiktok_url} image={tiktok + "." + getExtension()} label="TikTok" />

            </div>
        );
    }

}

export default SocialIcons;
