import React from 'react';
import './FacilityHours.css';
import moment from 'moment';

const CLOSED_TEXT = "CLOSED ...BUT STILL AWESOME"

const OpHoursTable = props => { // simple component to display opHours
    const opHours = props.opHours; // array of {}
    const rows = opHours.map((dowHours, i) => {
        const dateText = dowHours.date ? ` (${dowHours.date})` : '';
        return (
            <p className="facility-hour-padding" key={i}><span style={{ color: '#0098da', fontWeight: "bold" }}>{dowHours.dow}{dateText}: </span>{dowHours.hours}</p>
        );
    });

    return (
        <div className="OpHoursTable">
            {rows}
        </div>
    );
}

const convertStartDateToOpenHours = (startTime) => {
    const returnVal = {
        dow: startTime.format('dddd'),
        start_hour: startTime.format('h:mma'),
        month: startTime.format('MMM'),
        yearNum: Number(startTime.format('YYYY')),
        monthNum: Number(startTime.format('MM')),
        dayNum: Number(startTime.format('DD')),

    }
    returnVal.date = `${returnVal.month} ${returnVal.dayNum}`;

    return returnVal;
}

export default class FacilityHours extends React.Component {
    state = { // initialize states
        opHours: []
    };

    updateOpHours = opHours => {

        let newOpHours = [];
        // update this.state for each dowHour that is different
        opHours.forEach((openHoursForDay) => {
            const startTime = moment(openHoursForDay.start_time_local).utc();
            const endTime = moment(openHoursForDay.end_time_local).utc();

            const mostDetails = convertStartDateToOpenHours(startTime);
            mostDetails.end_hour = endTime.format('h:mma');

            const lastOpenHourSoFar = newOpHours.length > 0 ? newOpHours[newOpHours.length - 1] : null;
            if (lastOpenHourSoFar != null && lastOpenHourSoFar !== undefined) {
                const diffDays = moment.duration(startTime.startOf('day').diff(lastOpenHourSoFar.raw_start_time.startOf('day'))).asDays();
                if (diffDays >= 2) {
                    for (let i = 1; i < diffDays; i++) {
                        const closedDay = lastOpenHourSoFar.raw_start_time.clone().add(i, 'days');
                        const mostClosedDetails = convertStartDateToOpenHours(closedDay);
                        newOpHours.push({
                            raw_start_time: closedDay,
                            ...mostClosedDetails,
                            hours: CLOSED_TEXT
                        })
                    }
                }
            }

            newOpHours.push({
                raw_start_time: startTime,
                ...mostDetails,
                hours: mostDetails.start_hour + ' - ' + mostDetails.end_hour,
            });
        });

        newOpHours = newOpHours.sort((a, b) => {
            if (a.yearNum !== b.yearNum) {
                return a.yearNum - b.yearNum;
            }

            if (a.monthNum !== b.monthNum) {
                return a.monthNum - b.monthNum;
            }

            return a.dayNum - b.dayNum;
        });
        console.debug(newOpHours);
        this.setState({ opHours: newOpHours });
    }

    collapseOpHours = () => {
        let currOpHours = this.state.opHours;

        let start = 0;
        let end = 0;

        for (let i = 0; i < currOpHours.length;) {
            if (currOpHours[i].hours === currOpHours[start].hours) { // i hours === start hours -> ++end
                end = i;
                ++i;
            }
            else if (currOpHours[i].hours !== currOpHours[start].hours) { // i hours !== start hours -> check if needa collaspse
                if (start !== end) { // collapse if period > 1 day
                    const dist = end - start;
                    const dowPeriod = currOpHours[start].dow.substring(0, 3) + ' - ' + currOpHours[end].dow.substring(0, 3);
                    const dowPeriodHours = currOpHours[start].hours;
                    const front = currOpHours.slice(0, start);
                    const back = currOpHours.slice(end + 1);
                    const mid = [{ dow: dowPeriod, hours: dowPeriodHours, date: `${currOpHours[start].date} - ${currOpHours[end].date}` }];
                    currOpHours = front.concat(mid);
                    currOpHours = currOpHours.concat(back);
                    start = i - dist;
                    end = i - dist;
                    i = i - dist;
                }
                else { // no need to collapse, reset i
                    start = i;
                    end = i;
                }
            }
        }
        if (start !== end) { // check end case
            const dowPeriod = currOpHours[start].dow.substring(0, 3) + ' - ' + currOpHours[end].dow.substring(0, 3);
            const dowPeriodHours = currOpHours[start].hours;
            const front = currOpHours.slice(0, start);
            const back = currOpHours.slice(end + 1);
            const mid = [{ dow: dowPeriod, hours: dowPeriodHours, date: `${currOpHours[start].date} - ${currOpHours[end].date}` }];
            currOpHours = front.concat(mid);
            currOpHours = currOpHours.concat(back);
        }
        this.setState({ opHours: currOpHours }); // update state at the end
    }

    componentDidMount() { // pull api data
        const location_id = this.props.location_id;

        const url = "https://api.ctrlv.ca/api/booking/getOperationHours/" + location_id;

        fetch(url)
            .then(res => res.json())
            .then(res => {
                if (res.result.response) {
                    const opHours = res.result.response;
                    this.updateOpHours(opHours);
                }
                this.collapseOpHours();
            });
    }

    render() {
        return ( // passing state.opHours as props
            <div id="FacilityHoursID" className='FacilityHours'>
                <OpHoursTable opHours={this.state.opHours} />
            </div>
        );
    }
}
