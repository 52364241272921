import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';
import { withRouter } from 'react-router-dom';

import { withLocalize, Translate } from 'react-localize-redux';
import FreeVrTranslation from '../../../translations/free_vr.json';

import { getBaseName } from '../../../utilities/GlobalConsts';

import '../GenericPage.css';

import ParallaxJs from '../../parallax_js/ParallaxJs.js';
import {Helmet} from 'react-helmet';

// parallax images
const background = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/1b5c49b6-quivrbackground";
const arrow = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/2be0c726-quivrarrow";
const dragon = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/086ea729-quivrcreature";
const snow = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/4575da13-quivrsnow";
const smoke = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/96c0dd61-quivrsmoke";
const logs = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/e9f0b9c5-quivrforeground";
const ryan = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/a6f9905b-quivrryan";

class FreeVR extends Component {
    
    constructor(props) {
        super(props);
        this.props.addTranslation(FreeVrTranslation);
    }

    parallaxObjects = [
        {
            image: background,
            class: "parallax-js-image",
            dataDepth: "0",
            loadDelay: -1
        },
        {
            image: dragon,
            class: "parallax-js-image",
            dataDepth: "0.06",
            loadDelay: 500
        },
        {
            image: ryan,
            class: "parallax-js-image",
            dataDepth: "0.02",
            loadDelay: 1000
        },
        {
            image: arrow,
            class: "parallax-js-image",
            dataDepth: "0.15",
            loadDelay: 1500
        },
        {
            image: snow,
            class: "parallax-js-image",
            dataDepth: "0",
            loadDelay: -1
        },
        {
            image: smoke,
            class: "parallax-js-image",
            dataDepth: "0",
            loadDelay: -1
        },
        {
            image: logs,
            class: "parallax-js-image",
            dataDepth: "0",
            loadDelay: -1
        }
    ];

    render() {
        return(
            <div className={isBrowser ? "generic-wrapper browser-wrapper" : "generic-wrapper"}>
                <Helmet>
                    <title>Free Virtual Reality | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="As a part of our mission to bring VR to the masses, Ctrl V offers free 10 minute demos on any weekday!"/>
                    <link rel="canonical" href={`${getBaseName()}/free-VR/`} />
                    <meta property="og:title" content="Free Virtual Reality | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="As a part of our mission to bring VR to the masses, Ctrl V offers free 10 minute demos on any weekday!" />
                    <meta property="og:url" content={`${getBaseName()}/free-VR/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="As a part of our mission to bring VR to the masses, Ctrl V offers free 10 minute demos on any weekday!" />
                    <meta name="twitter:title" content="Free Virtual Reality | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="free,virtual,reality,demo,vr" />
                </Helmet>

                <ParallaxJs objects={this.parallaxObjects} limitX={100} limitY={25} />

                <div className="generic-heading">
                    <Translate id="section-1.title" />
                </div>

                <div className="generic-section">
                    <Translate id="section-1.body.start" />
                    <span className="blue-text">
                        <Translate id="section-1.body.blue" />
                    </span>
                    <Translate id="section-1.body.end" />
                </div>
                <div className="generic-heading">
                    <Translate id="section-2.title" />
                </div>

                <div className="generic-section">
                    <Translate id="section-2.body-1.start" />
                    <span className="blue-text">
                        <Translate id="section-2.body-1.free-vr-day" />
                    </span>

                    <Translate id="section-2.body-1.options" />

                    <span className="blue-text">
                        <Translate id="section-2.body-1.free-10-minute" />
                    </span>
                    
                    <Translate id="section-2.body-1.ending" />
                </div>

                <div className="generic-section">
                    <Translate id="section-2.body-2.start" />
                    <span className="blue-text">
                        <Translate id="section-2.body-2.first-come" />
                    </span>
                    <Translate id="section-2.body-2.ending" />
                </div>

                <div className="alignment-wrapper">
                    <button className="generic-location-button add-pulse-animation" onClick={() => this.props.history.push("/locations")}>
                        <Translate id="section-2.location-button" />
                    </button>
                </div>

            </div>
        );
    }

}

export default withLocalize(withRouter(FreeVR));