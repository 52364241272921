import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';

import { withLocalize, Translate } from 'react-localize-redux';
import CookiesTranslation from '../../../../translations/cookies.json';

import { getBaseName } from '../../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

class Cookies extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(CookiesTranslation);
    }

    render() {
        return(
            <div className={isBrowser ? "generic-wrapper align-left blue-sub-headings browser-wrapper" : "generic-wrapper align-left blue-sub-headings"}>

                <Helmet>
                    <title>Cookie Policy | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Ctrl V uses cookies and is affiliated with third-party websites and Google products that also use cookies. This policy provides the specifics for our use."/>
                    <link rel="canonical" href={`${getBaseName()}/legal/cookies/`} />   
                    <meta property="og:title" content="Cookie Policy | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Ctrl V uses cookies and is affiliated with third-party websites and Google products that also use cookies. This policy provides the specifics for our use." />
                    <meta property="og:url" content={`${getBaseName()}/legal/cookies/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Ctrl V uses cookies and is affiliated with third-party websites and Google products that also use cookies. This policy provides the specifics for our use." />
                    <meta name="twitter:title" content="Cookie Policy | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="cookies" />
                </Helmet>

                <div className="generic-heading generic-heading-padding">
                    <Translate id="title" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="about.title" />
                </div>
                <div className="generic-section">
                    <Translate id="about.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="about.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="about.body-3" />
                </div>
                <div className="generic-section">
                    <Translate id="about.body-4" />
                </div>
                
                <div className="generic-sub-heading">
                    <Translate id="cookies-on-our-site.title" />
                </div>
                <div className="generic-section">
                    <Translate id="cookies-on-our-site.body-1" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="google-cookies.title" />
                </div>
                <div className="generic-section">
                    <Translate id="google-cookies.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="google-cookies.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="google-cookies.body-3" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="other-cookies.title" />
                </div>
                <div className="generic-section">
                    <Translate id="other-cookies.body-1" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="refusing-cookies.title" />
                </div>
                <div className="generic-section">
                    <Translate id="refusing-cookies.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="refusing-cookies.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="refusing-cookies.body-3" />
                </div>
                <div className="generic-section">
                    <Translate id="refusing-cookies.body-4" />
                </div>
            </div>
        );
    }
}

export default withLocalize(Cookies);