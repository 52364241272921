import React from 'react';
import './BookNowModal.css';
import {Translate} from 'react-localize-redux';

const bookNowModal = (props) =>{
    return (
        <div className="book-now-modal-wrapper" onClick={props.closeModal}>
            <div className="book-now-modal" >
                <button className="close-button" onClick={props.closeModal}>
                    X
                </button>

                <div className="book-now-modal-heading">
                    <Translate id="book-now-modal.title" />
                </div>
                <div className="book-now-modal-body align-center">
                    <Translate id="book-now-modal.body" />
                </div>
                <div className="align-center">
                    <button className="ctrlv-blue-button" onClick={props.optionOne}>
                        <Translate id="book-now-modal.book-here" /> {props.preferredLocation}
                    </button>
                    <button className="ctrlv-blue-button" onClick={props.optionTwo}>
                        <Translate id="book-now-modal.book-elsewhere" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default bookNowModal;