import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';
import { withRouter } from 'react-router-dom';

import { withLocalize, Translate } from 'react-localize-redux';
import SeniorsTranslation from '../../../translations/seniors.json';

import { getBaseName } from '../../../utilities/GlobalConsts';

import '../GenericPage.css';
import '../seniors/Seniors.css';

import ParallaxJs from '../../parallax_js/ParallaxJs.js';
import {Helmet} from 'react-helmet';
import DynamicImg from '../../dynamic_image/DynamicImg';

const trevrmask = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/TreVR%20COVID%20Mask";
// parallax images
const background = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/616c0d5b-space-background";
const mike = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Website_Assets_Mike_1";
const manta = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/0a1f8e53-spacemanta";
const portal = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/025eaacf-spaceportal";

class Seniors extends Component {
    
    constructor(props) {
        super(props);
        this.props.addTranslation(SeniorsTranslation);
    }

    parallaxObjects = [
        {
            image: background,
            class: "parallax-js-image",
            dataDepth: "0",
            loadDelay: -1
        },
        {
            image: portal,
            class: "parallax-js-image",
            dataDepth: "0.06",
            loadDelay: 500
        },
        {
            image: mike,
            class: "parallax-js-image",
            dataDepth: "0.02",
            loadDelay: 1000
        },
        {
            image: manta,
            class: "parallax-js-image",
            dataDepth: "0.15",
            loadDelay: 1500
        }
    ];

    render() {
        return(
            <div className={isBrowser ? "generic-wrapper browser-wrapper" : "generic-wrapper"}>
                <Helmet>
                    <title>Seniors | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="As a part of our mission to bring VR to the masses, Ctrl V offers seniors discounts and mobile events!"/>
                    <link rel="canonical" href={`${getBaseName()}/seniors/`} />
                    <meta property="og:title" content="Seniors | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="As a part of our mission to bring VR to the masses, Ctrl V offers seniors discounts and mobile events!" />
                    <meta property="og:url" content={`${getBaseName()}/seniors/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="As a part of our mission to bring VR to the masses, Ctrl V offers seniors discounts and mobile events!" />
                    <meta name="twitter:title" content="Seniors | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="senior,seniors,discounts,mobile,virtual,reality,vr" />
                </Helmet>

                <ParallaxJs objects={this.parallaxObjects} limitX={100} limitY={25} />

                <div>
                    <div className="seniors-generic-text">
                        <div className="align-center">
                            <Translate id="section-1.body.start" />
                        </div>
                    </div>
                    
                    <div className="seniors-generic-text">
                        <div className="align-center">
                            <Translate id="section-1.body.middle-1" />
                            <Translate id="section-1.body.middle-2" />
                        </div>
                    </div>

                    <div className="seniors-generic-text">
                        <div className="align-center">
                            <Translate id="section-1.body.end" />
                            <span className="seniors-blue-text">
                                <Translate id="section-1.body.blue" />
                            </span>
                        </div>
                    </div>
                    <div className="seniors-generic-text">
                        <div className="align-center">
                            <Translate id="section-2.body.start" />
                        </div>
                    </div>

                    <br/>
                </div>

                <div>
                    <div className="align-center">
                        <span className="seniors-heading blue-text">
                            <Translate id="section-2.body.middle" />
                        </span>
                    </div>
                    <div className="generic-text-2">
                        <div className="align-center">
                            <Translate id="section-2.body.end-1" />
                            <span className="seniors-blue-text">
                                <Translate id="section-2.body.blue" />
                            </span>
                            <Translate id="section-2.body.end-2" />
                        </div>
                    </div>

                    <br/>
                    <br/>

                    <div className="alignment-wrapper">
                        <button className="generic-location-button add-pulse-animation" onClick={() => this.props.history.push("/locations")}>
                            <Translate id="section-2.location-button" />
                        </button>
                    </div>
                </div>

                <div>
                    <div className="seniors-fine-print">
                        <div className="align-center">
                                <Translate id="section-2.body.fine-print-1" />
                        </div>
                    </div>
                    <div className="seniors-fine-print">
                        <div className="align-center">
                                <Translate id="section-2.body.fine-print-2" />
                        </div>
                    </div>
                </div>

                <br/>
                <hr/>
                <br/>
                
                <div>
                    <div className="generic-text-3">
                        <div>
                            <Translate id="section-3.body.start" />
                        </div>
                    </div>
                    <div className="generic-text-3">
                        <div>
                            <Translate id="section-3.body.middle" />
                        </div>
                    </div>
                    <br/>
                    <DynamicImg 
                        imageName={trevrmask}
                        fallbackExt='png'
                        alt="TreVR Mask Thumbs Up"
                        width="33%"
                    />
                    <div className="heading-2">
                        <div>
                            <span className="seniors-blue-text">
                                <Translate id="section-3.body.blue" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withLocalize(withRouter(Seniors));