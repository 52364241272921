import React, { Component } from 'react';
import './CoveragePreviewContainer.css';
import { isBrowser } from 'react-device-detect';

class CoveragePreviewContainer extends Component {
    
    render = () => {
        return (
            <React.Fragment>
                <div className="coverage-controls-wrapper">
                    <button 
                        onClick={this.props.previousPage} 
                        className="coverage-index-button"
                        disabled={this.props.curPage === 0}>Previous</button>
                    (Page {this.props.curPage + 1} / {this.props.maxPage})
                    <button 
                        onClick={this.props.nextPage} 
                        className="coverage-index-button"
                        disabled={this.props.curPage + 1 === this.props.maxPage}>Next</button>
                </div>

                <div className={ isBrowser ? "panel-wrapper" : null }>
                    {this.props.panels}
                </div>

            </React.Fragment>
        );
    }
}

export default CoveragePreviewContainer;