import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';

import { withLocalize, Translate } from 'react-localize-redux';
import VXR from '../../../translations/victoryxr.json';
import DynamicImg from '../../dynamic_image/DynamicImg';

import '../GenericPage.css';
import '../homeschool/Homeschool.css';

import { getExtension, getBaseName } from '../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

const xavrImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/xavr.png';
const ClassroomSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Virtual%20Classroom%20Screenshot';
const Classroom = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Virtual%20Classroom%20Background';
const VRobot = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/VRobot%20Education%20Background';
const VRobotSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/VRobot%20Education%20Screenshot';
const Universe = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Universe%20Sandbox%20Background';
const UniverseSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Universe%20Sandbox%20Screenshot';
const hsBackground = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Homeschool%20Header%20Background';
const hsHeader = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Homeschool%20Header%20Image';
const Nature = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Nature%20Treks%20VR%20Background';
const NatureSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Nature%20Treks%20VR%20Screenshot';
const Brush = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Tilt%20Brush%20Background';
const BrushSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Tilt%20Brush%20Screenshot';
const Anatomy = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Human%20Anatomy%20Experience%20Background';
const AnatomySS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Human%20Anatomy%20Experience%20Screenshot';
const Google = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Google%20Earth%20VR%20Background';
const GoogleSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Google%20Earth%20VR%20Screenshot';
const Victory = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Victory%20XR%20Background';
const Victory2 = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Victory%20XR%20Background%202'
const VictorySS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Victory%20XR%20Screenshot';

class VictoryXR extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(VXR);
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Victory XR | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="An extensive collection of educational VR experiences"/>
                    <link rel="canonical" href={`${getBaseName()}/victoryxr/`} />
                    <meta property="og:title" content="Victory XR | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="An extensive collection of educational VR experiences" />
                    <meta property="og:url" content={`${getBaseName()}/victoryxr/`}/>
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="An extensive collection of educational VR experiences" />
                    <meta name="twitter:title" content="Victory XR | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content={`victoryxr,education,learning,school,vr`}/>
                </Helmet>
                
                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${hsBackground}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className='hs-title'>
                        <Translate id="title.top" />
                    </div>
                    <div className='hs-title2'>
                        <Translate id="title.middle" />
                    </div>
                    <div className='top-image-section'>
                        <DynamicImg
                            imageName={Victory}
                            fallbackExt='png'
                            alt="Victory XR Screenshot"
                            width="100%"
                            height="auto"
                            imgClass="hs-image"
                        />
                    </div>
                </div>
                
                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Anatomy}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="hs-section">
                        <div className="hs-heading3">
                            <Translate id="elementary.heading" />
                        </div>
                    </div>
                    <div className="hs-section2">
                        <div className="hs-sub-section5">
                            <div className="hs-description3">
                                <ul>
                                    <li><Translate id="elementary.list.item-1" /></li>
                                    <li><Translate id="elementary.list.item-2" /></li>
                                    <li><Translate id="elementary.list.item-3" /></li>
                                    <li><Translate id="elementary.list.item-4" /></li>
                                    <li><Translate id="elementary.list.item-5" /></li>
                                </ul>
                            </div>
                        </div>
                        <div className="hs-sub-section5">
                            <div className="hs-description3">
                                <ul>
                                    <li><Translate id="elementary.list.item-6" /></li>
                                    <li><Translate id="elementary.list.item-7" /></li>
                                    <li><Translate id="elementary.list.item-8" /></li>
                                    <li><Translate id="elementary.list.item-9" /></li>
                                    <li><Translate id="elementary.list.item-10" /></li>
                                    <li><Translate id="elementary.list.item-11" /></li>
                                    <li><Translate id="elementary.list.item-12" /></li>
                                </ul>
                            </div>
                        </div>
                        <div className='hs-sub-section5'>
                            <div className="hs-description3">
                                <ul>
                                    <li><Translate id="elementary.list.item-13" /></li>
                                    <li><Translate id="elementary.list.item-14" /></li>
                                    <li><Translate id="elementary.list.item-15" /></li>
                                    <li><Translate id="elementary.list.item-16" /></li>
                                    <li><Translate id="elementary.list.item-17" /></li>
                                    <li><Translate id="elementary.list.item-18" /></li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="hs-sub-section5">
                            <div className="hs-description3">
                                <ul>
                                    <li><Translate id="elementary.list.item-19" /></li>
                                    <li><Translate id="elementary.list.item-20" /></li>
                                    <li><Translate id="elementary.list.item-21" /></li>
                                    <li><Translate id="elementary.list.item-22" /></li>
                                    <li><Translate id="elementary.list.item-23" /></li>
                                    <li><Translate id="elementary.list.item-24" /></li>
                                    <li><Translate id="elementary.list.item-25" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Classroom}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="hs-section">
                        <div className="hs-heading3">
                            <Translate id="high-school.heading" />
                        </div>
                    </div>
                    <div className="hs-section2">
                        <div className="hs-sub-section5">
                            <div className="hs-description3">
                                <ul>
                                    <li><Translate id="high-school.list.item-1" /></li>
                                    <li><Translate id="high-school.list.item-2" /></li>
                                    <li><Translate id="high-school.list.item-3" /></li>
                                    <li><Translate id="high-school.list.item-5" /></li>
                                    <li><Translate id="high-school.list.item-6" /></li>
                                    <li><Translate id="high-school.list.item-7" /></li>
                                </ul>
                            </div>
                        </div>
                        <div className="hs-sub-section5">
                            <div className="hs-description3">
                                <ul>
                                    <li><Translate id="high-school.list.item-4" /></li>
                                    <li><Translate id="high-school.list.item-9" /></li>
                                    <li><Translate id="high-school.list.item-10" /></li>
                                    <li><Translate id="high-school.list.item-11" /></li>
                                    <li><Translate id="high-school.list.item-12" /></li>
                                    <li><Translate id="high-school.list.item-14" /></li>
                                    <li><Translate id="high-school.list.item-15" /></li>
                                </ul>
                            </div>
                        </div>
                        <div className='hs-sub-section5'>
                            <div className="hs-description3">
                                <ul>
                                    <li><Translate id="high-school.list.item-16" /></li>
                                    <li><Translate id="high-school.list.item-17" /></li>
                                    <li><Translate id="high-school.list.item-18" /></li>
                                    <li><Translate id="high-school.list.item-19" /></li>
                                    <li><Translate id="high-school.list.item-20" /></li>
                                    <li><Translate id="high-school.list.item-21" /></li>
                                    <li><Translate id="high-school.list.item-22" /></li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="hs-sub-section5">
                            <div className="hs-description3">
                                <ul>
                                    <li><Translate id="high-school.list.item-23" /></li>
                                    <li><Translate id="high-school.list.item-24" /></li>
                                    <li><Translate id="high-school.list.item-25" /></li>
                                    <li><Translate id="high-school.list.item-26" /></li>
                                    <li><Translate id="high-school.list.item-27" /></li>
                                    <li><Translate id="high-school.list.item-28" /></li>
                                    <li><Translate id="high-school.list.item-29" /></li>
                                    <li><Translate id="high-school.list.item-30" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Victory2}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="hs-section">
                        <div className="hs-heading3">
                            <Translate id="dissections.heading" />
                        </div>
                    </div>
                    <div className="hs-section2">
                        <div className="hs-sub-section5">
                            <div className="hs-description3">
                                <ul>
                                    <li><Translate id="dissections.list.item-1" /></li>
                                    <li><Translate id="dissections.list.item-2" /></li>
                                </ul>
                            </div>
                        </div>
                        <div className="hs-sub-section5">
                            <div className="hs-description3">
                                <ul>
                                    <li><Translate id="dissections.list.item-3" /></li>
                                    <li><Translate id="dissections.list.item-4" /></li>
                                </ul>
                            </div>
                        </div>
                        <div className='hs-sub-section5'>
                            <div className="hs-description3">
                                <ul>
                                    <li><Translate id="dissections.list.item-6" /></li>                                 
                                </ul>
                            </div>
                        </div>
                        <div className="hs-sub-section5">
                            <div className="hs-description3">
                                <ul>
                                    <li><Translate id="dissections.list.item-7" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withLocalize(VictoryXR);