import React, { Component } from 'react';
import './ContestDropdown.css';

class ContestDropdown extends Component { 

    state = {
        showDropdown: false
    }

    toggleDropDown = () => {
        this.setState({
            showDropdown: !this.state.showDropdown
        });
    }

    render() {
        return (
            this.props.body ?
                <div className="contest-dropdown-wrapper">
                    <div className="contest-dropdown-heading" onClick={this.toggleDropDown}>
                        {this.state.showDropdown ? '- ' : '+ '}
                        
                        {this.props.title}
                    </div>

                    <div className={this.state.showDropdown ? "contest-dropdown-body show-dropdown" : "contest-dropdown-body hide-dropdown" }>
                        {this.props.body}
                    </div>
                </div>
            : <div> No Contests or Sweepstakes At This Time </div>
        );
    }
}

export default ContestDropdown;