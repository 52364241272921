import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';

import { withLocalize, Translate } from 'react-localize-redux';
import AntiSpamTranslation from '../../../../translations/anti_spam.json';

import { getBaseName } from '../../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

class AntiSpam extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(AntiSpamTranslation);
    }

    render() {
        return(
            <div className={isBrowser ? "generic-wrapper align-left blue-sub-headings browser-wrapper" : "generic-wrapper align-left blue-sub-headings"}>

                <Helmet>
                    <title>Anti-Spam Policy | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Our anti-spam policy is set forth to help protect visitors to Ctrl V, its website and facilities, as well as Ctrl V - Virtual Reality Arcade itself."/>
                    <link rel="canonical" href={`${getBaseName()}/legal/anti-spam/`} />       
                    <meta property="og:title" content="Anti-Spam Policy | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Our anti-spam policy is set forth to help protect visitors to Ctrl V, its website and facilities, as well as Ctrl V - Virtual Reality Arcade itself." />
                    <meta property="og:url" content={`${getBaseName()}/legal/anti-spam/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Our anti-spam policy is set forth to help protect visitors to Ctrl V, its website and facilities, as well as Ctrl V - Virtual Reality Arcade itself." />
                    <meta name="twitter:title" content="Anti-Spam Policy | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="anti-spam" />
                </Helmet>

                <div className="generic-heading generic-heading-padding">
                    <Translate id="title" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="what-is-spam.title" />
                </div>
                <div className="generic-section">
                    <Translate id="what-is-spam.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="what-is-spam.body-2" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="automated-filter.title" />
                </div>
                <div className="generic-section">
                    <Translate id="automated-filter.body-1" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="problems.title" />
                </div>
                <div className="generic-section">
                    <Translate id="problems.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="problems.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="problems.body-3" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="user-spam.title" />
                </div>
                <div className="generic-section">
                    <Translate id="user-spam.body-1" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="reciept.title" />
                </div>
                <div className="generic-section">
                    <Translate id="reciept.body-1" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="changes.title" />
                </div>
                <div className="generic-section">
                    <Translate id="changes.body-1" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="contact.title" />
                </div>
                <div className="generic-section">
                    <Translate id="contact.body-1" />
                </div>
                <div className="generic-section">
                    <span className="blue-text">
                        <a href="mailto: contact@ctrlv.ca">contact@ctrlv.ca</a>
                    </span>
                </div>
            </div>
        );
    }
}

export default withLocalize(AntiSpam);