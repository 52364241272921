import React, { Component } from 'react';

import { withLocalize, Translate } from 'react-localize-redux';
import BookNowPopupTranslations from '../../translations/modals.json';
import BookNowModal from '../book_now_modal/BookNowModal';

import './BookNowPopup.css';


import {
    LOCAL_STORAGE,
    getLocalStorage,
    getBaseName
} from '../../utilities/GlobalConsts';

class BookNowPopup extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(BookNowPopupTranslations);

        this.state = {
            location_id: '',
            location_name: '',
            showPopup:false
        }

        setInterval(this.getLocationData, 500);
        this.tryRedirect = this.tryRedirect.bind(this);
        this.locationMatches = this.locationMatches.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    getLocationData = () => {
        const locationDetails = getLocalStorage(LOCAL_STORAGE.LOCATION_DETAILS.KEY);

        if(locationDetails !== null && locationDetails.location_id !== this.state.location_id) {
            this.setState({
                location_id: locationDetails.location_id,
                display_name: locationDetails.display_name
            });
        }
        return false;
    }

    locationMatches = () =>{
        const url = window.location.href;
        const displayNameRe = new RegExp(`${this.state.display_name}`, 'i');
        if(url.match('location/[a-z]+.*') === null 
        || this.state.location_id === ""){ // If the user has no preferred location
            return true;
        }
        return displayNameRe.test(url.split('/location/')[1]);
    }

    handleCloseModal(){
        this.setState({
            showPopup:false
        })
    }

    tryRedirect(){
        if(!this.locationMatches()){
            this.setState({
                showPopup:true
            });
        }
        else{
            window.location = `${getBaseName()}/booking/location/${this.state.location_id}`;
        }
    }

    render() {
        //if(isBrowser) return null;

        return (
            <React.Fragment>
                {this.state.showPopup?(
                    <BookNowModal 
                    closeModal={this.handleCloseModal} 
                    optionOne={()=>{window.location = `${getBaseName()}/booking/location/${this.state.location_id}`}}
                    optionTwo={()=>{window.location = `${getBaseName()}/booking/`}}
                    preferredLocation={this.state.display_name}
                    />
                ):null}
                <div onClick={this.tryRedirect}>
                    <div className="book-now-popup">
                        <Translate id="book-now" />
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default withLocalize(BookNowPopup);