import React, { useState } from "react";
import "./AnniPopup.css";
import DynamicImg from "../dynamic_image/DynamicImg";

const AnniImg = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/trevr_crying";

function AnniPopup () {
    const [open, setOpen] = useState(true);
    
    function handleClick() {
          setOpen(!open);
    }

    return (open === true ? 
        <div className = "anniBackdrop">
            <div className = "anniPositionWrapper">
                <div className = "anniMain">
                    <span className = "anniText1">
                        We are temporarily closed as we relocate to serve our community better.
                    </span>
                    <a href = "https://www.ctrlvarcade.com/location/coral-springs#newsletter" className = "anniImage">
                        <DynamicImg  
                            imageName={AnniImg}
                            fallbackExt='jpg'
                            alt="Ctrl V fifth AnniVRsary Stream"
                            width="100%"
                            height="auto"
                        />
                    </a>
                    <span className = "anniText2">
                        <span className = "blueText">Sign up for our newsletter</span> to stay up-to-date on our Grand Reopening and new address!
                    </span>
                    <div className = "anniCloseButton" onClick = {handleClick}>X</div>
                </div>
            </div>
        </div> : null
    );  
}

export default AnniPopup;