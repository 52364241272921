import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import MobileNav from './mobile_nav/MobileNav';
import SideTray from './mobile_nav/side_tray/SideTray';
import DesktopNav from './desktop_nav/DesktopNav';

import './header.css';

import {
    LOCAL_STORAGE,
    localStorageObjectExpired,
    setLocalStorage,
    getLocalStorage
} from '../../utilities/GlobalConsts';
import DynamicImg from '../dynamic_image/DynamicImg';

class Header extends Component {

    shouldComponentUpdate(_, nextState) {
        if (nextState.locationDetails.webName !== this.state.locationDetails.webName) return true;
        else if (nextState.trayIsOpen !== this.state.trayIsOpen) return true;
        else if (nextState.freeVrSubMenuIsOpen !== this.state.freeVrSubMenuIsOpen) return true;
        else if (nextState.corporateSubMenuIsOpen !== this.state.corporateSubMenuIsOpen) return true;
        else if (nextState.legalSubMenuIsOpen !== this.state.legalSubMenuIsOpen) return true;
        else if (nextState.waiverVideoSubMenuIsOpen !== this.state.waiverVideoSubMenuIsOpen) return true;
        else if (nextState.myInfoSubMenuIsOpen !== this.state.myInfoSubMenuIsOpen) return true;
        else if (nextState.locationsSubMenuIsOpen !== this.state.locationsSubMenuIsOpen) return true;
        return false;
    }

    checkNumVisits = () => {
        const numVisits = getLocalStorage(LOCAL_STORAGE.NUMBER_OF_VISITS.KEY);

        let count;
        if (numVisits === null) {
            count = 1;

            setLocalStorage(LOCAL_STORAGE.NUMBER_OF_VISITS.KEY, LOCAL_STORAGE.NUMBER_OF_VISITS.EXPIRY_DURATION, {
                count: 1
            });
        } else if (localStorageObjectExpired(numVisits)) {
            count = numVisits.count + 1;

            setLocalStorage(LOCAL_STORAGE.NUMBER_OF_VISITS.KEY, LOCAL_STORAGE.NUMBER_OF_VISITS.EXPIRY_DURATION, {
                count: numVisits.count + 1
            });
        } else {
            count = numVisits.count;
        }

        return count
    }

    checkLocationSaved = () => {
        const location = getLocalStorage(LOCAL_STORAGE.LOCATION.KEY);
        const locationDetails = getLocalStorage(LOCAL_STORAGE.LOCATION_DETAILS.KEY);

        if (location !== null && locationDetails !== null) {
            return {
                displayName: locationDetails.display_name,
                webName: location.closestLocation,
                location_id: locationDetails.location_id
            }
        } else return false;
    }

    constructor() {
        super();

        this.state = {
            trayIsOpen: false,
            freeVrSubMenuIsOpen: false,
            corporateSubMenuIsOpen: false,
            legalSubMenuIsOpen: false,
            waiverVideoSubMenuIsOpen: false,
            myInfoSubMenuIsOpen: false,
            locationsSubMenuIsOpen: false,
            hideCorperate: (this.checkNumVisits() >= 3),
            locationDetails: this.checkLocationSaved()
        }
        setInterval(this.localStorageChecks, 500);
    }

    localStorageChecks = () => {
        this.setState({
            hideCorperate: (this.checkNumVisits() >= 3),
            locationDetails: this.checkLocationSaved()
        })
    }

    // toggles side tray menu visibility (for mobile)
    toggleSideTray = () => {
        this.setState({
            trayIsOpen: !this.state.trayIsOpen,
            freeVrSubMenuIsOpen: false,
            corporateSubMenuIsOpen: false,
            legalSubMenuIsOpen: false,
            waiverVideoSubMenuIsOpen: false,
            myInfoSubMenuIsOpen: false,
            locationsSubMenuIsOpen: false
        });
    }

    // closes side tray menu (for mobile)
    hideSideTray = () => {
        this.setState({
            trayIsOpen: false,
            freeVrSubMenuIsOpen: false,
            corporateSubMenuIsOpen: false,
            legalSubMenuIsOpen: false,
            waiverVideoSubMenuIsOpen: false,
            myInfoSubMenuIsOpen: false,
            locationsSubMenuIsOpen: false
        });
    }

    // toggles free vr submenu visibility (mobile)
    toggleFreeVrSubMenu = () => {
        this.setState({
            freeVrSubMenuIsOpen: !this.state.freeVrSubMenuIsOpen,
            corporateSubMenuIsOpen: false,
            legalSubMenuIsOpen: false,
            waiverVideoSubMenuIsOpen: false,
            myInfoSubMenuIsOpen: false,
            locationsSubMenuIsOpen: false
        });
    }

    // toggles corperate submenu visibility (mobile)
    togglecorporateSubMenu = () => {
        this.setState({
            corporateSubMenuIsOpen: !this.state.corporateSubMenuIsOpen,
            freeVrSubMenuIsOpen: false,
            legalSubMenuIsOpen: false,
            waiverVideoSubMenuIsOpen: false,
            myInfoSubMenuIsOpen: false,
            locationsSubMenuIsOpen: false
        });
    }

    // toggles legal submenu visibility submenu visibility (mobile)
    toggleLegalSubMenu = () => {
        this.setState({
            legalSubMenuIsOpen: !this.state.legalSubMenuIsOpen,
            freeVrSubMenuIsOpen: false,
            corporateSubMenuIsOpen: false,
            waiverVideoSubMenuIsOpen: false,
            myInfoSubMenuIsOpen: false,
            locationsSubMenuIsOpen: false
        });
    }

    // toggles waiver video submenu visibility submenu visibility (mobile)
    toggleWaiverVideoSubMenu = () => {
        this.setState({
            legalSubMenuIsOpen: false,
            freeVrSubMenuIsOpen: false,
            corporateSubMenuIsOpen: false,
            waiverVideoSubMenuIsOpen: !this.state.waiverVideoSubMenuIsOpen,
            myInfoSubMenuIsOpen: false,
            locationsSubMenuIsOpen: false
        });
    }

    // toggles my info submenu visibility submenu visibility (mobile)
    toggleMyInfoSubMenu = () => {
        this.setState({
            legalSubMenuIsOpen: false,
            freeVrSubMenuIsOpen: false,
            corporateSubMenuIsOpen: false,
            waiverVideoSubMenuIsOpen: false,
            myInfoSubMenuIsOpen: !this.state.myInfoSubMenuIsOpen,
            locationsSubMenuIsOpen: false
        });
    }

    // toggles locations submenu visibility submenu visibility (mobile)
    toggleLocationsSubMenu = () => {
        this.setState({
            legalSubMenuIsOpen: false,
            freeVrSubMenuIsOpen: false,
            corporateSubMenuIsOpen: false,
            waiverVideoSubMenuIsOpen: false,
            myInfoSubMenuIsOpen: false,
            locationsSubMenuIsOpen: !this.state.locationsSubMenuIsOpen
        });
    }

    render() {
        return (
            <React.Fragment>
                <nav className="main-header">
                    <Link to="/">
                        <DynamicImg
                            imageName="https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/7a06ab15-website-logo"
                            fallbackExt='png'
                            imgClass="logo"
                            alt="Glowing Station"
                            width="100%"
                            height="auto"
                        />
                    </Link>

                    <DesktopNav
                        hideCorperate={this.state.hideCorperate}
                        locationDetails={this.state.locationDetails} />
                    <MobileNav MenuClicked={this.toggleSideTray} />
                    <SideTray
                        linkClicked={this.hideSideTray}
                        trayWrapperClass={this.state.trayIsOpen ? "side-tray-wrapper open-side-tray" : "side-tray-wrapper closed-side-tray"}
                        freeVrSubMenuClass={this.state.freeVrSubMenuIsOpen ? "sub-menu freevr-side-tray-sub-item" : "sub-menu closed-side-tray-sub-item"}
                        corporateSubMenuIsOpen={this.state.corporateSubMenuIsOpen}
                        legalSubMenuClass={this.state.legalSubMenuIsOpen ? "sub-menu legal-side-tray-sub-item" : "sub-menu closed-side-tray-sub-item"}
                        waiverVideoSubMenuClass={this.state.waiverVideoSubMenuIsOpen ? "sub-menu waiver-video-side-tray-sub-item" : "sub-menu closed-side-tray-sub-item"}
                        myInfoSubMenuClass={this.state.myInfoSubMenuIsOpen ? "sub-menu my-info-side-tray-sub-item" : "sub-menu closed-side-tray-sub-item"}
                        locationsSubMenuClass={this.state.locationsSubMenuIsOpen ? "sub-menu locations-side-tray-sub-item" : "sub-menu closed-side-tray-sub-item"}

                        freeVrClicked={this.toggleFreeVrSubMenu}
                        corporateClicked={this.togglecorporateSubMenu}
                        legalClicked={this.toggleLegalSubMenu}
                        waiverVideoClicked={this.toggleWaiverVideoSubMenu}
                        myInfoClicked={this.toggleMyInfoSubMenu}
                        locationsClicked={this.toggleLocationsSubMenu}

                        hideCorperate={this.state.hideCorperate}
                        locationDetails={this.state.locationDetails} />
                </nav>
                
            </React.Fragment>
        );
    }
}

export default Header;