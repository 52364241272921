import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { getBaseName } from '../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

import './Show404.css';

class Show404 extends Component {

    state = {
        redirectRoute: undefined
    }

    redirectHome = () => {
        this.setState({
            redirectRoute: '/'
        });
    }

    render() {
        return(
            <div className="generic-wrapper">
                
                <Helmet>
                    <title>404 Page Not Found - Ctrl V | Virtual Reality Arcade</title>
                    <meta name="description" content="404 Page Not Found | Ctrl V - Virtual Reality Arcade"/>
                    <link rel="canonical" href={`${getBaseName()}/404/`}  />
                    <meta property="og:title" content="404 Page Not Found | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="404 Page Not Found | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:url" content={`${getBaseName()}/404/`}  />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="404 Page Not Found | Ctrl V - Virtual Reality Arcade" />
                    <meta name="twitter:title" content="404 Page Not Found | Ctrl V - Virtual Reality Arcade"/>
                </Helmet>

                {this.state.redirectRoute !== undefined ? 

                    <Redirect push to={this.state.redirectRoute} />
                
                    : null
                }

                <div className="show404-error-code">
                    404
                </div>

                <div className="show404-sub-heading">
                    Oops! That Page Could Not Found
                </div>

                <button className="generic-location-button" onClick={this.redirectHome}>Go Back Home</button>
            </div>
        );
    }

}

export default Show404;