import React, { Component } from 'react';

import { withLocalize, Translate } from 'react-localize-redux';
import DisclaimerTranslation from '../../../../translations/disclaimer.json';

import { isBrowser } from 'react-device-detect';

import { getBaseName } from '../../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

class Disclaimer extends Component {
    
    constructor(props) {
        super(props);

        this.props.addTranslation(DisclaimerTranslation);
    }

    render() {
        return(
            <div className={isBrowser ? "generic-wrapper align-left blue-sub-headings browser-wrapper" : "generic-wrapper align-left blue-sub-headings"}>

                <Helmet>
                    <title>View our Website Disclaimer | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Read our website disclaimer for Ctrl V - Virtual Reality Arcade in order to understand the limitations of liability and other exemptions."/>
                    <link rel="canonical" href={`${getBaseName()}/legal/disclaimer/`} />
                    <meta property="og:title" content="View our Website Disclaimer | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Read our website disclaimer for Ctrl V - Virtual Reality Arcade in order to understand the limitations of liability and other exemptions." />
                    <meta property="og:url" content={`${getBaseName()}/legal/disclaimer/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Read our website disclaimer for Ctrl V - Virtual Reality Arcade in order to understand the limitations of liability and other exemptions." />
                    <meta name="twitter:title" content="View our Website Disclaimer | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="disclaimer" />
                </Helmet>

                <div className="generic-heading generic-heading-padding">
                    <Translate id="title" />
                </div>
                <div className="generic-sub-heading">
                    <Translate id="no-warranties.title" />
                </div>
                <div className="generic-section">
                    <Translate id="no-warranties.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="no-warranties.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="no-warranties.body-3" />
                </div>
                <div className="generic-section">
                    <Translate id="no-warranties.body-4" />
                </div>
                <div className="generic-section">
                    <Translate id="no-warranties.body-5" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="limitations.title" />
                </div>
                <div className="generic-section">
                    <Translate id="limitations.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="limitations.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="limitations.body-3" />
                </div>
                <div className="generic-section">
                    <Translate id="limitations.body-4" />
                </div>
                <div className="generic-section">
                    <Translate id="limitations.body-5" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="exceptions.title" />
                </div>
                <div className="generic-section">
                    <Translate id="exceptions.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="exceptions.body-2" />
                </div>
                <div className="generic-section">
                    <Translate id="exceptions.body-3" />
                </div>
                <div className="generic-section">
                    <Translate id="exceptions.body-4" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="reasonableness.title" />
                </div>
                <div className="generic-section">
                    <Translate id="reasonableness.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="reasonableness.body-2" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="other-parties.title" />
                </div>
                <div className="generic-section">
                    <Translate id="other-parties.body-1" />
                </div>
                <div className="generic-section">
                    <Translate id="other-parties.body-2" />
                </div>

                <div className="generic-sub-heading">
                    <Translate id="unenforceable-provisions.title" />
                </div>
                <div className="generic-section">
                    <Translate id="unenforceable-provisions.body-1" />
                </div>
            </div>
        );
    }
}

export default withLocalize(Disclaimer);