import React, { Component } from 'react';
import './CookiesPopup.css';

import { Link } from 'react-router-dom';
import { withLocalize, Translate } from 'react-localize-redux';
import CookiePopupTranslations from '../../translations/modals.json';

class CookiesPopup extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(CookiePopupTranslations);

        this.state = {
            showCookie: false
        }
    }

    closeCookiePopup = () => {
        // save to not show cookie popup again. if it changes, just add a script to clear localstorage to reshow it
        window.localStorage.setItem("closed-cookie", true);

        this.setState({
            showCookie: false
        });
    }

    componentDidMount = () => {
        // make sure it hasnt been shown before
        let show = window.localStorage.getItem("closed-cookie");
        
        this.setState({
            showCookie: (show === null)
        });
    }

    render() {
        // dont show cookie popup if its shown before
        if(!this.state.showCookie) return null;

        return (
            <div className="cookie-popup-wrapper">
                <div className="cookie-popup">
                    <div className="cookie-text">
                        <Translate id="cookies-footer.before" />
                        {window.location.hostname}
                        <Translate id="cookies-footer.after" />
                        <span className="blue-text">
                            <Link to="/legal/cookies">
                                <Translate id="cookies-footer.link" />
                            </Link>
                        </span>
                    </div>

                    <Link to="/legal/cookies">
                        <button className="generic-location-button"><Translate id="cookies-footer.learn-more-button" /></button>
                    </Link>
                    <button className="generic-location-button" onClick={this.closeCookiePopup}><Translate id="cookies-footer.close-button" /></button>
                </div>
            </div>
        )
    }
}

export default withLocalize(CookiesPopup);