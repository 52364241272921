import React, { Component } from 'react';
import Parallax from 'parallax-js';

import {
    tryAddImageExtension
} from '../../utilities/GlobalConsts';

class ParallaxJs extends Component {

    constructor(props) {
        super(props);

        let classes = [];
        // eslint-disable-next-line
        for(const key in this.props.objects) {
            if(this.props.objects[key].loadDelay === -1) classes[key] = " show-parallax-img";
            else classes[key] = " hide-parallax-img";
        }
        
        this.state = {
            classes: classes
        }
    }

    delayImgAnimation = () => {
        this.timeoutObjects = [];

        // eslint-disable-next-line
        for(const key in this.props.objects) {
            this.timeoutObjects.push(setTimeout(() => {
                let classes = {...this.state.classes};

                classes[key] = " show-parallax-img"

                this.setState({
                    classes: classes
                });
            }, this.props.objects[key].loadDelay));
        }
    }

    // parallax library requires dom to be renderd, so keep trying until it works
    initParallaxLib = () => {
        let scene = document.getElementById('scene');

        if(scene) {
            new Parallax(scene, {
                relativeInput: true,
                limitX: this.props.limitX,
                limitY: this.props.limitY
            });

            this.delayImgAnimation()
        } else {
            setTimeout(this.initParallaxLib, 50);
        }
    }

    getImageUrl = (img) => {
        if(this.props.notWebp) {
            return img;
        } else {
            return tryAddImageExtension(img);
        }
    }
    
    getParallaxImages = () => {
        let imgs = [];
        // eslint-disable-next-line
        for(const key in this.props.objects) {
            imgs.push(
                <img 
                    key={key}
                    data-depth={this.props.objects[key].dataDepth} 
                    src={this.getImageUrl(this.props.objects[key].image)} 
                    className={this.props.objects[key].class + this.state.classes[key]}
                    alt="Parallax"
                />
            );
        }

        return (
            <div data-relative-input="true" id="scene">
                {imgs}
            </div>  
        );
    }

    componentWillUnmount = () => {
        // eslint-disable-next-line
        for(const key in this.timeoutObjects) {
            clearTimeout(this.timeoutObjects[key])
        }
    }

    componentDidMount = () => {
        this.initParallaxLib()
    }

    render() {
        return this.getParallaxImages();
    }
}

export default ParallaxJs;