import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';

import { withLocalize, Translate } from 'react-localize-redux';
import ReferralTranslations from '../../../translations/referral.json';

import '../GenericPage.css';
import './Referral.css';

import ParallaxJs from '../../parallax_js/ParallaxJs.js';

import { getExtension, getBaseName } from '../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

const text = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/refferal_overlay';
const background = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/26b678c1-layer-slider-ncr-background";
const trevr = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/84f0b150-layer-slider-ncr-trevr";
const xavr = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/b2280b57-layer-slider-ncr-xavr";
const extraBoyImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/9d75bf6d-extra-life-page-back-boy-1024x576';

class Referral extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(ReferralTranslations);
    }

    parallaxObjects = [
        {
            image: background,
            class: "parallax-js-image",
            dataDepth: "0",
            loadDelay: -1
        },
        {
            image: text,
            class: "text-overlay-parallax ",
            dataDepth: "0.001",
            loadDelay: 600
        },
        {
            image: trevr,
            class: "trevr-robit-parallax",
            dataDepth: "1",
            loadDelay: 1000
        },
        {
            image: xavr,
            class: "green-robit-parallax",
            dataDepth: "0.02",
            loadDelay: 500
        }
    ];

    render() {

        return(
            <div className="generic-wrapper-no-padding">

                <Helmet>
                    <title>New Customer Referral | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Earn loyalty points for each station in your booking with a first-time customer!"/>
                    <link rel="canonical" href={`${getBaseName()}/referral/`} />
                    <meta property="og:title" content="New Customer Referral | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Earn loyalty points for each station in your booking with a first-time customer!" />
                    <meta property="og:url" content={`${getBaseName()}/referral/`}/>
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Earn loyalty points for each station in your booking with a first-time customer!" />
                    <meta name="twitter:title" content="New Customer Referral | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="referral,program,bonus,free,loyalty"/>
                </Helmet>

                <div className={ isBrowser ? "browser-wrapper" : "mobile-wrapper" }> 

                    <ParallaxJs objects={this.parallaxObjects} limitX={0} limitY={100} />

                    <div className="generic-section">
                        <div className="align-center">
                            <Translate id="love-playing" />
                        </div>
                    </div>

                    <div className="generic-section">
                        <div className="align-center">
                            <Translate id="exploiting-friends" />
                        </div>
                    </div>

                    <div className="generic-section">
                        <div className="align-center">
                            <Translate id="do-both.start" />
                            <span className="blue-text">
                                <Translate id="do-both.blue" />
                            </span>
                            <Translate id="do-both.end" />
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${extraBoyImg}.${getExtension()})`}}>
                    <div className={ isBrowser ? "browser-wrapper" : "mobile-wrapper" }> 

                        <div className="generic-heading">
                            <Translate id="title" />
                        </div>

                        <div className="generic-section">
                            <Translate id="introduce.start" />
                            <span className="blue-text">
                                <Translate id="introduce.blue" />
                            </span>
                            <Translate id="introduce.end" />
                        </div>

                        <div className="generic-section">
                            <Translate id="earn-money.start" />
                            <span className="blue-text">
                                <Translate id="earn-money.blue" />
                            </span>
                            <Translate id="earn-money.end" />              
                        </div>

                        <div className="generic-section">
                            <Translate id="steps.step-1" />
                        </div>

                        <div className="generic-section">
                            <Translate id="steps.step-2" />
                        </div>

                        <div className="generic-section"> 
                            <Translate id="steps.conclusion.start" />
                            <span className="blue-text">
                                <Translate id="steps.conclusion.end" />
                            </span>
                        </div>

                        <div className="generic-footnote">
                            <Translate id="footnotes.footnote-1" />
                        </div>
                        <div className="generic-footnote">
                            <Translate id="footnotes.footnote-2" />
                        </div>
                        <div className="generic-footnote">
                            <Translate id="footnotes.footnote-3" />
                        </div>

                        <div className="alignment-wrapper">
                            <a href={`${getBaseName()}/booking/location/`}>
                                <button className={ isBrowser ? "ctrlv-blue-button-browser add-pulse-animation" : "ctrlv-blue-button add-pulse-animation" }>
                                    <Translate id="book-now-button" />
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withLocalize(Referral);