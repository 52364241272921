import React, { Component } from 'react';
import axios from 'axios';
import { isBrowser } from 'react-device-detect';
import { WEBCACHE_API as Api, getBaseName } from '../../../utilities/GlobalConsts';

import './Pressroom.css';
import {Helmet} from 'react-helmet';

class PressroomPost extends Component {

    state = {};

    getPost = () => {
        
        let postId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

        axios.get(Api.GET_POST + postId)
            .then((response) => {
                this.setState({
                    title: response['data']['obj'].title,
                    author: response['data']['obj'].author,
                    image: response['data']['obj'].preview_image,
                    content: response['data']['obj'].content
                })
            }).catch((error) => {
                console.error(error);
            });
    }

    componentDidMount = () => {
        this.getPost();
    }

    render() {
        
        return (
            <div className={ isBrowser ? "generic-wrapper browser-wrapper" : "generic-wrapper" }>

                <Helmet>
                    <title>{this.state.title} | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Directory and resources for news, press coverage, graphics, and press materials pertaining to Ctrl V - Virtual Reality Arcade"/>

                    <link rel="canonical" href={`${getBaseName()}/pressroom/${this.props.id}`} />
                    <meta property="og:title" content={this.state.title + " | Ctrl V - Virtual Reality Arcade"} />
                    <meta property="og:url" content={`${getBaseName()}/pressroom/${this.props.id}`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:title" content={this.state.title + " | Ctrl V - Virtual Reality Arcade"} />
                    <meta name="keywords" content="press,media,articles,news"/>
                </Helmet>

                <div className="generic-heading">
                    {this.state.title} {this.props.id}
                </div>

                <img src={this.state.image} alt="pressroom" className="generic-image" />

                <div className="generic-sub-heading">
                    Author: {this.state.author}
                </div>
                <div className="pressroom-post generic-section" dangerouslySetInnerHTML={{__html: this.state.content}} />
            </div>
        );
    }
}

export default PressroomPost;