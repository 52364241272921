import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';
import '../GenericPage.css';
import './GiftCards.css';

import { withLocalize, Translate } from 'react-localize-redux';
import GiftCardTranslation from '../../../translations/gift_cards.json';
import Axios from 'axios';

import {
    LOCAL_STORAGE,
    getLocalStorage,
    baseName,
    getBaseName,
    ctrlVAPI
} from '../../../utilities/GlobalConsts';
import { ReCaptcha } from 'react-recaptcha-v3';
import DynamicImg from '../../dynamic_image/DynamicImg';
import {Helmet} from 'react-helmet';

const GiftCardImage = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/gift_card';

class GiftCards extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(GiftCardTranslation);

        this.state = {
            locationDetails: false,
            totalVCards: 400000,
            captchaCompleted: false,
            captchaLoaded: false,
            recaptchaToken: "",
            recaptchaVersion: "BalanceCheck",
            cardNumber: '',
            amount: -1
        }
    }

    reloadCaptcha = () => {
        this.recaptcha.execute();
        this.setState({ captchaLoaded: false });
        this.forceUpdate();
    }

    onCaptchaExpired = () => {
        this.expireCaptcha();
    }

    expireCaptcha = () => {
        this.setState({ captchaCompleted: false });
    }

    updateReCaptcha = (recaptchaToken) => {
        this.setState({
            recaptchaToken: recaptchaToken,
        })
    }

    onCaptchaResponse = (response) => {
        //captcha completed, we may proceed to use it here on
        this.completeCaptcha(response);
    }

    completeCaptcha = (response) => {
        //set any states and run any functions with the completed captcha
        this.setState({
            captchaCompleted: !(response === '' || response === undefined),
        });
        this.updateReCaptcha(response);
    }

    // catch-all binding event for inputs. get key from dom, and save in state
    inputChanged = (event) => {
        // get state key from dom element
        let key = event.target.getAttribute('statekey');
        // get state value from dom element
        let value = event.target.value;

        let newState = {...this.state};
        newState[key] = value;
        this.setState(newState);
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            this.submitCardNumber();
        }
    }

    isValidForm = () => {
        return this.state.cardNumber !== ''
            && (this.state.cardNumber.length === 14
                || this.state.cardNumber.length === 16
                || this.state.cardNumber.length === 20);
    }

    getSubmitButtonClasses = () => {
        let classes = "news-letter-button ";

        if(isBrowser) classes += "card-button-browser-font ";
        else classes += "card-button-mobile-font ";

        if(this.isValidForm()) classes += "enabled-letter-button"
        else classes += "disabled-letter-button"

        return classes;
    }

    submitCardNumber = async () => {
        if(!this.isValidForm()) return;
        let apiData = {
            recaptcha: this.state.recaptchaToken,
            recaptcha_version: this.state.recaptchaVersion,
            card_number: this.state.cardNumber.toUpperCase()
        }
        try {
            let result = await Axios.post(ctrlVAPI + "/api/booking/simpleCheckGiftCardBalance", apiData);
            if (result.data.success) {
                let amount = result.data.result.balance_remaining;
                if(!result.data.result.has_record) {
                    amount = null;
                } else if (!result.data.result.is_active) {
                    amount = 0;
                }
                this.setState({
                    amount: amount
                });
            }
        } catch (err) {
            this.setState({
                amount: -1
            });
            console.error(err);
        }
        this.reloadCaptcha();
    }

    checkLocationSaved = () => {
        const location = getLocalStorage(LOCAL_STORAGE.LOCATION.KEY);
        const locationDetails = getLocalStorage(LOCAL_STORAGE.LOCATION_DETAILS.KEY);

        if (location !== null && locationDetails !== null) {
            return {
                displayName: locationDetails.display_name,
                webName: location.closestLocation
            }
        } else return false;
    }

    componentDidMount = async () => {
        let url = ''
        if(baseName === 'https://staging.api.ctrlv.ca/website-cache'){
            url = 'https://staging.api.ctrlv.ca/'
        } else {
            url = 'https://api.ctrlv.ca/'
        }
        const giftCardTotal = await Axios.get(`${url}api/booking/getNonPointCardCount`);

        this.setState({
            locationDetails: this.checkLocationSaved(),
            totalVCards: parseInt(giftCardTotal.data.result.count)
        })
    }

    render() {

        const amount = this.state.amount;
        let replyMessage = null;

        if(amount === null) {
            replyMessage = <div className="align-center card-message-font"> <Translate id="message.card-not-found" /> <br></br><Translate id="message.check-again" /></div>
        } else if(amount < 0) {
            replyMessage = null;
        } else if (amount >= 0) {
            replyMessage = <div className="align-center card-message-font">
                <React.Fragment><Translate id="message.card-balance" /><span className="blue-text">${amount.toFixed(2).toString()}</span></React.Fragment>
            </div>
        }

        return (
            <div className={isBrowser ? "generic-wrapper browser-wrapper" : "generic-wrapper"}>
                <Helmet>
                    <title>Gift Cards | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Get your V Card now! Ctrl V has gift cards available for purchase in-store. They are redeemable at any location, so grab one now!" />
                    <link rel="canonical" href={`${getBaseName()}/gift-cards/`} />
                    <meta property="og:title" content="Gift Cards | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Get your V Card now! Ctrl V has gift cards available for purchase in-store. They are redeemable at any location, so grab one now!" />
                    <meta property="og:url" content={`${getBaseName()}/gift-cards/`}/>
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Get your V Card now! Ctrl V has gift cards available for purchase in-store. They are redeemable at any location, so grab one now!" />
                    <meta name="twitter:title" content="Gift Cards | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="gift,cards,e-card,digital,buy,purchase" />
                </Helmet>

                <div className="generic-heading generic-heading-padding">
                    <span style={{ fontSize: "50px" }} className="blue-text">
                        <Translate id="v-card" />s
                    </span>
                    <span style={{ fontSize: "50px" }}>
                        <Translate id="title" />
                    </span>
                </div>

                <div className="generic-section">
                    <div className="align-center">
                        <span style={{ fontSize: "30px" }}>
                            <Translate id="subtitle" />
                        </span>
                    </div>
                </div>

                <DynamicImg
                    imageName={GiftCardImage}
                    fallbackExt='jpg'
                    imgClass="generic-image"
                    alt="Gift Cards"
                    width="50%"
                    height="auto"
                />

                <div className="generic-section">
                    <div className="align-center">
                        <span style={{ fontSize: "25px" }}>
                            <Translate id="body.section-1.stat" />

                            {this.state.totalVCards}{" "}
                        </span>

                        <span className="blue-text" style={{ fontSize: "25px" }}>
                            <Translate id="v-card" />s
                        </span>

                        <span style={{ fontSize: "25px" }}>
                            <Translate id="body.section-1.yours-too" />
                        </span>
                    </div>
                </div>

                <div className="generic-section">
                    <Translate id="body.section-2.great-for" />

                    <span className="blue-text">
                        <Translate id="body.section-2.events" />
                    </span>

                    <Translate id="body.section-2.and-moments" />
                </div>
                <div className="generic-section">
                    <Translate id="body.section-3.simply" />
                    <Translate id="body.section-3.location" />
                    <Translate id="body.section-3.friends-family" />
                </div>

                <div className="generic-section">
                    <div className="align-center">
                        <span className="blue-text"><Translate id="sub-title-2.exist" /></span>
                    </div>
                </div>
                <div className="align-center">
                    <div className="card-wrapper">
                        <Translate>
                            {({ translate }) => <input
                                aria-label="Card Number"
                                type="text"
                                value={this.state.cardNumber}
                                statekey="cardNumber"
                                onKeyPress={this.handleKeyPress}
                                onChange={this.inputChanged}
                                placeholder={translate('sub-title-2.card')}
                            />}
                        </Translate>
                    </div>
                    {replyMessage}
                    <button className={ this.getSubmitButtonClasses() } onClick={this.submitCardNumber}>
                        <Translate id="sub-title-2.submit" />
                    </button>
                </div>
                <div className="align-center">
                    <Translate id="sub-title-2.after" />
                </div>

                <a href={`${getBaseName()}/buy-gift-cards/`}>
                    <button className="generic-location-button add-pulse-animation">
                        <Translate id="online-purchase" />
                    </button>
                </a>

                <div className="generic-section">
                    <div className="align-center">
                        <Translate id="offline-purchase" />
                    </div>
                </div>

                <ReCaptcha
                    sitekey='6LchmVwaAAAAAJMmwe8VbW-pCmj0ou2ztqTgqorL'
                    action='gift_card_page'
                    verifyCallback={this.onCaptchaResponse}
                    ref={ref => this.recaptcha = ref}
                    expiredCallback={this.onCaptchaExpired} // this isn't a param according to documentation, but might be implemented
                />
            </div>
        );
    }
}

export default withLocalize(GiftCards);
