import React, { Component } from 'react';

import { withLocalize, Translate } from 'react-localize-redux';
import WaiverTranslation from '../../../translations/waiver.json';

import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import '../GenericPage.css';

import { getBaseName } from '../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

class WaiverAndVideo extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(WaiverTranslation);
    }

    openWaiver = () => {
        window.open(`${getBaseName()}/waiver/`);
    }

    render() {

        return (
            <div className={isBrowser ? "generic-wrapper browser-wrapper" : "generic-wrapper"}>

                <Helmet>
                    <title>Online Waiver | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="An online waiver for all attendees who intend on playing virtual reality at Ctrl V accompanied by the explainer video shown prior to every session."/>
                    <link rel="canonical" href={`${getBaseName()}/waiver/`} />
                    <meta property="og:title" content="Online Waiver | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="An online waiver for all attendees who intend on playing virtual reality at Ctrl V accompanied by the explainer video shown prior to every session." />
                    <meta property="og:url" content={`${getBaseName()}/waiver/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="An online waiver for all attendees who intend on playing virtual reality at Ctrl V accompanied by the explainer video shown prior to every session." />
                    <meta name="twitter:title" content="Online Waiver | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="online,legal,waiver,liability,sign"/>
                </Helmet>

                <div className="generic-heading generic-heading-padding">
                    <Translate id="title.before" />
                    <span className="blue-text">
                        <Translate id="title.unique" />
                    </span>
                    <Translate id="title.required" />
                </div>

                <div className="generic-section">
                    <span className="blue-text">
                        <Translate id="sub-title.parents" />    
                    </span> 
                    <Translate id="sub-title.please-add" />
                </div>

                <MobileView>
                    <button className="generic-location-button" onClick={this.openWaiver}>
                        <Translate id="waiver-button" />
                    </button>
                </MobileView>

                <BrowserView>
                    <iframe title="Waiver" src={`${getBaseName()}/waiver/`} width="100%" height="600" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>
                </BrowserView>
            </div>
        );
    }


}

export default withLocalize(WaiverAndVideo);