
// TODO: add production support
 //const baseName = 'https://api.ctrlv.ca/website-cache';
export const baseName = process.env.REACT_APP_API_URL;
export const ctrlVAPI = ((process.env.REACT_APP_API_URL==="https://api.ctrlv.ca/website-cache") ? "https://api.ctrlv.ca" : "https://staging.api.ctrlv.ca")

// pin to display on locations in location map
export const CTRL_V_MAP_PIN = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/8362584c-1bcc6889-ctrl-v-icon-v3.png";

//TODO: refactor into object and use a basename const
export const GET_LOCATIONS_FOR_WEBSITE = 'https://api.ctrlv.ca/api/corp/getLocationsForWebsite';
export const GET_LOCATION_DETAILS_FOR_WEBSITE = "https://api.ctrlv.ca/api/corp/getLocationDetailsForWebsite/"
export const GET_PUBLIC_GAMES_FROM_LOCATION = "https://api.ctrlv.ca/api/corp/getPublicGamesFromLocation/";

// google maps API key. this is public so its okay in plaintext
export const GOOGLE_MAP_API_KEY = 'AIzaSyCpff4dLEOu8Mbgnb0SqwM4xeNbUcvVdv8';

// constants for local storage use
export const LOCAL_STORAGE = {
    NO_LOCATION: {
        KEY: 'no_location',
        EXPIRY_DURATION: 5 * 24 * 60 * 60 * 1000
    },
    LAST_REDIRECT: {
        KEY: 'last_redirect',
        EXPIRY_DURATION: 6 * 60 * 60 * 1000
    },
    LOCATION: {
        KEY: 'location',
        EXPIRY_DURATION: 30 * 24 * 60 * 60 * 1000
    },
    LOCATION_DETAILS: {
        KEY: 'location_details',
        EXPIRY_DURATION: 30 * 24 * 60 * 60 * 1000
    },
    NUMBER_OF_VISITS: {
        KEY: 'number_of_visits',
        EXPIRY_DURATION: 12 * 60 * 60 * 1000
    },
    UNSUPPORTED_BROWSER_CHECK: {
        KEY: 'unsupported_browser',
        EXPIRY_DURATION: 3 * 60 * 60 * 1000
    }
}


// all webcache endpoints that are public
export const WEBCACHE_API = {
    GET_NEWS_PAGE_COUNT: baseName + '/posts/getPostPageCount/news/',
    GET_NEWS_PAGE: baseName + '/posts/getPostPage/news/',

    GET_PRESS_PAGE_COUNT: baseName + '/posts/getPostPageCount/press',
    GET_PRESS_PAGE: baseName + '/posts/getPostPage/press/',

    GET_LOCATION_BANNERS: baseName + '/locations/getLocationBanner',

    GET_POST: baseName + '/posts/getPost/',

    GET_VIDEOS: baseName + '/videos/getNonDraftVideos/',

    GET_GAME_BY_URL: baseName + '/games/getGameByUrl/',
    GET_FEATURED_GAMES: baseName + '/games/getFeaturedGames',
    
    GET_FAKE_REVIEWS: baseName + "/reviews/getFakeReviews",
    
    GET_SEARCH_RESULTS: baseName + "/search/getSearchResults",

    GET_LOCATION_EVENTDATA: baseName + "/locations/getWebsiteDetails/",

    GET_LOCATION_DISCOUNTS: baseName + "/locations/getLocationDiscounts/",

    GET_LOCATION_BOTTOM_ALERTS: baseName + "/bottom-alert/getBottomAlert"
}

// checks if local storage set with setLocalStorage has expired
export const localStorageObjectExpired = (localStorageObj) => {
    let timeInMs = new Date(localStorageObj.timestamp).getTime();
    let duration = localStorageObj.expiry;

    return timeInMs + duration <= new Date().getTime();
}

// sets local storage item with expiry
// note: the expiry does NOT automatically delete. after retrieving, you need to check if its expired (with localStorageObjectExpired)
export const setLocalStorage = (key, expiry, value = null) => {
    window.localStorage.setItem(key, JSON.stringify({
        expiry: expiry,
        timestamp: new Date(),
        ...value
    }));
}

// gets and parses json object from local storage
// note: returns null if the value is not set.
export const getLocalStorage = (key) => {
    return JSON.parse(window.localStorage.getItem(key));
}

// used to determine which image type to load (thanks safari)
export const getExtension = () => {
    var ua = navigator.userAgent.toLowerCase(); 
    let isSafari = false;
    if (ua.indexOf('safari') !== -1) { 
        if (ua.indexOf('chrome') > -1) {
            isSafari = false;
    } else {
            isSafari = true;
        }
    }

    if(isSafari) return 'png'
    else return 'webp'
}
export const tryAddImageExtension = (path) =>{
    if(!path) return path;
    if(path.match(".*game_images.*") !== null){
        if(path.match("\\.jpe?g") !== null){
            return path;
        }
        else{
            return path + ".jpg";
        }
    }
    else if(path.match("\\.(png|jpe?g|webp|jpg?2)") !== null){
        const pathNoExtension = path.substring(0, path.lastIndexOf('.'));
        return pathNoExtension+ "." + getExtension();
    }
    else if(path.match("\\.(webp|jpg?2)") !== null){
        // This will now never get called, which will hopefully resolve issues on iOS
        return path;
    }
    else{
        return path + "." + getExtension();
    }
}

export const getBaseName = () => {
    let getUrl = window.location;
    let baseUrl = getUrl.protocol + "//" + getUrl.host;
    return baseUrl;
}