import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import './formComponent.css';

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}))
/*
    ControllOpenSelect: Multiple Select Form Element
    Inputs:
    props.formlabel: select title (string)
    props.setter: setter for the value (setter function)
    props.items: choices and their values (id: display: object)
    props.submit: check to see if the page is submittted
    props.value: current value (display) of the select
*/
const OpenSelect = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [display, setDisplay] = useState("");

  const findDisplay = (value) => {
    for (var item of props.items) {
      if (item.id === value) {
        setDisplay(item.id);
        return item.id;
      }
    }
  };

  const handleChange = (event) => {
    props.setter(parseInt(event.target.value, 10));
    findDisplay(parseInt(event.target.value, 10));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const detectError = (submit) => {
    return (submit && !props.value);
  };

  React.useEffect(() => {
    if (props.value !== undefined) {
      for (var item of props.items) {
        if (item.id === props.value.location_id) {
          setDisplay(item.id);
        }
      }
    }
  }, [props.value, props.items, display]);

  return (
    <div>
      <FormControl
        className={classes.formControl}
        fullWidth
        style={{ margin: "0%" }}
      >
        { display === '' && (
            <InputLabel
                id="demo-controlled-open-select-label"
                className="override-open-select-label"
            >
            {props.formLabel}
            </InputLabel>
        )}

        <select
            id="demo-controlled-open-select"
            className="open-select-select"
            onChange={handleChange}
            value={display}
        >
          <option selected disabled hidden></option>
          {props.items.map((item, index) => {
            return (
              <option className={"select-items"} key={index} value={item.id} name={item.display} style={{ textShadow: "none", padding: "5px"}}>
                {item.display}
              </option>
            );
          })}
        </select>
      </FormControl>
    </div>
  );
};

// React.memo to optimze rendering for multi select element
export const ControlledOpenSelect = React.memo(
  OpenSelect,
  function arePropsEqual(prevProps, nextProps) {
    return (
      prevProps.chosenLoc === nextProps.chosenLoc &&
      prevProps.items.length === nextProps.items.length &&
      prevProps.value === nextProps.value &&
      prevProps.submit === nextProps.submit
    );
  }
);

/* Text-Field Input
  Param:
  props.formLabel: input_title (string)
  props.setter: setter for input value (function)
  props.value: value of input
  props.submit: check to see if the page is submitted
  props.error: check if there is an error (function with value, submit as arg)
  props.errorMessage: message when there is an error
*/

const TextFields = (props) => {
  const classes = useStyles();
  let classesToUse = classes.root || [];
  if (props.extraClass) {
    classesToUse.push(props.extraClass);
  }

  return (
    <form className={classesToUse} noValidate autoComplete="off">
      <TextField
        name={props.name}
        fullWidth
        id={props.id}
        autoComplete={"on"}
        label={props.formLabel}
        onChange={(event) => {
          event.preventDefault();
          props.setter(event.target.value);
        }}
        defaultValue={
          props.value !== "" || props.value !== 0 ? props.value : ""
        }
        error={props.error(props.submit, props.value)}
        helperText={
          props.error(props.submit, props.value) ? props.errorMessage : ""
        }
      />
    </form>
  );
};

export const BasicTextFields = TextFields;
