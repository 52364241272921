
// These must be the first lines in src/index.js
import 'core-js';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";

import { LocalizeProvider } from "react-localize-redux";
import { loadReCaptcha } from 'react-recaptcha-v3';

if (module.hot) {
    module.hot.accept();
  }

loadReCaptcha("6LchmVwaAAAAAJMmwe8VbW-pCmj0ou2ztqTgqorL");

ReactDOM.render(<LocalizeProvider><BrowserRouter basename={process.env.REACT_APP_SUFFIX_URL}><App /></BrowserRouter></LocalizeProvider>, document.getElementById('root'));

serviceWorker.unregister();
